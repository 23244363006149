/*
 * Contact Page Styling
 */
@contact_form_border_radius: @border_radius;

.contactWrap {

    float: none;
    clear: both;
    width: ~"calc(100% + 80px)";
    margin: 0 -40px;
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: none;

    @media all and (max-width: 1200px) {
        width: ~"calc(100% + 32px)";
        margin: 0 -16px;
    }

	@media all and (max-width: 750px) {
		flex-wrap: wrap;
	}

    &:before, &:after {
        display: none;
    }
}

.contactFormWrap, .contactDetails {
    .clearfix();
    float: none;
    clear: both;
    margin: 0 0 32px;
    padding: 0 40px;

    @media all and (max-width: 1200px) {
        padding: 0 16px;
    }

    @media all and (max-width: 890px) {
        width: 100%;
    }

    &:before, &:after {
        display: none;
    }



    .successMessage {
        text-align: center;

        @media @mobile {
            text-align: left;
        }
    }
}

.contactDetails {
	width: 50%;
	flex: 0 0 50%;
	
	@media @flyout {
		width: 40%;
		flex: 0 0 40%;
	}
	
	@media all and (max-width: 750px) {
		width: 100%;
		flex: 0 0 100%;
	}
}

.contactFormWrap {
    order: 2;
    margin: 0;
	width: 50%;
	flex: 1 1 100%;
	
}

.contactForm {
    background: #fff;
	border: 1.5px solid @primary_color;
    padding: 40px;
    border-radius: @contact_form_border_radius;
	position: sticky;
	top: 24px;


    @media @mobile {
        padding: 24px 16px;
    }

    &:before, &:after {
        display: none;
    }

    h4 {
        color: @text_color;
		.headingStyle(h4);
        margin-bottom: 32px;
        text-align: center;

    }

    button {
        float: none;
        clear: both;
        margin: 0;
        min-width: 200px;
        width: 100%;
        .rems(18);
        line-height: 24px;
        padding: 16px;
        margin: 8px 0 0;
    }

    textarea {
        min-height: 160px;
    }

    .row {
        float: none;
        clear: both;
        display: block;
        width: 100%;
        margin: 0 0 32px;

        .validationMessage {
            padding: 4px 0 0 0;
        }

    }

    .inputWrap {
        textarea {
            resize: none;
        }
    }

}

.contactDetails {
    float: none;
    clear: both;
    order: 1;

    &:before, &:after {
        display: none;
    }


    h1, h2 {
        padding: 0;
        text-align: left;
		.headingStyle(h2);

        .innerText {
            padding: 0 !important;
        }
    }


    .mapCoordsList {
        width: 100%;
        float: left;

        .address {
            float: none;
            clear: both;

            @media @desktop, @tablet {
                .grid(2);
                margin-bottom: 15px;
            }

            @media @mobile {
                .grid(1);
            }

            .title {
                margin: 0 0 8px 0;
                letter-spacing: -0.8px;

                a {
                    .rems(16);
                    color: @text_color;
                    text-decoration: none;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .pageContent {
        padding-bottom: 0;
        margin: 16px 0 0 ;

        p {
            .rems(16);
            line-height: 24px;
        }
    }

    .contactBoxes {
        margin: 24px 0 0;

        .contactTitle {
            .rems(21);
            line-height: 24px;
            letter-spacing: 0.01px;
            font-weight: 500;
            color: @text_color;
            margin: 0 0 12px;
        }

        p {
            margin: 0;
            .rems(14);
            line-height: 24px;

            strong {
                font-weight: normal;
            }
        }
		
		.addressArea {
			margin: 0;
			.rems(14);
			line-height: 24px;

			strong {
				font-weight: normal;
			}

			& > span {
				display: block;
				width: 100%;

				@media @mobile {
					display: inline-block;
					width: auto;

					&:not(.noSeparator) {
						padding-right: 4px;

						&:after {
							content: ",";
						}
					}
				}

				&.spaced {
					margin-top: 10px;

					@media @mobile {
						display: block;
					}
				}
			}
		}

        .personWrap {

            margin: 8px 0 0;

            &:first-of-type {
                margin-top: 0;
            }

            .personTitle {
                display: block;
                width: 100%;
                font-weight: 600;
            }

            .personName {
                display: block;
                width: 100%;
            }

        }

        .addressBox {
            padding: 0;
            margin: 24px 0 0;

            .addressRow {
                margin: 8px 0 0;
                .rems(14);
                line-height: 24px;

                &:first-of-type {
                    margin: 0;
                }

                .addressTitle {
                    .rems(14);
                    line-height: 24px;
                    margin: 0;
                    font-weight: 600;
                }
            }
        }

        .regNumbers {
            margin: 0;

            .companyReg {
                margin: 8px 0 0;

                strong {
                    font-weight: 600;
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    /*
     * Contact Meta (phone, email and opening hours)
     */

    .contactMeta {

        margin: 0;

        &:before, &:after {
            display: none;
        }

        li {
            float: none;
            clear: both;
            position: relative;
            padding: 0 0 0 32px;
            margin: 0 0 12px 0;
            width: 100%;

            i {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                text-align: center;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: @primary_color;

                &:before {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .rems(16);
                    line-height: 26px;
                }
            }

            a {
                color: @text_color;
                text-decoration: none;
                transition: color 200ms linear;

                &:hover {
                    color: @primary_color;
                    transition: color 200ms linear;
                }
            }
        }
    }



    .bottomDetails {
        border-top: 1px solid @border_color_alt;
        padding-top: 32px;
        margin-top: 32px;

        @media @mobile {
            padding-top: 24px;
            margin-top: 24px;
        }
    }

    .detailsWrap {

        width: ~"calc(100% + 32px)";
        margin: 0 -16px;
        display: flex;
        flex-wrap: wrap;

        @media all and (max-width: 890px) {
            max-width: 640px;
        }


        &:before, &:after {
            display: none;
        }

        .gridItem {

            width: 50%;
            padding: 0 16px;
            margin: 0;

            @media (min-width: 891px) and (max-width: 1200px) {
                width: 100%;

                &:last-of-type {
                    margin-top: 12px;
                }
            }

            @media all and (max-width: 890px) {
                margin: 0;
            }

            @media @mobile {
                width: 100%;

                &:last-of-type {
                    margin-top: 12px;
                }
            }

            &.address {
                display: flex;

                .addressIcon {

                    width: 32px;
                    text-align: left;
                    position: relative;

                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: @primary_color;

                        &:before {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .rems(16);
                            line-height: 26px;
                        }
                    }
                }

                .innerAddress {
                    width: ~"calc(100% - 32px)";
                }
            }
        }
    }
}

.withMaps {
    /*
     * Map Styling
     */

    .mapWrap {
        position: relative;
        margin: 0;
        .clearfix();
        float: none;
        clear: both;
        border-top: 4px solid @primary_color;


        .map {
            width: 100%;
            height: 340px;
            background: @background_color;
        }

        .mapLink {
            position: absolute;
            left: 0;
            bottom: 0;
            background: @secondary_color;
            color: #fff;
            padding: 16px;
            text-decoration: none;
            .rems(14);
            line-height: 14px;
            font-weight: 500;
            border-radius: 0 @border_radius 0 0;
        }
    }
}

.multiMap {

    .mapMultiple {
        height: 400px;
        width: 100%;
    }
}

