.featuredItemsWrap {
    position: relative;
    float: none;
	clear: both;
    width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 95px 0 100px;
	
	
	@media @mobile {
		padding: 65px 0 40px;
	}

	
	& > .wrapper {
		min-height: 620px;
		
		@media @mobile {
			min-height: 474px;
		}
	}
   

    .featuredItems {
        .itemsList();
        margin: 0;
		display: flex;
		
		&:before, &:after {
			display: none;
		}

        li {
			flex: 0 0 382px;
            margin: 0;

        }
		
    }
	
	.itemsArea {
		
		display: flex;
		width: ~"calc(100% + 26px)";
		margin: 0 -13px;
		
		& > li {
			flex: 0 0 33.3333%;
			padding: 0 13px;
		}
		
		
	}
	
	
}
