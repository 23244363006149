@import '_form_variables.less';


.chosen-container {
    position: relative;
    min-width: 190px;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    *display: inline;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;

    a {
        cursor: pointer;
    }

    .chosen-drop {
        position: absolute;
        top: 100%;
        left: -9999px;
        width: 100%;
        border-top: 0;
        background: #fff;
        z-index: 8999999;

        .darkBg & {
            background: transparent;
        }
    }

    .search-choice .group-name, .chosen-single .group-name {
        margin-right: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #fff;

        &::after {
            content: ":";
            padding-left: 2px;
            vertical-align: top;
        }
    }



    .chosen-single {
        position: relative;
        display: block;
        overflow: hidden;
        text-align: left;
        padding: (@form_padding_top_bottom + 1px) @form_padding_icon_offset (@form_padding_top_bottom - 1px) @form_padding_sides;
        height: @field_min_height;
        text-decoration: none;
        white-space: nowrap;
        .rems(@form_font_size);
        line-height: @form_line_height;
        border-radius: @chosen_border_radius;

        border: @form_border_width solid @form_border_color;
        background: #fff;
        font-family: @body_font;
        transition: border-color 200ms linear;
        color: @form_text_color;
        font-weight: @form_font_weight;


        &:hover {
            border-color: @form_highlight_color;
        }


        span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: inherit;
            line-height: inherit;
			position: relative;
			top: 0;
        }

        .chosen-single-with-deselect span {
            margin-right: 32px;
        }

        abbr {
            position: absolute;
            top: 6px;
            right: 26px;
            display: block;
            width: 12px;
            height: 12px;
            font-size: 1px;
        }

        div {
            position: absolute;
            bottom: 0;
            top: @form_icons_top;
            right: @form_icons_side;
            width: @form_icons_width;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            b {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
				position: relative;

                svg {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 15px;
					height: 15px;
					transform: translate(-50%,-50%) rotate(0deg);
					transition: transform 200ms linear, color 200ms linear;
					color: @form_icons_color;
				}
            }
        }
    }

    &.chosen-container-active {

        .chosen-single {
            border-color: @form_highlight_color;
            transition: border-color 200ms linear;

            div > b:after {
                color: @form_highlight_color;
                opacity: 1;
                transition: all 200ms linear;
            }
        }

        &.chosen-with-drop {
            .chosen-single {
                border-radius: @chosen_border_radius @chosen_border_radius 0 0;
                border-bottom: none;
            }
        }
    }

    .chosen-search, .search-field {
        .clearfix();
        position: relative;
        z-index: 1010;
        padding: 8px;
        margin: 0;
        white-space: nowrap;
        width: 100%;

        &:after {
            position: absolute;
            top: 50%;
            right: 24px;
            transform: translateY(-50%);
            .fa(f002);
            z-index: 1000;
            .rems(14);
            line-height: 14px;
        }

        a {
            text-decoration: none;
        }

        input[type="search"] {
            float: none;
            outline: 0;
            padding: 8px 24px 8px 12px;
            width: 100% !important;

            &:focus {
                border: @form_border_width solid @form_border_color;
                box-shadow: none;
            }
        }
    }


    .chosen-drop {
        margin-top: 0;
        background-clip: padding-box;
        text-align: left;
        border: @form_border_width solid @form_border_color;
        border-top: none;
        border-top: none;
        overflow: hidden;
        border-radius: 0 0 @chosen_border_radius @chosen_border_radius;
        box-shadow: none;
        transition: border-color 200ms linear;

        &.chosen-dropup {
            top: 100% !important;
            bottom: auto !important;
        }

    }

    &.chosen-container-active {
        .chosen-drop {
            border-color: @form_highlight_color;
            transition: border-color 200ms linear;
        }
    }
}



&.chosen-with-drop .chosen-drop {
    left: 0;
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px;
}

.chosen-container {

    width: 100%;
    min-width: 100%;

    .chosen-results {
        float: left;
        width: 100%;
        background: #fff;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 216px;
        -webkit-overflow-scrolling: touch;
        margin: 0;
        padding: 0;

        .darkBg & {
            background: @dark_color;
        }

        li {
            margin: 0;
            padding: @form_padding_top_bottom @form_padding_sides;
            list-style-type: none;
            word-wrap: break-word;
            -webkit-touch-callout: none;
            color: @form_text_color;
            font-family: @body_font;
            .rems(@form_font_size);
            line-height: @form_line_height;
            font-weight: @form_font_weight;
            transition: background 200ms linear, color 200ms linear;

            .darkBg & {
                color: #fff;
            }

            em {
                font-style: normal;
                text-decoration: underline;
            }

            &.active-result {
                cursor: pointer;
            }

            &.disabled-result {
                display: list-item;
                cursor: default;
                opacity: 0.5;
                pointer-events: none;
            }

            &.result-selected {
                background-color: @form_highlight_color !important;
                color: #fff !important;
                transition: background 200ms linear, color 200ms linear;
            }

            &:hover, &.highlighted {
                background-color: fade(@form_highlight_color,16%);
                transition: background 200ms linear, color 200ms linear;
            }

            &:active {
                background-color: fade(@form_highlight_color,24%);
                transition: background 200ms linear, color 200ms linear;
            }

            .group-result {
                display: list-item;
                cursor: default;
            }

            .group-option {
                padding-left: 15px;
            }
        }
    }
}

.chosen-container-multi {



    .chosen-choices {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: auto !important;
        background: #fff;
        border-radius: @chosen_border_radius;
        cursor: text;
        margin: 0;
        padding: 0 @form_padding_icon_offset 0 0;
        transition: border-color 200ms linear;
        border: @form_border_width solid @form_border_color;

        .darkBg & {
            background: transparent;
            border-color: fade(@form_border_dark, 40%);
        }

        li {
            float: left;
            list-style-type: none;

            &.search-field {
                margin: 0;
                padding: 0;
                position: initial;
                width: ~"calc(100% + @{form_padding_icon_offset})";

                input {
                    border: none;
                    background: transparent;
                    padding: @form_padding_top_bottom @form_padding_icon_offset @form_padding_top_bottom @form_padding_sides;
                    color: @form_text_color;
                    min-width: 100% !important;
                    width: 100% !important;

                    .darkBg & {
                        color: #fff;
                    }
                }

                &:after {

                    position: absolute;
                    top: auto;
                    bottom: @form_icons_top;
                    right: @form_icons_side;
                    width: @form_icons_width;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .rems(14);
                    line-height: 16px;
                    color: @form_icons_color !important;
                    transition: color 200ms linear;
                    font-weight: 400 !important;
                    z-index: 1;
                    pointer-events: none;
                    transform: none !important;

                    .darkBg & {
                        color: #fff;
                    }

                }
            }

            &.search-choice-focus {
                background: red;
            }

            &.search-choice {
                position: relative;
                margin: 16px 8px 0 8px;
                padding: 0;
                max-width: 100%;
                color: @form_text_color;
                cursor: default;
                background-color: transparent;

                .darkBg & {
                    color: #fff;
                }

                & + .search-field {
                    margin-top: 12px;
                    background: rgba(0,0,0,0.04) !important;

                    .darkBg & {
                        background: rgba(0,0,0,0.1) !important;
                    }
                }

                span {
                    word-wrap: break-word;
                }

                .search-choice-close {
                    display: inline-block;
                    text-decoration: none;
                    margin: 0 0 0 5px;

                    &:after {
                        position: relative;
                        .fa(f057, @alert_color);
                        font-weight: 400;
                        transition: color 200ms linear;
                    }

                    &:hover {
                        &:after {
                            color: darken(@alert_color, 10%);
                            transition: color 200ms linear;
                        }
                    }

                    &:active {
                        &:after {
                            color: darken(@alert_color, 5%);
                            transition: color 200ms linear;
                        }
                    }
                }
            }
        }
    }


    &.chosen-container-active {

        .chosen-choices {

            border-color: @form_border_color;
            transition: border-color 200ms linear;

            .darkBg & {
                border-color: fade(@form_border_dark, 100%);
            }
        }


        &.chosen-with-drop {

            .chosen-choices {
                border-radius: @chosen_border_radius @chosen_border_radius 0 0;
                border-bottom: none;

                li {

                    &.search-field {

                        input {
                            border-bottom-left-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                        }
                    }

                }
            }

        }

    }

    .chosen-results {
        margin: 0;
        padding: 0;
    }

    .chosen-drop {

        &.chosen-dropup {
            margin-bottom: 8px;
        }

        .result-selected {
            display: list-item;
            color: #ccc;
            cursor: default;
        }
    }
}

.chosen-container-active {

    &.chosen-with-drop {

        .chosen-single {

            div {
                border-left: none;
                background: transparent;

                b {

                    svg {
						color: @form_highlight_color;
						transform: translate(-50%,-50%) rotate(-180deg);
						transition: transform 200ms linear, color 200ms linear;
					}
                }
            }
        }
    }

    .chosen-disabled {
        cursor: default;
        opacity: 0.5;

        .chosen-single {
            cursor: default;
        }

        .chosen-choices .search-choice .search-choice-close {
            cursor: default;
        }
    }


}

.mobileChosen {
    position: relative;
    min-width: 200px;

    select {
        display: block !important;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 998;
    }
}

/*
* Ensure the select box is full width within chosen
 */
.chosen-container {
    &>select {
        width: 100% !important;
    }
}

.chosen-search {
    display: none !important;
}


.minimalSelect {
	& + .chosen-container {
		
		display: inline-block;
		height: 48px;
		min-width: 220px;

		.rems(14);
		line-height: 20px;
		font-weight: 700;
		text-transform: none;
		
		.chosen-single {
			
			width: 100%;
			height: 48px;
			font-size: inherit;
			line-height: inherit;
			color: inherit;
			text-transform: inherit;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 8px 64px 8px 20px;
			border-width: 1px;
			border-color: transparent;
		}
		
		.chosen-drop {
			border-width: 1px;
			border-color: @highlight_color !important;

			font-size: inherit;
			line-height: inherit;
			color: inherit;
			text-transform: inherit;
			
			.chosen-results {

				font-size: inherit;
				line-height: inherit;
				color: inherit;
				text-transform: inherit;
				
				li {
					font-size: inherit;
					line-height: inherit;
					color: inherit;
					text-transform: inherit;
				}
				
			}
			
		}
		
		&.chosen-container-active {

			.chosen-single {
				border-color: @highlight_color;
			}
			
		}
		
	}
}
