

@flyout_header_height: 96px;
@flyout_footer_height: 83px;

//Nav Top level
@main_menu_link_padding_top: 20px;
@main_menu_link_padding_side: 20px;
@main_menu_link_spacing: @main_menu_link_padding_side * 2;
@main_menu_link_icon_padding: 24px;


@main_menu_sub_link_padding_top: 8px;
@main_menu_sub_link_padding_side: 0;

@main_menu_sub_menu_padding: 24px 0;

//Grid
@main_menu_sub_gutter: 32px;
@main_menu_sub_gutter_spacing: @main_menu_sub_gutter / 2;


//Colours
@main_menu_sub_menu_bg_color: @dark_color;
@main_menu_sub_menu_text_color: #fff;
@main_menu_sub_menu_highlight_color: @primary_color;
@main_menu_top_level_highlight_color: @secondary_color;


//Font
@main_menu_font_size: 14;
@main_menu_line_height: 14px;

@main_menu_sub_menu_font_size: 14;
@main_menu_sub_menu_line_height: 14px;

//Icons
@main_menu_icon_weight: @global_fa_font_weight;

//Borders
@main_menu_sub_menu_border_top: 1.5px solid @border_color_alt;
@main_menu_sub_menu_border_bottom: none;
@main_menu_sub_menu_shadow: 0 8px 8px -6px rgba(0,0,0,0.32);


//Menu buttons
@main_menu_button_width: 32px;
@main_menu_button_height: 48px;
@main_menu_button_padding: 12px 16px;

//Animation
@main_menu_sub_menu_anim: visibility 400ms linear, opacity 400ms linear;



.parentMobileLink {
    display: none !important;
}

/*
 * Secondary Navigation Menu
 */
.secondaryMenuNavigation {
    display: none;

    @media @flyout {
        display: block;
        clear: both;
        max-width: 100%;
    }
}

.siteWrapper {
    position: relative;
    min-height: 100%;
    overflow: hidden;

    &.mobileAssessment {
        @media @flyout {
            padding-bottom: 88px;
        }
    }
	
	&.requiresSticky {
		overflow: visible !important;
	}
}

.siteInnerWrapper {
    width: 100%;
    position: initial;
    //z-index: 2;
	background: @light_color;

    &.bundleOverlayOpen {
        position: initial;
    }

	.whiteBackground & {
		background: #fff;
	}
}

.flyoutButton  {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    height: @main_menu_button_height;
    padding: @main_menu_button_padding;
    width: @main_menu_button_width;
    transition: all 200ms linear;

    &:hover {
        color: @primary_color;
        transition: all 200ms linear;
    }

    @media @flyout {
        padding: 16px 0;
        height: 56px;
    }

    .innerButton {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    i {
        color: @text_color;
        transition: color 200ms linear;

		width: 25px;
		height: 16px;
		
        .flyoutActive & {
            transition: color 200ms linear;
        }
		
		svg {
			width: 100%;
			height: 100%;
		}
    }

    i.openButton {
        display: inline-block;

        .flyoutActive & {
            display: none;
        }
    }

    i.closeButton {
        display: none;

        .flyoutActive & {
            display: inline-block;
        }
    }

}


/*
* Flyout
 */
@media @above_flyout {
    .flyoutWrap {
        float: none;
        clear: both;
        width: 100%;

        .flyoutHeader, .flyoutFooter {
            display: none;
        }

		.mobileMenu {
			display: none;
			
			&.mainNavigationMenu {
				display: block;
			}
		}
		
		.mobileTitle {
            display: none;
        }
    }
}

.mainMenu {

	.rems(18);
	font-style: normal;
	font-weight: 500;
	line-height: 25.278px;
	
	ul {
		&:before, &:after {
			display: none;
		}
	}

	.menuIcon {
		
		display: inline-block;
		position: relative;
		width: 8px;
		height: 8px;

		svg {
			width: 100%;
			height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%)
		}

	}
	
	a {
		color: @flyout_link_color;
		font-style: normal;
		font-weight: 500;
		font-size: inherit;
		line-height: inherit;
		text-align: left;
		letter-spacing: initial;
		text-transform: none;
		display: block;

		&:hover, &:focus, &:active {
			color: darken(@highlight_color, 8%);
		}
		
		
	}
	
}

@media @flyout {
	

    .flyoutLeft {

        .flyoutWrap {
			width: ~"calc(100% + 50px)";
            right: -25px;
			left: -25px;
        }


        &.flyoutActive {

            .flyoutWrap {
                transform: none;
            }

        }
    }

    .flyoutWrap {
        position: absolute;
		top: 100%;
        width: @flyout_width;
        background: @flyout_background;
        z-index: 99;
        display: none;
        box-shadow: none !important;
        transition: opacity @flyout_transition_time ease-in-out, visibility @flyout_transition_time ease-in-out;
        overflow: hidden;
        padding: 0 @page_side_padding;
        color: @flyout_text_color;
        flex-direction: column;
		height: auto;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		
        .mobileTitle {
            display: block;
            width: 100%;
            .rems(13);
            line-height: 19px;
            font-weight: 600;
            color: fade(@flyout_text_color, 40%);
            margin: 0 0 7px;
			display: none;
        }

        .flyoutInitialised & {
			display: flex;
			flex-direction: column;
			transition: opacity @flyout_transition_time ease-in-out, visibility @flyout_transition_time ease-in-out;
		}

		.flyoutActive & {
			box-shadow: @flyout_shadow;
			transition: opacity @flyout_transition_time ease-in-out, visibility @flyout_transition_time ease-in-out;
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}

        .flyoutHeader {
            padding: 0;
            border-bottom: none;
            flex: 0 0 auto;
            height: auto;

            .flyoutHeaderTop {
				
				display: none;

                .flyoutButton {
                    color: @flyout_text_color;
                    opacity: 0.4;
                    transition: opacity 200ms linear;
                    width: 100%;
                    text-align: left;
                    padding: 0;
                    height: auto;
					display: none;

                    &:hover, &:focus {
                        color: #fff;
                        opacity: 1;
                        transition: opacity 200ms linear;
                    }

                    &:active {
                        color: #fff;
                        opacity: 0.8;
                        transition: opacity 200ms linear;
                    }

                    i {
                 
                        color: inherit !important;
                        margin-right: 0;
                        position: relative;
                        top: 2px;
                        display: inline-block !important;
						
						svg {
							width: 25px;
							height: 16px;
						}

                    }
                }

            }

        }
		

    }
	
	.mainMenu {

		.rems(16);
		line-height: 1.1em;

		a {
			padding: 6px 0;

			.menuIcon {


				width: 0.5359em;
				height: 0.5359em;
				border-radius: 100%;
				background: @primary_color;
				color: #fff;
				position: relative;
				top: -0.1em;
				
				min-width: 12px;
				min-height: 12px;

				svg {
					width: 0.2em;
					height: 0.2em;

					min-width: 4.5px;
					min-height: 4.5px;
				}

			}

			&.isClickable.open {
				color: @highlight_color !important;
			}
		}

		
		&.mainNavigationMenu {
			
			margin: 45px 0;
			.rems(28);
			line-height: 1em;
			
			& > .dropdownWrap {

				.dropdownWrap {
					.rems(16);
					line-height: 1.1em;

					
				}
			}
			
		}
		
		&.linksMenu {
			display: flex;
			margin: auto 0 45px;
		}
		
		
	}

}

@media all and (max-width: 400px) {

	.mainMenu {
		&.mainNavigationMenu {
			.rems(20);
		}
	}
	
}


@media @above_flyout {

    .flyoutWrap {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .mainMenu {
		
		&.linksMenu {
			display: none;
		}

		.menuIcon {
			color: @menu_chevron_color !important;
			margin-left: 7px;
		}
		
        & > .dropdownWrap > ul  {

            display: flex;
            width: ~"calc(100% + 43px)";
            margin: 0 -21.5px;

			

            & > li, & > mtc-menu {

                display: flex;
                align-items :center;
                padding: 0 21.5px;
				
				.secondaryMenuWrap & {
					position: relative;
					padding: 0 12.5px;
				}

                & > a {
                    white-space: nowrap;
					display: flex;
					align-items: center;
					
					.svgIcon {
						
						width: 8px;
						height: 8px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-left: 7px;
						
						svg {
							width: 100%;
							height: auto;
							max-width: 8px;
						}
						
					}
					
                }


				&.mobileOnly {
					display: none !important;

					@media @flyout {
						display: block !important;
					}
				}
					
            }
			
			
        }


    }
	
}

.mainMenu.secondaryMenu {


	font-weight: 700;
	.rems(12);
	line-height: 12px;
	
	
}

.menuIcon {
	display: none !important;
}
