
//Grid
@doctor_gutter_width: 24px;
@doctor_gutter_space: @doctor_gutter_width / 2;

@doctor_gutter_width_mobile: 8px;
@doctor_gutter_space_mobile: @doctor_gutter_width_mobile / 2;


//Borders
@doctor_border_radius: @border_radius;



.onlineDoctorCategories, .gridCards {
    float: none;
    clear: both;
    width: 100%;
    background: @light_color;
    padding: 80px 0;
    text-align: center;
    position: relative;
    z-index: 1;

    @media @flyout {
        padding: 80px 0;
    }

    @media @mobile {
        padding: @top_bottom_gutter_mobile 0;
    }

    &:before, &:after {
        display: none;
    }

    &.firstSectionPadding {

        padding: 120px 0 40px;

        @media @flyout {
            padding: 80px 0 24px;
        }

        @media @mobile {
            padding: @top_bottom_gutter_mobile 0 24px;
        }
    }

    &.lastSectionPadding {

        padding: 40px 0 120px;

        @media @flyout {
            padding: 24px 0 80px;
        }

        @media @mobile {
            padding: 24px 0 @top_bottom_gutter_mobile;
        }
    }

    &.darkOffsetTop {

        &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            height: 48px;
            width: 100%;
            display: block;
            background: @light_color;

            @media @flyout {
                display: none;
            }
        }
    }

    .onlineDoctorWrap  ul {

        .colGroup(@doctor_gutter_width);

        @media @mobile {
            .colGroup(8px);
        }


        &:before, &:after {
            display: none;
        }

        &.centered {
            justify-content: center;
        }

    }

    .gridItem {

        .col(3, @doctor_gutter_width);

        @media @flyout {
            .col(2, @doctor_gutter_width);
        }

        @media @mobile {
            .col(2, @doctor_gutter_width_mobile);
        }

        @media all and (max-width: 500px) {
            .col(1, @doctor_gutter_width_mobile);
        }


    }

    .innerGridItem {

        text-decoration: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background: #fff;
        text-align: center;
        color: @text_color;
        height: 100%;



        .cardHeader {

            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 24px;
            flex: 1 1 auto;
            width: 100%;
			color: inherit;

            h5 {
                text-align: inherit;
                font-weight: normal;
                color: inherit;
                width: 100%;
                margin: 0;

                @media @mobile {
                    .rems(20);
                }

            }

            p {
                margin: 0;
                padding: 16px 0 0;
                text-align: inherit;
                color: inherit;
                .rems(14);
                line-height: 24px;

                @media @mobile {
                    .rems(13);
                    line-height: 20px;
                    padding: 12px 0 0;
                }
            }
        }

        .cardImage {

            width: 100%;
            height: auto;
            flex: 0 0 auto;
            position: relative;
			color: inherit;
			margin-top: auto;

            .imageWrap {
                border: none;
                box-shadow: none;
                position: relative;
                background: fade(@primary_color,10%);
                overflow: hidden;
                height: auto;
                padding-bottom: 74%;

                @media @flyout {
					padding-bottom: 60%;
                }

                @media all and (max-width: 500px) {
					padding-bottom: 60%;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;
                    height: auto;
                }

				.noImageSvg {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					width: 56px;
					height: 56px;
					opacity: 0.08;

					svg {
						width: 100%;
						height: auto;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%,-50%);

						path {
							fill: #000;
						}
					}
				}

            }

        }

        .cardFooter {
            width: 100%;
            padding: 24px;
            height: auto;
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
			color: inherit;
			flex-wrap: wrap;



            .cardPrice {
                text-align: center;
                padding: 0 8px 0 0;
                width: 100%;
				color: inherit;

                .priceHeader {
                    .rems(10);
                    line-height: 12px;
                    text-transform: uppercase;
                }

                .price {
                    * {
                        .rems(25);
                        line-height: 29px;
                        font-weight: normal;
                    }
                }
            }


            .cardCta {
                width: 100%;
                text-align: left;
                padding: 0;

                @media (min-width: 981px) and (max-width: 1100px) {
                    padding: 16px 0 0;
                }

            }

            .cardPrice + .cardCta {
               
                padding: 0;
				
                .button, .buttonAlt {
                    width: 100%;
                }
            }

            .button, .buttonAlt {
                margin: 0;
                display: inline-block;
                min-height: 0;
                width: auto;

                @media (min-width: 981px) and (max-width: 1100px) {
                    width: 100%;
                }

                @media all and (max-width: 740px) {
                    width: 100%;
                }
            }
        }

    }

}


.onlineDoctorCategories {

    position: relative;
    min-height: 260px;
    background: #fff;

    @media @mobile {
        background: #fff;
    }

    &.lightBg {
        background: #fff;

        .innerGridItem {
            background: #fff;

            @media @mobile {
                background: #fff;
            }
        }
    }

    &.subCategories {
        background: @light_color;
        padding: 80px 0 120px;


        @media @flyout {
            padding: 80px 0;
        }

        @media @mobile {
            padding: @content_padding_top_mobile 0 @content_padding_bottom_mobile;
			background: @light_color;
        }

        .onlineDoctorWrap {
            ul {

                width: ~"calc(100% + 32px)";
                margin: 0 -16px;

                @media @flyout {
                    width: ~"calc(100% + 24px)";
                    margin: 0 -12px;
                }

                @media @flyout {
                    width: ~"calc(100% + 8px)";
                    margin: 0 -4px;
                }

                li {
                    padding: 0 16px;

                    @media @flyout {
                        padding: 0 12px;
                    }

                    @media @flyout {
                        padding: 0 4px;
                    }
                }
            }
        }

        .innerGridItem {
            background: #fff;
            border-radius: @border_radius;
            border: none;

            @media @mobile {
                //background: @light_color;
            }
        }
    }



    .gridItem {
     

        @media @mobile {
            .col(2, 8px);
        }

		@media all and (max-width: 350px){
			.col(1, 8px);
		}

    }

    .innerGridItem {

        padding: 40px;
        background: @light_color;
        border: 1px solid #FAFAFA;
        transition: background 400ms linear, border-color 400ms linear;
        border-radius: 16px;

        @media @flyout {
            padding: 24px;
        }

        @media @mobile {
            background: @light_color;
            border: 1px solid #FAFAFA;
            position: relative;
            min-height: 0;
            display: flex;
			padding: 16px;
        }

        &:hover {
            background: @faded_primary;
            border-color: @faded_primary;
            transition: background 400ms linear, border-color 400ms linear;


            button {
                background: @secondary_color;
                border-color: @secondary_color;
                color: #fff;
            }
        }

        &:active, &:focus {
            background: darken(@faded_primary, 4%);
            border-color: darken(@faded_primary, 4%);
            transition: background 400ms linear, border-color 400ms linear;


            button {
                background: darken(@secondary_color, 4%);
                border-color: darken(@secondary_color, 4%);
                color: #fff;
            }
        }


        .cardHeader {

            padding: 0 0 24px 0;

            @media @mobile {
                padding: 0 0 16px 0;
                width: 100%;
                margin-top: 0;
                height: auto;
                flex: 0 0 auto;
            }

            h5 {
                .rems(24);
                line-height: 32px;
                font-weight: bold;
                position: relative;
				text-align: center !important;

                @media @flyout {
                    .rems(16);
                    line-height: 24px;
                }

                @media @mobile {
                    .rems(14);
                    line-height: 20px;
                    word-wrap: break-word;
                }

                @media all and (max-width: 380px) {
                    .rems(12);
                    line-height: 18px;
                }

                i {
                    margin-left: 8px;
                    color: @text_color;
                    transition: color 400ms linear;

                    @media @mobile {
                        display: none;
                    }
                }
            }

            p {
                opacity: 0.64;
                @media @mobile {
                    display: none;
                }
            }
        }


        .cardFooter {
            padding: 24px 0 0;

			@media @mobile {
				padding: 16px 0 0;
			}

			
			.cardPrice {
				@media @mobile {
					display: none;
				}
			}
			
			.cardCta {
				
				width: 100%;
				
				@media @mobile {
					width: 100%;
				}
				
				.button {
					
					width: 100%;
					
					@media @mobile {
						width: 100% !important;
						max-width: 100%;
						padding: 12px 8px;
						.rems(11);
						line-height: 1.2em;
					}
				}
			}
            
        }


    }


    .wrapper {
        position: relative;
        z-index: 2;
    }

    .background {
        display: none;
    }

}

.noArticles {

    padding: 120px 0;

    @media @flyout {
        padding: 40px 0;
    }

    .title {
        text-align: center;
    }

    p {
        text-align: center;
    }
}


.onlineDoctorWrap {
	
	.parentSections {
		
		padding: 0 0 80px;
		
		@media @mobile {
			padding: 0 0 40px;
		}
		
		
	}
	
}


.categoryContentBox {
	padding: 40px 0;
	background: #fff;
	
	.innerCategoryFaqs {
		padding: 36px 50px;
		border-radius: 4px;
		background: rgba(195, 195, 195, 0.30);
		
		@media @mobile {
			padding: 24px;
		}
		
		.faqs {
			li {
				h4 {
					.rems(19);
					font-weight: 600;
					line-height: 28.278px;
					border: none;
					padding: 10px 0;
					
					.faqIcon {
						color: @primary_color;
						
						svg {
							max-width: 12px;
							max-height: 12px;
						}
					}
					
					&:hover, &:active, &:focus-visible {
						color: @primary_color;

						.faqIcon {
							color: @primary_color;
							
							svg {
								path {
									fill: @primary_color;
								}
							}
							
						}
					}
					
					&.open {
						.faqIcon {
							opacity: 0;
							visibility: hidden;
							transform: none !important;
						}
					}
				}
				
				.answer {
					padding: 28px 0 0;

					@media @mobile {
						padding: 24px 0;
					}
				}
			}
		}
	}
}
