.categoryCTAWrap {
	background: #fff;
	padding: 46px 0;
	float: none;
	clear: both;
	width: 100%;
	position: relative;
	overflow: hidden;

	@media all and (max-width: 1120px) {
		padding-bottom: 54px;
	}

	.categoryGrid {
		padding-bottom: 80px;
		
		&__item {
			@media all and (max-width: 1120px) {
				margin: 0;
				height: 100%;
			}
		}
	}
	
	&.medicinesList {

		padding: 94px 0;
		
		.categoryGrid {
			padding-bottom: 0;
		}
		
	}
	

	
	&__list {
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		width: 100%;
		display: none;
		
		&.splide {
			display: flex;
		}

		@media all and (max-width: 1120px) {
			overflow: visible;
		}
		
		mtc-slide {
			width: 100%;
			flex: 0 0 100%;
			min-width: 100%;
		}
		
		
		&.desktop {
			display: flex;
			
			@media all and (max-width: 1120px) {
				display: none;
			}
		}

		&.smallScreens {
			display: none;

			@media all and (max-width: 1120px) {
				display: flex;
			}
		}
	}
	
	.splide__arrow {
		&:disabled {
			display: none;
		}
		
		&--prev {
			left: -1em;
		}
		
		&--next {
			right: -1em;
		}
	}


	.splide__track {
		@media all and (max-width: 1120px) {
			width: 90%;
			overflow: visible;
		}
		
		@media @mobile {
			width: 100%;
		}
	}
	
	.splide__list {
		
		max-width: 100%;
		
		
		.splide__slide {
			width: 20%;
		}
	}
	
}

.categoryGrid {
	
	display: flex;
	flex-wrap: wrap;
	width: ~"calc(100% + 52px)";
	margin: 0 -26px -52px -26px;

	@media all and (max-width: 1120px) {
		width: ~"calc(100% + 52px)";
		margin: 0 -26px 0 -26px;
	}
	
	@media @mobile {
		width: ~"calc(100% + 24px)";
		margin: 0 -12px 0 -12px;
	}
	
	&:before, &:after {
		display: none;
	}
	
	&__item {
		flex: 0 0 20%;
		list-style: none;
		padding: 0 26px;
		margin: 0 0 52px;
		
		@media all and (max-width: 1200px) {
			flex: 0 0 25%;
		}

		@media all and (max-width: 950px) {
			flex: 0 0 33.333333%;
		}

		@media all and (max-width: 700px) {
			flex: 0 0 50%;
		}
		
		@media @mobile {
			padding: 0 12px;
		}

		@media all and (max-width: 400px) {
			flex: 0 0 100%;
		}
	}
	
}

.categoryCard {
	
	padding: 0;
	color: @dark_title_color;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
	.rems(21);
	font-style: normal;
	font-weight: 500;
	line-height: 23.5px;
	letter-spacing: -0.63px;
	transition: color 200ms linear;
	overflow: hidden;
	border-radius: 18px;
	border: 0.8px solid fade(@dark_title_color, 40%);
	
	&:hover, &active, &:focus-visible {
		border-color: @highlight_color;
		color: @highlight_color;
		transition: color 200ms linear, border-color 200ms linear;
	}

	&__text {
		position: relative;
		padding: 0 20px 20px 20px;
		text-align: center;
		z-index: 2;
		margin-top: auto;
	}
	
	&__imageBox {
		width: 100%;
		padding: 24px 48px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__imageWrap {

		position: relative;
		width: 100%;
		padding-bottom: 100%;
		z-index: 1;

		img {
			position: absolute;
			object-fit: cover;
			width: 100%;
			height: 100%;
			z-index: 1;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}

	}

}
