//Breakpoints
@footer_meta_mobile_breakpoint: 1000px;

//Colours
@footer_border_color: rgba(0,0,0,0.5);
@footer_bg_color: @dark_color;


//Groups
@columns: 5;
@newsletter_group_width: 429px;

//Newsletter
@newsletter_padding: 12px 56px 12px 24px;
@newsletter_button_padding: 0;

//Newsletter font
@newsletter_form_font_size: 16;
@newsletter_form_line_height: 24px;

/*
 * Footer
 */
.footerWrap {
    position: relative;
    float: none;
    clear: both;
    width: 100%;
    background: @footer_bg_color;
	padding: 34px 0 30px;

    .hasCookieNotice & {
        padding-bottom: 40px;

        @media @flyout {
            padding-bottom: 56px;
        }

        @media @mobile {
            padding-bottom: 88px;
        }
    }

    &.mobileMenuAttached {

        @media @flyout {
            padding-bottom: 96px;
        }

        @media @mobile {
            padding-bottom: 96px;
        }
    }

    @media @mobile {
        padding: 0 0 0;
    }

    &.hasProgressBar {
        @media @flyout {
            padding-bottom: 90px;
        }
    }

    .footer {

        float: none;
        clear: both;
        position: relative;
        color: #fff;
        width: 100%;
        padding: 0;
        .rems(16);
        line-height: 24px;

        .lightBg & {
            color: @text_color;
        }

        @media @mobile {
            .rems(18);
            line-height: 24px;
        }

        @media all and (max-width: 450px) {
            .rems(18);
            line-height: 20px;
        }


        &:before, &:after {
            display: none;
        }
		


    }

    .stickyFooter & {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .contactSocial {

        display: flex;
        padding-top: 16px;

        @media @mobile{
            display: none;
        }

    }

    .mobileSocial {
        background: transparent;
        padding: 0 0 24px;
        display: none;
        justify-content: center;
        border-top: none;


        @media @mobile{
            display: flex;
        }

        .socialTitle {
            text-align: center;
            margin-bottom: 8px;
        }

        .followButtons {
            width: auto;
        }

    }

	.menuGroupsWrap {
		@media @mobile {
			padding-top: 24px;
		}
	}
	
    .menuGroup, .mobileSocial {

        .followButtons {
            padding: 24px 0 0;
			display: flex;
			justify-content: center;
			
			ul {
				width: auto;
			}
        }

    }

	.menuGroup {
		.followButtons {
			
			display: flex;
			
			@media @mobile {
				display: none;
			}
		}
	}

	.mobileSocial {
		.followButtons {
			
			display: none;
			
			@media @mobile {
				display: flex;
			}
		}
	}

    .newsletterFormWrap {

        padding-top: 0;

        .successMessage {
            .rems(20);
            line-height: 32px;
            color: @secondary_color;
            font-weight: 600;
            margin-bottom: 32px;
            float: none;
            clear: both;

            .message {
                .rems(18);
                line-height: 24px;
                font-weight: normal;
                color: #fff;
            }
        }

        form {
            display: block;

            .row {

                float: none;
                clear: both;
                width: 100%;

                .validationMessage {
                    padding: 8px 0;
                    .rems(18);
                    line-height: 24px;
                    font-weight: 600;

                    @media @mobile {
                        text-align: center;
                    }
                }

                .inputWrap {

                    &:after {
                        top: 26px;
                    }

                    input {
                        position: relative;
                        z-index: 1;
                        .rems(@newsletter_form_font_size);
                        line-height: @newsletter_form_line_height;
                        padding: @newsletter_padding;
                        background: @mid_primary;
                        .placeholder(fade(#fff, 72%));
                        border-color: @mid_primary;
                        height: 56px;
                        color: #fff;
                        border-radius: 32px;

                        &:focus {
                            border-color: @secondary_color;
                        }
                    }

                    input::placeholder {
                        opacity: 1;
                    }

                    &.withButton {
                        position: relative;
                        z-index: 1;

                        &:after {
                            right: 64px;
                            z-index: 4;
                        }

                        .button {
                            position: absolute;
                            z-index: 5;
                            right: 4px;
                            top: 4px;
                            bottom: 0;
                            height: 48px;
                            width: 48px;
                            padding: @newsletter_button_padding;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background: @secondary_color;
                            border-color: @secondary_color;

                            &:hover {
                                background: @dark_secondary;
                                border-color: @dark_secondary;
                            }

                            i {
                                padding: 0;
                                margin: 0;
                                .rems(20);
                                line-height: 24px;
                                height: 24px;
                                width: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &:before {
                                    font-size: inherit;
                                    line-height: inherit;
                                }
                            }
                        }
                    }
                }
            }

            .button {
                margin: 0;
            }
        }
    }
	
    &.lightBg {

        background: #fff;
        color: @text_color;
        border-top: none;

        .footer {
            a {
                color: @text_color;

                &:hover, &:active {
                    color: @highlight_color;
                }


            }
        }
    }
	
	a {
		color: #fff;
	}
	

	ul.paymentIcons {
		display: flex;
		align-items: center;
		justify-content: center;
		width: ~"calc(100% + 20px)";
		margin: 0 -10px;


		&:before, &:after {
			display: none;
		}

		& > li {
			
			padding: 0 10px;


			& > picture {
				width: auto;

				img, svg {
					width: auto;
					height: 100%;
					max-height: 24px;
					min-height: 24px;

					@media @mobile {
						filter: grayscale(1);
					}
				}
			}
		}
	}
	
	
}


.footerTop {
	display: flex;
	padding: 75px 0 0;


	@media @mobile {
		padding: 40px 0 0;
	}
	
	
	.footerTopLeft {
		width: 50%;
		display: flex;
		align-items: center;
		
		@media @mobile {
			display: none;
		}
	}
	
	.footerTopRight {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@media @mobile {
			flex: 0 0 100%;
			width: 100%;
			justify-content: center;
			flex-wrap: wrap;
		}
		
		.topRightCol {
			flex: 0 0 auto;

			@media @mobile {
				flex: 0 0 100%;
			}
			
			&:last-child {
				@media @mobile {
					display: none;
				}
			}
		}
		
		.trustLogos {
            max-width: 170px;
			height: 40px;
			padding-right: 12px;
			border-right: 1px solid rgba(0,0,0,0.16);
			
			@media @mobile {
				padding-right: 0;
				border-right: none;
				justify-content: center;
			}
		}
		
	}

	ul.paymentIcons {
		
		justify-content: flex-end;
		padding-left: 12px;
		
		@media @mobile {
			display: none;
		}
		
		li {
			
		}
		
		picture {
			display: block;
			width: 100%;
			height: 100%;
		}
		
		img {
			width: auto;
			height: 100%;
		}
	}
	
	.logo {
		width: 250px;
		margin: 0;
		
		@media all and (max-width: 750px) {
			width: 190px;
		}
		
		svg, img {
			max-width: 100%;
            height: auto;
		}
	}
	
}


.footerAddress, .mobileAddress {

	& > span {
		display: block;
		width: 100%;

		@media @mobile {
			display: inline-block;
			width: auto;

			&:not(.noSeparator) {
				padding-right: 4px;
				
				&:after {
					content: ",";
				}
			}
			
			&.noSeparator {
				&:after {
					display: none;
				}
			}
		}

		&.spaced {
			margin-top: 10px;

			@media @mobile {
				display: block;
			}
		}
	}
}




/*
 * Footer menu groups
 */
.menuGroupsWrap {
    float: none;
    clear: both;
    width: ~"calc(100% + 24px)";
    margin: 0 -12px;
    padding: 42px 0 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media all and (max-width: 900px) {
        flex-wrap: wrap;
    }

    @media @mobile {
        width: 100%;
        margin: 0;
		justify-content: center;
    }

    &:before, &:after {
        display: none;
    }

    .menuGroup {
        float: none;
        clear: both;
        text-align: left;
		justify-content: space-between;
        .rems(18);
		font-weight: 300;
		line-height: 25.278px;
        padding: 0 12px;
        flex: 1 1 auto;
        width: auto;
        margin-bottom: -6px;
        letter-spacing: initial;
		

        .lightBg & {
            color: @text_color;
        }

        @media all and (max-width: 900px) {
            flex: 0 0 50%;
            width: 50%;
            border: none !important;
        }
		
		@media @mobile {
			width: 100%;
			flex: 0 0 100%;
			text-align: center;
		}



        &.newsletterGroup {
            flex: 1 0 @newsletter_group_width;
            width: @newsletter_group_width;

            @media all and (max-width: 1000px) {
                flex: 0 0 auto;
                width: 100%;
                border-top: 1px solid @footer_border_color;
                padding-top: 32px;
                margin-top: 32px;
            }

            @media @mobile {
                padding-top: 24px;
                margin-top: 0;
            }

            .menuGroupTitle {
                @media all and (max-width: 1000px) {
                    width: 50%;
                    margin: 0 auto 16px auto;
                    text-align: center;
                }

                @media @flyout {
                    margin-bottom: 16px;
                }

                @media all and (max-width: 700px) {
                    width: 100%;
                }
            }

            .newsletterFormWrap {
                @media all and (max-width: 1000px) {
                    width: 50%;
                    margin: 0 auto;
                    padding-top: 0;
                }

                @media all and (max-width: 700px) {
                    width: 100%;
                }
            }

            .footerLogoArea {
                @media all and (max-width: 1000px) {
                    width: 50%;
                    margin: 0 auto;
                }

                @media all and (max-width: 700px) {
                    width: 100%;
                }

                .trustLogos {
                    @media all and (max-width: 1000px) {
                        justify-content: center;
                        padding-top: 8px;
                    }
                }
            }
        }

        .menuGroupTitle {
            margin: 0 0 10px;
			padding: 0 0 10px 0;
            color: #fff;
            .rems(18);
            line-height: 1.2em;
            font-weight: 700;
			letter-spacing: initial;
			text-transform: none;
			position: relative;

			
            @media @mobile {
                padding: 8px 24px !important;
                margin: 0 !important;
                .rems(20);
                line-height: 24px;
				font-weight: 500;
				
				&:hover, &:active {
					color: @highlight_color;
				}
            }


            .lightBg & {
                color: @text_color;
            }

            &.addressTitle {
                display: none;
            }

        }

        .dropdownWrap {
			
			@media @flyout {
				margin: 0 0 40px;
			}

            @media @mobile {
                padding: 0 24px;
                margin: 0 0 8px;
				display: none;
            }

            &.mobileMenuDrop {
                position: relative;
                top: initial;
                left: initial;
                background: transparent;
                overflow: initial;
                display: block;
            }

            & > ul {
				
                margin-top: 0;

                li, mtc-menu {
                    width: 100%;
                    margin: 0;
					display: block;
					

                    a {
                        text-decoration: none;
                        color: #fff;
                        padding: 5px 0;
                        transition: color 200ms linear;
                        display: inline-block;
						
                        &:hover, &:active {
                            color: @highlight_color;
                            transition: color 200ms linear;
                        }

                    }

                    .lightBg & {

                        a {
                            color: @text_color;

                            &:hover, &:active {
                                color: @highlight_color;
                            }

                        }
                    }
					
                }
            }
        }

        &:not(.newsletterGroup) {
            @media @mobile {
                padding-right: 0;
                max-width: none;
                width: 100%;
                padding: 0;
                border-top: 1px solid @footer_border_color;
            }

            .menuGroupTitle {

                @media @mobile {
                    padding: 16px 40px 16px 16px;
                    margin: 0;
                    border-bottom: none;
                    cursor: pointer;
                    position: relative;

                    &:after {
                        position: absolute;
                        top: 50%;
                        right: 16px;
                        //.fa(f107);
                        transform: rotate(0deg) translateY(-50%);
                        transition: transform 200ms linear;
                        transform-origin: 50% 0;
                        .rems(16);
                        line-height: 16px;
                        width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        display: none;
                    }

                    &.open {

                        &:after {
                            transform: rotate(-180deg) translateY(-50%);
                            transition: transform 200ms linear;
                            transform-origin: 50% 0;
                        }
                    }
                }
            }
        }

        .dropdownWrap, .footerContactDetails {
			

            .addressBox {

                background: #fff;
                color: @text_color;
                padding: 32px;
                box-shadow: 0 10px 10px rgba(0,0,0,0.08);
                border-radius: @border_radius;

                .addressBoxTitle {
                    color: @text_color;
                    margin: 0 0 16px;
                    font-weight: bold;

                    @media @flyout {
                        margin-bottom: 16px !important;
                    }
                }

                .addressBoxWrapper {

                    @media @flyout {
                        .rems(18);
                        line-height: 20px;
                    }

                    p {

                        .rems(18) !important;
                        line-height: 17px;
                        font-weight: 600;
                        margin-bottom: 16px;

                        @media all and (max-width: 400px) {
                            .rems(12) !important;
                            line-height: 20px !important;
                        }

                        &:last-of-type {
                            margin: 0;
                        }

                        a {
                            font-weight: 600;
                            margin-top: 6px;
                            display: block;
                            width: 100%;
                            .rems(17);
                            line-height: 24px;

                            @media all and (max-width: 400px) {
                                .rems(18);
                                line-height: 20px;
                            }
                        }
                    }

                    a {
                        .rems(16);
                        line-height: 24px;

                        @media @flyout {
                            .rems(18) !important;
                            line-height: 24px !important;
                        }

                        @media all and (max-width: 400px) {
                            .rems(12) !important;
                            line-height: 20px !important;
                        }
                    }

                    p > a {

                        color: @secondary_color;

                        &:hover {
                            color: darken(@secondary_color, 8%);
                        }

                        &:active {
                            color: darken(@secondary_color, 4%);
                        }
                    }
                }
            }

            @media @flyout {


            }

        }

        .footerContactDetails {

            @media @flyout {
                margin-left: 24px;
                margin-right: 24px;
                margin-top: 16px;

                .addressBoxTitle {
                    padding: 0 !important;
                }

                .addressBoxWrapper {
                    &, p {
                        .rems(18);
                        line-height: 24px;
                    }
                }
            }

            .addressBox {
                @media @mobile {
                    padding: 24px !important;
                }
            }
        }
		
		&:last-of-type {

			.dropdownWrap {
				
				@media @mobile {
					margin: 0;
					padding-top: 8px;
				}
			}
			
		}
    }

}

/*
* Footer Logos Banner
*/
.trustLogoBar {
    float: none;
    clear: both;
    width: 100%;
    background: #fff;
    padding: 24px 0;

    .innerBar {

        display: flex;
        justify-content: center;

        .footerLogoArea {

            .trustLogos {

                width: ~"calc(100% + 32px)";
                margin: 0 -16px -16px;
                justify-content: center;

                li {
                    padding: 0 16px;
                }

            }
        }
    }


}

/*
* Credits and Copyright
*/
.footerMeta {
    float: none;
    clear: both;
    width: 100%;
    background: @footer_bg_color;
    text-align: center;
    .rems(18);
    line-height: 24px;
    color: #fff;
    font-weight: normal;
	


    a {
        color: #fff;
        opacity: 1;
        transition: opacity 200ms linear, color 200ms linear;

        &:hover {
            color: #fff;
            opacity: 0.8;
            transition: opacity 200ms linear, color 200ms linear;
        }

        &.mtcLink {
            &:hover {
                color: @mtc_blue !important;
                opacity: 1 !important;
                transition: opacity 200ms linear, color 200ms linear;
            }
        }
    }

    &.lightBg {

        background: #fff;
        color: @text_color;
		border-top: none;

        a {
            color: @text_color;

            &:hover, &:active {
                color: @highlight_color;
            }

        }
    }

    .innerFooterBar {

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        margin: 0;
		padding: 25px 0;
		border-top: 2px solid rgba(255,255,255,0.04);
		letter-spacing: initial;
		

        @media all and (max-width: @wrapper_width) {
            width: 100%;
            margin: 0;
        }

        @media all and (max-width: @footer_meta_mobile_breakpoint) {
            width: 100%;
            margin: 0;
        }

		@media @flyout {
			padding: 25px 0 50px;
			flex-wrap: wrap;
		}

		@media @mobile{
			padding: 16px 0 44px;
			border-top: 2px solid rgba(255,255,255,0.04);
		}

        .credits {
            float: none;
            clear: both;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 @gutter_width 0 0;
            width: 70%;
            flex: 1 1 30%;

            @media all and (max-width: @wrapper_width) {
                padding: 0 @gutter_width_mobile 0 0;
            }

            @media all and (max-width: @footer_meta_mobile_breakpoint) {
                width: 100%;
                flex: 0 0 100%;
                justify-content: center;
                order: 2;
                padding: 0 0 12px;
            }

            span:not(.dash) {
                display: inline-block;
                width: 100%;
                text-align: left;

                @media all and (max-width: @footer_meta_mobile_breakpoint) {
                    text-align: center;
                }
            }

        }

        .paymentsBar {

            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 40%;
            padding: 0 @gutter_width;


            @media all and (max-width: @wrapper_width) {
                padding: 0 @gutter_width_mobile;
            }

            @media all and (max-width: @footer_meta_mobile_breakpoint) {
                width: 100%;
                margin-bottom: 16px;
                order: 1;
                padding: 0;
            }

			
        }
		
		.credits, .copyrightBlock {
			
			&, a {
				.rems(18);
				font-style: normal;
				font-weight: 500;
				line-height: 25.278px;
				text-transform: none;
			}
			
		}

        .copyrightBlock {
            float: none;
            clear: both;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
            padding: 0 0 0 @gutter_width;
            width: 30%;
            flex: 1 1 30%;

            @media all and (max-width: @wrapper_width) {
                padding: 0 0 0 @gutter_width_mobile;
            }

            @media all and (max-width: @footer_meta_mobile_breakpoint) {
                width: 100%;
                flex: 0 0 100%;
                order: 3;
                padding: 0;
            }

            .innerCopyright {
                text-align: right;
                width: 100%;

                @media all and (max-width: @footer_meta_mobile_breakpoint) {
                    text-align: center;
                }
            }
        }



    }


    br {
        display: none;

        @media @mobile {
            display: block;
        }
    }
    .dash {
        @media @mobile {
            display: none !important;
        }
    }

}
