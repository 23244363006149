/*
* Trust logos and banner
 */

@trust_banner_padding: 24px 0;
@trust_banner_bg_color: @tertiary_color;
@trust_banner_bg_color_mobile: @primary_color;

.trustLogos {

    display: flex;
    flex-direction: row;
    width: ~"calc(100% + 16px)";
    margin: 0 -8px -16px -8px;
    flex-wrap: wrap;
    justify-content: flex-start;
	height: 100%;
	
	.footerWrap & {
		width: auto;
		margin: 0;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
	
	&:before, &:after {
		display: none;
	}

    li {

        padding: 0 8px;
        margin-bottom: 16px;
		height: 100%;

		.footerWrap & {
			margin: 0;
		}

        a {

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            img {
                width: auto;
                height: 100%;
            }

        }

    }

}

.trustLogoBanner {

    background: @trust_banner_bg_color;
    padding: @trust_banner_padding;
    float: none;
    clear: both;

    &.mobileOnly {
        display: none;
        padding: 16px 0;

        @media @mobile {
            display: block;
            background: @trust_banner_bg_color_mobile;
        }

    }

    .innerTrustBanner {
        display: flex;
        align-items: center;
        justify-content: center;

        @media @mobile {
            justify-content: center;
        }

        .trustLogos {
            width: auto;
        }
    }
}
