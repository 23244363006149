//Sidebars
@sidebar_border_color: @border_color;
@sidebar_border_radius: @border_radius;
@sidebar_bg_color: #fff;
@sidebar_box_shadow: none;
@sidebar_padding: 24px;
@sidebar_padding_mobile: 16px;
@sidebar_margins: 0 0 32px 0;

/*
 * Sidebar Menu
 */

.sidebarMenu {

    ul {

        margin-top: 8px;

        li {
            position: relative;
            border-bottom: none;
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 0;
            }

            a {
                font-family: @primary_font;
                color: @text_color;
                text-decoration: none;
                .rems(15);
                line-height: 24px;
                text-transform: none;
                display: block;
                font-weight: 500;
                position: relative;
                transition: color 200ms linear;

                &:hover {
                    color: @highlight_color;
                    transition: color 200ms linear;
                }
            }

            &.active {

                a {
                    color: @highlight_color;
                    font-weight: bold;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

/*
 * Content Accordion Menus
 */

.accordionMenu {

    > li {
        border-bottom: 1px solid @border_color;
        position: relative;

        &.hasChildren:before {
            position: absolute;
            display: inline;
            .fa(f107);
            top: 10px;
            right: 0;
            transform: rotate(0);
            transition: transform .3s;
        }

        &.open {

            &:before {
                transform: rotate(-90deg);
            }
        }

        ul {
            display: none;

            li:last-child {
                border-bottom: 0;
            }
        }

        a {
            display: block;
        }
    }
}



.contentSidebar {


    nav ul {

        li {

            a {
                color: @text_color;
                text-decoration: none;

                &.onMe {
                    color: @primary_color;
                }

            }
        }
    }

    .innerSidebar {

        width: 100%;
        height: auto;

        .sidebarSection {

            margin: @sidebar_margins;
            border: 1px solid @sidebar_border_color;
            border-radius: @sidebar_border_radius;
            background: @sidebar_bg_color;
            box-shadow: @sidebar_box_shadow;
            padding: @sidebar_padding;


            @media @mobile {
                padding: @sidebar_padding_mobile;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &.sidebarNavSection {
                @media @flyout {
                    padding: 0;
                    border: none;
                }
            }

            .sidebarTitle {
                .rems(24);
                line-height: 32px;
                margin: 0;
                position: relative;

                & + .sidebarContent {
                    padding-top: 32px;
                }

                &.fullHeader {
                    position: relative;
                    margin: -25px -25px 24px -25px;
                    width: ~"calc(100% + 50px)";
                    padding: 24px;
                    color: #fff;
                    background: @primary_color;
                    border: 1px solid @primary_color;
                    border-radius: @border_radius @border_radius 0 0;
                    font-weight: bold;

                    span {
                        .rems(14);
                        letter-spacing: 0;
                        line-height: 24px;
                        font-weight: normal;
                    }
                }

                &.isClickable {

                    width: 100%;
                    height: auto;
                    margin: 0;
                    cursor: pointer;
                    .rems(18);
                    line-height: 24px;
                    padding: 16px 40px 16px 16px;
                    background: @secondary_color;
                    color: #fff;
                    border-radius: @border_radius;
                    transition: all 200ms linear;
                    font-weight: 600;

                    &:after {
                        position: absolute;
                        display: inline;
                        .fa(f107);
                        top: 50%;
                        right: 8px;
                        width: 32px;
                        height: 32px;
                        .rems(16);
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: translateY(-50%) rotate(0deg);
                        transform-origin: center;
                        transition: transform 200ms linear;
                        color: inherit;
                    }

                    &.open {

                        border-radius: @border_radius @border_radius 0 0;
                        transition: all 200ms linear;

                        &:after {
                            transform: translateY(-50%) rotate(-180deg);
                            transition: transform 200ms linear;
                        }
                    }

                    & + .sidebarContent {
                        padding: 16px;
                        border: 1px solid @sidebar_border_color;
                        border-top: none;
                        border-radius: 0 0 @border_radius @border_radius;
                    }
                }
            }

            p {
                .rems(14);
                line-height: 24px;
            }

            .sidebarContent {

                padding-top: 8px;

                p {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            .buttonWrap {

                padding-top: 16px;

                a {
                    width: 100%;
                    margin: 0;

                    @media @flyout {
                        width: auto;
                    }

                    @media @mobile {
                        width: 100%;
                    }
                }
            }
        }

    }

    &.hideMobile {

        .sidebarContactSection {
            @media @flyout {
                display: block;
            }
        }

        .sidebarNavSection {
            @media @flyout {
                display: none;
            }
        }

    }

    &.showMobile {
        display: none;

        @media @flyout {
            display: block;
            margin-bottom: 32px;
        }
    }
}

/*
* Members sidebar
 */
.contentSidebar.membersSidebar {

    float: none;
    clear: both;

    @media @flyout {
        min-height: 26px;
    }

    .headerTop {
        display: none;
        cursor: pointer;

        h3 {
            .rems(18);
            line-height: 26px;
            margin: 0;
            position: relative;
            font-weight: 500;
            transition: color 200ms linear;

            &:before {
                .fa(f078);
                width: 24px;
                height: 24px;
                border-radius: 50%;
                padding: 5px 7px;
                text-indent: 0;
                border: none;
                background: transparent;
                color: @text_color;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                display: flex;
                align-items: center;
                justify-content: center;
                .rems(16);
                line-height: 16px;
                transition: all 200ms linear;
                box-sizing: border-box;
                z-index: 2;
                font-weight: 400;

                .darkBg & {
                    color: #fff;
                    border-color: @border_color;
                }

            }
        }


        &.isClickable {
            display: block;

            h3 {

                font-weight: 600;

                &:hover, &:active, &:focus {
                    color: @secondary_color;
                    transition: color 200ms linear;

                    &:before {
                        color: @secondary_color;
                    }
                }
            }

            &.open {
                h3 {

                    &:before {
                        transition: all 200ms linear;
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }
    }

    nav  {

        display: block;
        width: 100%;

        @media @flyout {
            display: none;
            padding-top: 16px;
        }

        ul {

            display: block;
            width: 100%;

            &:before, &:after {
                display: none;
            }


            li {

                padding: 0;
                margin: 0;
                float: none;
                clear: both;

                a {

                    display: block;
                    width: 100%;
                    color: @text_color;
                    text-decoration: none;
                    .rems(18);
                    line-height: 32px;
                    font-weight: normal;
                    letter-spacing: 0;
                    padding: 16px 24px 16px 0;
                    position: relative;
                    cursor: pointer;

                    @media @flyout {
                        padding: 12px 0;
                        .rems(16);
                        line-height: 24px;
                    }

                    &:before {
                        content: "";
                        display: block;
                        width: 4px;
                        height: 100%;
                        background: @secondary_color;
                        border-radius: 4px 0 0 4px;
                        opacity: 0;
                        transition: opacity 200ms linear;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        display: none !important;

                        @media @flyout {
                            display: none;
                        }
                    }

                    .menuTitle {
                        display: block;
                        width: 100%;
                        color: @text_color;
                        transition: color 200ms linear;
                        font-weight: bold;
                    }

                    &:hover {
                        .menuTitle {
                            color: @secondary_color;
                            transition: color 200ms linear;
                        }
                    }

                    &:active {
                        .menuTitle {
                            color: darken(@secondary_color, 4%);
                            transition: color 200ms linear;
                        }
                    }

                    .menuSubtitle {
                        display: block;
                        width: 100%;
                        padding: 4px 0 0;
                        .rems(12);
                        line-height: 14px;
                        opacity: 0.64;
                    }

                    &.active, &.onMe {

                        &:before {
                            opacity: 1;
                            transition: opacity 200ms linear;
                        }

                        .menuTitle {

                            color: @secondary_color;

                            @media @flyout {
                                color: @secondary_color;
                            }
                        }

                    }

                }
            }
        }
    }

}
