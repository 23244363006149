@intro_block_max_width: 735px;


.introBlock {

    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 43px;
    color: @dark_primary;
	
    @media @mobile {
		margin-bottom: 40px;
	}
	
	.wrapper {
		width: 100%;
	}
	

    &.darkBg, .darkBg & {
        color: #fff;
    }

    &.introTitle {
        text-align: left;
    }

    &.alignLeft {
        text-align: left;
        justify-content: flex-start;

        @media all and (max-width: 800px) {
            text-align: center;
            justify-content: center;
        }

        .introContentWrap {
            max-width: 100%;
        }
    }


    .innerIntro {
        width: 100%;
        max-width: @intro_block_max_width;
        color: inherit;
        position: relative;

    }

    .subtitle {

        margin: 0 0 8px;
        .rems(14);
        line-height: 14px;
        position: relative;
        padding: 0;
        font-weight: bold;

        &:before {
            display: none;
        }

    }

    .introTitle {

		.rems(44);
		font-weight: 500;
		line-height: 52.91px;
		letter-spacing: -1.76px;
        text-align: inherit;
        margin: 0;
        position: relative;
        z-index: 2;
		
        @media @mobile {
			.rems(32);
			line-height: 1.2em;
        }

        &.smallTitle {
			.rems(25);
			line-height: 1.2em;

            @media @mobile {
				.rems(25);
				line-height: 1.2em;
            }
        }


        & + .introContentWrap {
            margin-top: 15px;
        }
		
		a {
			color: inherit;
			transition: color 200ms linear;

			&:hover, &:active {
				color: @highlight_color;
				transition: color 200ms linear;
			}

		}
    }

    .introContentWrap {

        max-width: 735px;
        margin-left: auto;
        margin-right: auto;
        color: @text_color_alt;

		.rems(19);
		font-style: normal;
		font-weight: 400;
		line-height: 28.278px; 


        @media @mobile {
            .rems(17);
            line-height: 24px;
        }

        .darkBg & {
            color: #fff;
        }

        p, ul, ol {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            margin: 0 auto;
            font-weight: inherit;
            letter-spacing: inherit;

        }

    }


    .buttonWrap {
        padding: 24px 0 0;

        .button, .buttonAlt {
            margin: 0;

			@media all and (max-width: 540px) {
				width: 100%;
			}
			
        }
    }


	&.horizontalIntro {
		.innerIntro {
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	@media all and (min-width: 1100px) {
		&.horizontalIntro {

			margin-bottom: 49px;
	

			.innerIntro {
				display: flex;
				justify-content: flex-start;
				width: 100%;
				max-width: 100%;
				text-align: left;
			}

			.introTitle {
				flex: 0 0 auto;
				margin: 0;
				padding: 0 25px 0 0;
				display: flex;
				align-items: flex-end;
				.rems(28);
				line-height: 28px;
			}

			.introContentWrap {
				flex: 0 0 auto;
				margin: 0;
				padding: 0 25px 0 0;
				display: flex;
				align-items: flex-end;
				.rems(14);
				line-height: 18px;
			}

			.buttonWrap {
				padding: 0;
				margin: 0 0 0 auto;
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				position: relative;
				top: 10px;
				
				.button {
					white-space: nowrap;
					padding-top: 14px;
					padding-bottom: 14px;
				}
			}

		}
	}

}


