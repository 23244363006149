@button_color: @primary_color;
@button_color_hover: darken(@button_color, 8%);
@button_color_focus: darken(@button_color, 8%);

@button_color_alt: @secondary_color;
@button_color_alt_hover: darken(@button_color_alt, 8%);
@button_color_alt_focus: darken(@button_color_alt, 8%);

@button_top_bottom_padding: 0.5768em;
@button_side_padding: 1.2em;


@button_border_radius: 50px;

/*
 * Button Styling
 */

.button {
    position: relative;
    display: inline-block;
    background: @button_color;
    border: 1.5px solid @button_color;
    margin: 0 0 16px;
    padding: @button_top_bottom_padding @button_side_padding;
    cursor: pointer;
    text-align: center;
    outline: none;
    font-family: @primary_font;
    color: #fff;
    font-weight: 700;
    .rems(17);
    line-height: 1.2em;
    transition: all 200ms linear;
    border-radius: @button_border_radius;
    text-decoration: none;
    box-shadow: none;
    z-index: 1;
    overflow: hidden;

    &:hover {
        color: #fff;
        background: @button_color_hover;
		border-color: @button_color_hover;
        transition: all 200ms linear;
    }

    &:focus-visible {
        color: #fff;
        background: @button_color_hover;
		border-color: @button_color_hover;
        transition: all 200ms linear;
    }

    &:active {
        color: #fff;
        background: @button_color_focus;
        border-color: @button_color_focus;
        transition: all 200ms linear;
    }


    i {
        margin: 0 0 0 11px;
        width: auto;
        height: auto;
        display: inline-block;
        text-align: center;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }

    &.iconLeft {

        i {
            margin: 0 11px 0 0;
        }

    }


	&.buttonText {
		color: @text_color;
		background: transparent;
		border-color: transparent;
		position: relative;
		
		&:hover, &:active, &:focus-visible {
			color: @highlight_color;
			background: transparent;
			border-color: transparent;
		}
	}


    &[disabled] {
        pointer-events: none;
        opacity: 0.4;
    }


    &.buttonOutlined {

        background: transparent;
        color: @text_color;

        &:hover {
            background: @button_color_hover;
            border-color: @button_color_hover;
            color: #fff;
        }

        &:focus-visible {
            background: @button_color_focus;
            border-color: @button_color_focus;
            color: #fff;
        }

        &:active {
            background: @button_color_hover;
            border-color: @button_color_hover;
            color: #fff;
        }

    }
	
	&.buttonRed {
		background: @alert_color;
		border-color: @alert_color;
		
		&:hover, &:focus-visible {
			background: darken(@alert_color, 8%);
			border-color: darken(@alert_color, 8%);
		}
		
		&:active {
			background: darken(@alert_color, 4%);
			border-color: darken(@alert_color, 4%);
		}
	}

	&.buttonGreen {
		background: @success_color;
		border-color: @success_color;

		&:hover, &:focus-visible {
			background: darken(@success_color, 8%);
			border-color: darken(@success_color, 8%);
		}

		&:active {
			background: darken(@success_color, 4%);
			border-color: darken(@success_color, 4%);
		}
	}
	

}



/*
* Button Alt
*/
.buttonAlt {
    .button();
    color: #fff;
    background: @button_color_alt;
    border: 2px solid @button_color_alt;

    &:hover {
        color: #fff;
        background: @button_color_alt_hover;
        border: 2px solid @button_color_alt_hover;
    }

    &:focus-visible {
        color: #fff;
        background: @button_color_alt_focus;
        border: 2px solid @button_color_alt_focus;
    }

    &:active {
        color: #fff;
        background: @button_color_alt_hover;
        border: 2px solid @button_color_alt_hover;
        transition: all 200ms linear;
    }

    &.buttonOutlined {

        background: transparent;
        color: @button_color_alt;

        &:hover {
            background: @button_color_alt_hover;
            border-color: @button_color_alt_hover;
            color: #fff;
        }

        &:focus-visible {
            background: @button_color_alt_focus;
            border-color: @button_color_alt_focus;
            color: #fff;
        }

        &:active {
            background: @button_color_alt_hover;
            border-color: @button_color_alt_hover;
            color: #fff;
        }


    }

    &[disabled] {

        color: #fff !important;
        background: @button_color_alt !important;
        border-color: @button_color_alt !important;


        &.buttonOutlined {
            background: transparent !important;
            color: @button_color_alt !important;
            border-color: @button_color_alt !important;
        }

        &.buttonText {
            color: @button_color_alt !important;
            background: transparent !important;
            border-color: transparent !important;
        }

    }

}


.buttonOptionSelected {
	color: #fff;
	border-color: @alert_color;
	background: @alert_color;


	&:hover {
		color: #fff;
		background: darken(@alert_color, 8%);
		border-color: darken(@alert_color, 8%);
	}

	&:focus {
		color: #fff;
		background: darken(@alert_color, 8%);
		border-color: darken(@alert_color, 8%);
	}

	&:active {
		color: #fff;
		background: darken(@alert_color, 4%);
		border-color: darken(@alert_color, 4%);
	}


	&[disabled] {
		color: #fff !important;
		background: @alert_color !important;
		border-color: @alert_color !important;
	}
}

/*
* Button alert
 */
.buttonAlert {
    .button();
    color: #fff;
    background: @alert_color;
    border: 2px solid @alert_color;

    &:hover {
        color: #fff;
        background: darken(@alert_color, 10%);
        border: 2px solid darken(@alert_color, 10%);
    }

    &:focus {
        color: #fff;
        background: darken(@alert_color, 10%);
        border: 2px solid darken(@alert_color, 10%);
    }

    &:active {
        color: #fff;
        background: darken(@alert_color, 8%);
        border: 2px solid darken(@alert_color, 8%);
        transition: all 200ms linear;
    }


    &[disabled] {

        color: #fff !important;
        background: @alert_color !important;
        border-color: @alert_color !important;


    }

}

/*
 * Button Groups
 */

.buttonGroup {

    float: none;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    align-items: flex-start;

    &:before, &:after {
        display: none;
    }

    a {
        margin-right: 16px;
        height: auto;
        flex: 0 0 auto;

        &:last-of-type {
            margin-right: 0;
        }
    }

}

.buttonMini {
    .rems(10);
    padding: 1em;

}

.toggleButtonWrap {
    text-decoration: none;
}

.toggleButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    text-decoration: none;
    font-weight: 400;
    .rems(14);
    line-height: 14px;
    border-radius: 50%;
    overflow: hidden;
    text-shadow: none;

    &:before, &:after {
        content: "";
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        .rems(14);
        line-height: 14px;
    }

    &:before {
        position: relative;
        z-index: 2;
        text-decoration: none;
        color: @primary_color;
        transition: all 200ms linear;
        text-shadow: none;

        .darkBg & {
            color: #fff;
        }
    }

    &:after {
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        background: @secondary_color;
        opacity: 0;
        width: 0;
        height: 0;
        transition: all 200ms linear;
    }

    .open & {
        &:before {
            color: @primary_color;

            .darkBg & {
                color: #fff;
            }
        }
    }

    &:hover, &:active {
        &:after {
            width: 100%;
            height: 100%;
            opacity: 0.24;
            transition: all 200ms linear;
        }
    }

    &:active {
        &:after {
            opacity: 0.64;
        }
    }

    &.toggleAlt {

        &:before {
            color: @secondary_color;

            .darkBg & {
                color: @secondary_color;
            }
        }
    }

    &.toggleBordered {

        border: 1px solid rgba(23, 39, 65, 0.4);

        .darkBg & {
            border-color: #fff;
        }

    }
}


.buttonIcon {
	.button();
	
	background: transparent;
	margin: 0;
	border-color: transparent;
	color: @text_color;
	padding: 14px 0;
	text-align: left;
	width: auto;
	max-width: none;
	
	&:hover, &:active, &:focus {
		background: transparent;
		border-color: transparent;
		color: @highlight_color;
	}
	
	.svgIcon {
		display: inline-block;
		width: 14px;
		height: 14px;
		margin-left: 4px;
		color: @highlight_color;
		position: relative;
		top: 1px;
		
		svg {
			width: 100%;
			height: 100%;
		}
	}
}
