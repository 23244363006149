
.landingItemsWrap {
    float: none;
    clear: both;
    margin: 0;
    padding: 100px 0;
    background: #fff;


    @media @mobile {
        padding: 50px 0;

    }
	
	& > .wrapper {
		min-height: 504.72px;
		
		@media @mobile {
			padding-left: 10px;
			padding-right: 10px;
			min-height: 0;
		}
	}

}

.emptyListings {

    text-align: center;
    min-height: 300px;
    float: none;
    clear: both;
    padding: 56px 0;

    @media @mobile {
        min-height: 0;
        padding: 40px 0;
    }

    h4 {
        .rems(24);
        line-height: 32px;
        margin-bottom: 8px;
    }

    p {
        margin: 0;
    }
}
