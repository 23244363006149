@import '_form_variables.less';

form {
    .clearfix();
	
	.row {
		text-transform: none;
	}

    fieldset {
        border: 0;
        margin: 0 0 24px;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }

    }

    legend {
        display: none;
    }

    .buttonGroup:last-child {
        margin-bottom: 0;
    }
	

    /*
     * Hide Anti Spam Field
     */

    .answer {
        display: none;
    }
}

/*
 * Datepicker styles
 */

.ui-datepicker-div {

    a {
        text-decoration: none;
    }
}

.row {
    float: left;
    width: 100%;
    margin: 0 0 24px;
    .clearfix();

    @media @mobile {
        margin: 0 0 16px;
    }

	
    label {
        float: none;
		clear: both;
        font-family: @body_font;
        color: @dark_primary;
        font-weight: @label_font_weight;
        position: relative;
        .rems(@label_font_size);
        line-height: @label_line-height;
        z-index: 2;
        margin: 0 0 8px;
        padding: 0;
        background: transparent;
    }

    .inputWrap, .datepickerWrap {
        float: none;
		clear: both;
        width: 100%;

        &.phoneWrap {
            .text {
                width: ~"calc(100% - 123px)";

                &.prefix {
                    width: 107px;
                    margin-right: 16px;
                    text-align: center;
                }

                &.hiddenPrefix {
                    display: none;

                    & + input {
                        width: 100%;
                    }
                }
            }
        }

        &.dobWrap {
            #dobMonth,
            #dobDate {
                text-align: center;
                width: ~"calc(30% - 16px)";
                margin-right: 16px;

            }

            #dobYear {
                text-align: center;
                width: 40%;
            }



        }

        &.numberArrowWrap {
            position: relative;

            input:not(.right) {
                padding-left: 35px;
            }
        }

        .numberButtons {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 35px;

            .numberButton {
                position: absolute;
                left: 0px;
                text-align: center;
                width: 100%;
                z-index: 300;
                height: 22.5px;
                cursor: pointer;

                &.add {
                    top: 1px;
                    line-height: 30px;
                }

                &.subtract {
                    bottom: 1px;
                    line-height: 20px;
                }
            }
        }

        .numberArrows {

            &.right {
                padding-right: 35px;

                + .numberButtons {
                    left: auto;
                    right: 0;
                }
            }

            &.fade {

                + .numberButtons {
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 400ms linear 0s, visibility 0s linear 400ms;
                    z-index: 100;

                    &:hover {
                        opacity: 1;
                        visibility: visible;
                        transition-delay: 0s;
                    }
                }

                &:hover {

                    + .numberButtons {
                        opacity: 1;
                        visibility: visible;
                        transition-delay: 0s;
                    }
                }
            }
        }
    }

    input:not([type='checkbox']), input:not([type='radio']), textarea, select, .chosen-search input {
        float: none;
        clear: both;
        position: relative;
        width: 100%;
        padding: @form_padding_top_bottom @form_padding_sides;
        border: @form_border_width solid @form_border_color;
        .rems(@form_font_size);
        line-height: @form_line_height;
        border-radius: @form_border_radius;
        font-family: @body_font;
        transition: all 200ms linear;
        z-index: 1;
        -webkit-appearance: none;
        background: #fff;
        .placeholder(fade(@form_text_color, 40%));
        color: @form_text_color;
        min-height: @field_min_height;
        font-weight: @form_font_weight;
		text-transform: none;
	


        &:focus {
            border-color: @form_highlight_color;
            transition: all 200ms linear;
        }


    }

    select {
        min-height: @field_min_height;
        cursor: pointer;
    }

    textarea {
        float: left;
        min-height: 140px;
    }

    input::placeholder {
        opacity: 1 !important;

    }

    textarea::placeholder {
        opacity: 1 !important;

    }

    &.fieldIcon {

        .inputWrap {

            position: relative;

            input {
                padding-right: @form_padding_icon_offset;
                position: relative;
                z-index: 1;
            }

            &:after {
                position: absolute;
                top: @form_icons_top;
                right: @form_icons_side;
                width: @form_icons_width;
                height: @field_min_height;
                display: flex;
                align-items: center;
                justify-content: center;
                .rems(14);
                line-height: 16px;
                color: @form_icons_color !important;
                transition: color 200ms linear;
                font-weight: 400 !important;
                z-index: 1;
                pointer-events: none;

            }

        }

    }

    .datepickerWrap {
        position: relative;

        input {
            padding-right: @form_padding_icon_offset;
            position: relative;
            z-index: 1;
        }

        &:after {
            position: absolute;
            top: @form_icons_top !important;
            right: @form_icons_side !important;
            width: @form_icons_width;
            height: @field_min_height;
            display: flex !important;
            align-items: center;
            justify-content: center;
            .rems(14);
            line-height: 16px;
            color: @form_icons_color !important;
            transform: none !important;
            transition: color 200ms linear;
            font-weight: 400 !important;
            z-index: 1 !important;
            pointer-events: none;

        }
    }

    &.errorWrap {
        .datepickerWrap {
            &:after {
                color: @alert_color !important;
            }
        }

		&.fieldIcon {

			.inputWrap {
				&:after {
					color: @alert_color !important;
					background: transparent !important;
				}
			}
		}
    }

    .validationMessage, .assistiveText {
        .clearfix();
        clear: both;
        padding: 7px 16px 0 0;
        z-index: 5;
        .rems(14);
        line-height: 18px;
        font-weight: normal;
        display: none;
    }

    .inputWrap {

        .assistiveText {
            padding: 8px 16px 0 0;
            width: 100%;
        }

    }

    .buttonInline {
        clear: both;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        position: relative;

        @media @mobile {
            flex-wrap: wrap;
        }

        .chosen-container {
            min-width: 0;
            flex: 1 1 auto;
        }

        .row {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;

            @media @mobile {
                flex-wrap: wrap;
            }
        }

        &:after {
            display: none !important;
        }


        select, input, .chosen-single {


            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            min-width: 0;
            width: auto;
            flex: 1 1 60%;
            min-height: @field_min_height;
            z-index: 1;
			height: 44px;

            @media @mobile {
                margin-bottom: 0;
                width: 100%;
				flex: 0 0 100%;
                border-radius: @form_border_radius;
                order: 1;
            }
        }

        .chosen-single {
            border-top-right-radius: 0 !important;

            @media @mobile {
                border-top-right-radius: @border_radius !important;
            }
        }

        .text {
            padding-top: 14px;
            padding-bottom: 14px;
        }

        .withIcon {
            border-radius: @form_border_radius;
            padding-right: 56px;
        }

        & > div {
            @media @mobile {
                order: 2;
            }
        }

        .iconButton {

            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            color: @form_icons_color;
            background: transparent;
            box-shadow: none;
            outline: 0;
            height: 56px;
            width: 56px;
            padding-top: @form_padding_top_bottom;
            padding-bottom: @form_padding_top_bottom;
            border-radius: 0 @form_border_radius @form_border_radius 0;
            transition: color 200ms linear;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            cursor: pointer;

            i {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover {
                color: fade(@text_color, 80%);
                background: transparent;
                transition: color 200ms linear;
            }

            &:focus, &:active {
                color: fade(@text_color, 100%);
                background: transparent;
                transition: color 200ms linear;
            }

        }

        button, .button, .buttonAlt {

            white-space: nowrap;
            border-radius: 0 @form_border_radius @form_border_radius 0;
            padding-top: @form_padding_top_bottom;
            padding-bottom: @form_padding_top_bottom;
			padding-left: 8px;
			padding-right: 8px;
            box-shadow: none;
            outline: 0;
            width: auto;
            flex: 0 0 40%;
            height: @field_min_height;
            margin: 0;
            background: @primary_color;
            border-color: @primary_color;
            .rems(12);
            line-height: 12px;
			text-align: center;
			

            @media @mobile {
                width: 100%;
                order: 3;
                .rems(14);
                line-height: 20px;
                padding: 12px 24px;
                margin: 12px 0 0 0;
                border-radius: 100px;
                height: auto;
                min-height: 48px;
				flex: 0 0 100%;
            }


            &:after {

                display: none !important;

            }

            &:before {
                display: none !important;
            }

            &:hover {

                background: darken(@primary_color, 10%);
                border-color: darken(@primary_color, 10%);

            }

            &:focus, &:active {
                background: darken(@primary_color, 5%);
                border-color: darken(@primary_color, 5%);
            }


        }
		
		.assistiveText {
			float: none;
			clear: both;
			width: 100%;
		}
    }

    &.requiredField:not(.errorWrap), &.requiredField:not(.successWrap) {
		
        .inputWrap {
            position: relative;
        }
    }

    &.errorWrap, &.successWrap {

        .validationMessage {
            display: block;
        }

        .assistiveText {
            display: none;
        }

        .inputWrap, .datepickerWrap {
            position: relative;

        }

    }

    &.errorWrap {

        .validationMessage {
            color: @alert_color;
        }

        .inputWrap {

            &:after {
                .rems(16);
                //.fa(f06a, @alert_color);
                background: @alert_color;
            }
        }

        input:not([type='checkbox']), input:not([type='radio']), textarea, select, .chosen-search input,
        .chosen-container .chosen-single {
            border-color: @alert_color !important;
        }

        // disable chosen select icon as error exists
        .chosen-container {

            .chosen-single {

                div {

                    b {

                        &:after {
                            color: @alert_color;
                        }
                    }
                }
            }

            .chosen-drop {
                border-color: @alert_color;
            }
        }

        label {
            color: @alert_color !important;

            a {
                &, &:hover, &:active {
                    color: @alert_color !important;
                }
            }
        }


    }

    &.successWrap {

        .validationMessage {
            color: @success_color;
        }

        .inputWrap {

            &:after {
                .rems(16);
                //.fa(f058, @success_color);
                background: @success_color;
            }
        }

        input:not([type='checkbox']), input:not([type='radio']), textarea, select, .chosen-search input {
            border-color: @success_color !important;
        }

        label {
            color: @success_color !important;
        }
    }

    input[disabled]:not(.datePicker):not(.datepicker),
    textarea[disabled],
    input[readonly]:not(.datePicker):not(.datepicker),
    textarea[readonly] {
        opacity: 0.4;
        cursor: default;
        pointer-events: none;

        & + .assistiveText {
            opacity: 0.4;
        }
    }

    /*
     * Checkboxes
     */

    input[type=checkbox] {
        float: none;
        display: inline;
        margin: 0;
        padding: 0;
        *height: 16px;
        *width: 16px;
    }

    input[type=checkbox]:not(.toggleSwitch), input[type=radio]:not(.toggleSwitch) {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            float: none;
			clear: both;
            padding: 6.5px 0 6.5px 20px;
            cursor: pointer;
            user-select: none;
            width: 100%;
            margin: 0;
            .rems(@form_font_size);
            line-height: @form_line_height;
            background: transparent !important;
			font-weight: normal;
			color: @dark_primary;

            &:before, &:after {
                position: absolute;
                top: 9.5px;
                left: 0;
                transform: translateY(0);
                width: 15px;
                height: 15px;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border-radius: 4px;
				
            }

            &:before {
                content: "";
                border: 1.5px solid @dark_primary;
                background: #fff;
                z-index: 2;
            }

            &:after {
				//Primary color
				content: url("data:image/svg+xml; utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' %3E%3Cpath d='M493.3 128l-22.6 22.6-256 256L192 429.3l-22.6-22.6-128-128L18.7 256 64 210.7l22.6 22.6L192 338.7 425.4 105.4 448 82.7 493.3 128z' fill='%233F8FA0'/%3E%3C/svg%3E");
                z-index: 3;
                opacity: 0;
				display: block;
				padding: 3px;
				top: 2.5px;
            }
			
            &.inputError {
                border: none !important;

                &:before {
                    border: none;
                    border-color: @alert_color;
                }
            }
			
        }

        

        &:checked {

            & + label {

                &:before {
                    border-color: @primary_color;
                    background: #fff;
                }

                &:after {
                    opacity: 1;
                }

            }
        }


        &[type=radio] {

            & + label {
				
				
                &:before, &:after {
                    border-radius: 50%;
					margin: 0 !important;
                }

                &:after {
					content: "";
					width: 10px !important;
					height: 10px !important;
					background: @primary_color;
					display: block;
					top: 12px !important;
					left: 2.5px !important;
                }
            }

            &:checked {

                & + label {

                    &:before, &:after {
                        border-radius: 50%;
                    }

                    &:before {
                        background: fade(@primary_color, 0);
                    }

                    &:after {
						content: "";
						width: 12px;
						height: 12px;
						background: @primary_color;
						display: block;
						margin: 3px;
                    }
                }

            }

        }

        & + label + .validationMessage, & + label + .assistiveText {
            padding-left: 0;
        }


        &[disabled] {

            & + label {
                opacity: 0.4;
                pointer-events: none;
            }
        }

    }

    .radioButtons {

        display: flex;
        position: relative;
        float: none;
        clear: both;
        backface-visibility: hidden;

        ul:not(.chosen-results) {

            display: flex;
            flex-wrap: wrap;
            width: ~"calc(100% + 8px)";
            margin: 0 -4px - 8px -4px;

            &:before, &:after {
                display: none;
            }

            & > li {
                padding: 0 4px;
                display: flex;
                margin-bottom: 8px;

                label {
                    width: 100%;
                    white-space: nowrap;
                    margin: 0 !important;
                }
            }
        }


		.labelButton {
			padding-right: 12px;
			
			&:last-of-type {
				padding-right: 0;
			}
		}

        .labelButton > input[type="radio"], input[type="radio"] {

            visibility: hidden;
            opacity: 0;
            position: absolute;
            left: -9999px;


            & + label {
                padding: 5px 16px;
				min-width: 100px;
                .rems(17) !important;
                line-height: 30px !important;
                font-weight: 400;
                text-align: center;
                float: none;
                clear: both;
                backface-visibility: hidden;
                border-radius: 4px;
                width: auto;
                transition: all 200ms linear;
                display: block;

                color: @dark_primary;
                border: 1px solid @border_color;
                background: fade(@primary_color, 0%);

                &:hover, &:active, &:focus-visible {
                    color: #fff;
                    border-color: @dark_primary;
                    background: fade(@dark_primary, 100%) !important;
                }


                &:before, &:after {
                    display: none !important;
                }
				
            }

            &:checked {

                & + label {

                    color: #fff;
                    border-color: @primary_color;
                    background: @primary_color !important;

                    transition: all 200ms linear;

                    &:hover, &:active, &:focus {
                        color: #fff;
                        border-color: @primary_color;
                        background: @primary_color !important;
                    }
                }


            }


        }



    }

    &.errorWrap {

        .radioButtons {


            .labelButton > input[type="radio"], input[type="radio"] {


                &:not(:checked) + label {
                    color: @alert_color;
                    border-color: @form_border_color !important;
                    background: fade(@alert_color, 0%);

                    &:hover, &:active, &:focus {
                        color: @alert_color !important;
                        border-color: @alert_color !important;
                        background: fade(@alert_color, 0%) !important;
                    }
                }

                &:checked + label  {

                    color: @alert_color !important;
                    border-color: @alert_color !important;
                    background: fade(@alert_color, 8%) !important;
                    transition: all 200ms linear;

                    &:hover, &:active, &:focus {
                        color: @alert_color !important;
                        border-color: @alert_color !important;
                        background: fade(@alert_color, 8%) !important;
                    }

                }



            }



        }

    }

    .assistiveLink {
		
        float: none;
        clear: both;
        width: 100%;
        .rems(12);
        line-height: 14px;
        margin: 0;
        padding: 4px 0 0 0;
        position: relative;
		opacity: 1;
		color: @text_color;
		display: block;
		text-transform: none;

    }

}



/*
 * Checkbox Toggle - Similar to the one seen in iOS
 */

.row input[type=checkbox].toggleSwitch {

    display: none;

    & + label {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 0 10px 50px;
        width: 100%;
        margin: 0;
        .rems(14);
        line-height: 16px;
        background: transparent !important;


        &:before {
            content: '';
            position: absolute;
            display: block;
            height: 14px;
            width: 34px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            border-radius: 10px;
            background: #C7CBD1;
            transition: left 200ms linear, background 200ms linear;

        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 20px;
            width: 20px;
            margin: 0;
            top: 50% !important;
            left: 0 !important;
            transform: translateY(-50%);
            border-radius: 50%;
            background: #A1A7B1;
            transition: left 200ms linear, background 200ms linear;
        }

        .hoverFX {
            position: absolute;
            left: -10px !important;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
            z-index: 1;
            border-radius: 50%;
            border: none;
            background: fade(#000, 0);
            transition: background 200ms linear, border-color 200ms linear, left 200ms linear !important;

        }

        &:hover {
            .hoverFX {
                border-color: fade(#000, 0);
                background: fade(#000, 8%);
                transition: background 200ms linear, border-color 200ms linear;

            }
        }

        &:active {

            .hoverFX {
                border-color: fade(#000, 0);
                background: fade(#000, 16%);
                transition: background 200ms linear, border-color 200ms linear;

            }
        }

    }

    &[disabled] {
        & + label {
            opacity: 0.4;
            pointer-events: none;
        }
    }
	

    &:checked {
		
        & + label {

            .hoverFX {
                border-color: fade(@secondary_color, 0);
                background: fade(@secondary_color, 0);
                left: 4px !important;
                transition: background 200ms linear, border-color 200ms linear, left 200ms linear !important;
            }

            &:hover {
                .hoverFX {
                    border-color: fade(@secondary_color, 0);
                    background: fade(@secondary_color, 8%);
                    transition: background 200ms linear, border-color 200ms linear;
                }
            }

            &:active {

                .hoverFX {
                    border-color: fade(@secondary_color, 0);
                    background: fade(@secondary_color, 16%);
                    transition: background 200ms linear, border-color 200ms linear;
                }
            }

            &:before {
                background: fade(@secondary_color, 20%);
                transition: left 200ms linear, background 200ms linear;
            }

            &:after {
                left: 14px !important;
                background: @secondary_color;
                transition: left 200ms linear, background 200ms linear;
            }

        }

    }
}

/*
 * Form inline styles
 */

.formInline {

    .row {

        .datepickerWrap, .buttonInline {
            clear: none;
        }

        label {

            width: 30%;
            padding: 14px 16px 14px 0;
            .rems(12);
            line-height: 16px;
            color: @dark_primary;
            background: transparent;
            margin: 0;

        }

        .errorWrap {

            label {
                color: @alert_color;


                a {

                    &, &:hover, &:active {
                        color: @alert_color;

                    }
                }
            }


        }

        .successWrap {

            label {
                color: @success_color;

            }

        }

        .inputWrap, .datepickerWrap {
            padding: 0;
            width: 70%;
        }

        .mobileChosen {

            select {
                position: absolute;
                width: 100%;
            }
        }
    }

    .validationMessage, .assistiveText {
        width: 70%;
        float: right;
        padding-left: 0;

        @media @mobile {
            padding-left: 16px;
            width: 100%;
            float: none;
        }
    }

    input[type=radio] + label + div,
    input[type=checkbox] + label + div {
        float: none;
        width: 100%;
        padding-left: 0;
    }

    @media @mobile {

        .row {

            label {
                clear: both;
                width: 100%;
            }

            .inputWrap, .datepickerWrap {
                padding: 0;
                width: 100%;

            }
        }
    }
}

/*
 * Tooltip styles
 */

.toolTip {
    position: relative;
    display: inline-block;
    cursor: pointer;

    i {
        color: @form_highlight_color;
        float: none;
        clear: both;
        font-size: inherit;
        line-height: inherit;

    }

    .tip {
        content: attr(data-tooltip);
        border-radius: 4px;
        padding: 16px;
        min-width: 200px;
        color: #fff;
        text-align: left;
        position: absolute;
        background: @form_highlight_color;
        z-index: 9999;
        border: solid 1px @form_highlight_color;
        display: none;


        &, p {
            .rems(12);
            line-height: 14px;
            font-weight: normal;
        }


        h4 {
            color: #fff;
            .rems(14);
            line-height: 16px;
            margin-bottom: 4px;
        }

         p {
             margin: 0;
             color: #fff;
         }
    }

    &[data-tooltip]:before {
        content: attr(data-tooltip);
        border-radius: 4px;
        padding: 16px;
        min-width: 200px;
        color: #fff;
        text-align: left;
        position: absolute;
        background: @form_highlight_color;
        z-index: 9999;
        border: solid 1px @form_highlight_color;
        display: none;
        .rems(12);
        line-height: 14px;
        font-weight: normal;

    }

    &:after {
        content: '';
        position: absolute;
        z-index: 9998;
        display: none;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 10px 6px;
        border-color: transparent transparent @form_highlight_color transparent;

    }



    &:hover {

        &:before, &:after, .tip {
            display: block;
        }
    }

    &.tooltipTop {

        &:before, .tip {
            bottom: calc(~"100% + 16px");
            left: -16px;
        }

        &:after {
            bottom: calc(~"100% + 6px");
            left: 2px;
            border-width: 10px 6px 0 6px;
            border-color: @form_highlight_color transparent transparent transparent;

        }
    }

    &.tooltipRight {

        &:before, .tip {
            left: calc(~"100% + 16px");
            top: 50%;
            transform: translateY(-50%);

        }

        &:after {
            left: calc(~"100% + 5px");
            top: 8px;
            border-width: 5px 12px 5px 0;
            border-color: transparent @form_highlight_color transparent transparent;

        }
    }

    &.tooltipBottom {

        &:before, .tip {
            top: calc(~"100% + 16px");
            left: -16px;

        }

        &:after {
            top: calc(~"100% + 6px");
            left: 2px;
            border-width: 0 6px 10px 6px;
            border-color: transparent transparent @form_highlight_color transparent;

        }
    }

    &.tooltipLeft {

        &:before, .tip {
            right: calc(~"100% + 16px");
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            right: calc(~"100% + 4px");
            top: 8px;
            border-width: 5px 0 5px 12px;
            border-color: transparent transparent transparent @form_highlight_color;

        }
    }

}

.ps-container > .ps-scrollbar-y-rail {
    width: 5px;
	background-color: #E4E4E4;
	opacity: 1 !important;
	border-radius: 0;

	.ps-scrollbar-y {
        width: 5px;
		background-color: #5F5F5F;
	}
}

.tagitWrapper {

    ul.tagit {

        padding: 5px 16px 3px !important;
        display: flex;
        flex-wrap: wrap;
        border-radius: @form_border_radius;
        min-height: @field_min_height;
        border: @form_border_width solid @form_border_color;



        & > li {

            display: flex;
            align-items: center;
            padding: 0 8px 0 0 !important;
            margin: 0 !important;
            float: none;
            clear: both;


            input[type="text"] {

                background: #fff;
                border: none;
                border-radius: 0;
                padding: 0 0 3px 0 !important;
                min-height: 32px;

            }


            &.tagit-choice {

                padding: 1px 8px 2px 16px !important;
                background: @primary_color;
                border-radius: @form_border_radius;
                margin-bottom: 3px !important;
                margin-right: 4px !important;

                .tagit-label {
                    .rems(@form_font_size);
                    line-height: (@form_line_height - 6px);
                    float: none;
                    clear: both;
                    display: flex;
                    color: #fff;
                    white-space: nowrap;
					font-weight: 700;
                }

                .tagit-close {
                    position: relative !important;
                    top: auto;
                    right: auto;
                    left: auto;
                    bottom: auto;
                    transform: none;
                    .rems(16);
                    line-height: (@form_line_height - 6px);
                    margin: 0 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    color: #fff;
                    transition: color 200ms linear;

                    &:hover {
                        color: @alert_color;
                        transition: color 200ms linear;
                    }

                    span {
                        position: relative;
                        top: 0;
                    }

                }

            }

            &.tagit-choice-read-only {

            }

            &.tagit-choice-editable {

            }

            &.tagit-new {
                margin: 0 !important;
            }

        }

        input.tagit-hidden-field {

        }
    }

}

.ui-menu {
    z-index: 1000;
    display: block;
    width: 230px;
    border: @form_border_width solid @form_border_color;
    border-radius: @form_border_radius;
    overflow: hidden;
    margin-top: 8px;

    .ui-menu-item {
        border-top: 1px solid #fff;
        z-index: 1000;
        display: none;

        &:first-child {
            border-top: none !important;
        }

        &:last-child {
            border-bottom: none !important;
        }


        &:nth-child(-n+10) {
            display: block;
            background: @light_color;
            padding: 8px 16px;
            color: @form_text_color;
            border-bottom: 1px solid @form_border_color;
            border-top: none;
            cursor: pointer;

            &:hover {
                background: #fff;
            }
        }
    }
}

.inputHelper {
    .rems(12);
    line-height: 16px;
    float: none;
    clear: both;
    display: block;
    width: 100%;
    opacity: 0.7;
    color: @text_color;
    padding-top: 8px;
}

.ui-helper-hidden-accessible {
    display: none !important;
}

.row .inputWrap.passwordToggle {

    position: relative;
    z-index: 1;

    input[type="password"], input[type="text"] {
        width: 100%;
        position: relative;
        z-index: 1;
        padding-right: @form_padding_icon_offset;
    }

    .toggleButtons {

        width: @form_icons_width;
        height: 100%;
        padding: 0;
        border: none;
        position: absolute;
        top: @form_icons_top;
        right: @form_icons_side;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        cursor: pointer;


        i {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            display: none;
            .rems(14);
            line-height: 16px;
            color: @form_icons_color;
            transition: color 200ms linear;

            &.enabled {
                display: none;
            }

            &.disabled {
                display: flex;
            }
        }

        &:hover {
            i {
                color: @secondary_color;
                transition: color 200ms linear;
            }
        }

        &:active {
            i {
                color: darken(@secondary_color, 10%);
                transition: color 200ms linear;
            }
        }

    }

    &.reveal {
        .toggleButtons {

            i {
                &.enabled {
                    display: flex;
                }

                &.disabled {
                    display: none;
                }
            }
        }
    }

}

#preloadFormElements {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}

.validationMessage {

    position: relative;

    & + .validationMessage {
        display: none !important;
    }

}

.formGrid {

    display: flex;
    flex-wrap: wrap;
    width: ~"calc(100% + 24px)";
    margin: 0 -12px;

    @media @mobile {
        width: 100%;
        margin: 0;
    }

    .fieldFull {
        padding: 0 12px;
        width: 100%;

        @media @mobile {
            padding: 0;
        }
    }

    .fieldLeft, .fieldRight {

        padding: 0 12px;
        width: 50%;

        @media @mobile {
            width: 100%;
            padding: 0;
        }

    }

}

.dobSelectRow {
	
	display: flex;
	gap: 15px;
	
	& > .inputWrap {
		float: none;
		clear: both;
		display: block;
		flex: 1 1 100%;
		margin: 0 0 16px;
	}
	
}

fieldset:disabled input {
	border: 0 !important;
	background: @light_color !important;
}

.grecaptcha-badge {
	visibility: hidden;
}

.tagInput {

	width: 100%;
	
	.vue-tags-input {
		
		width: 100%;
		max-width: 100% !important;
		
		.ti-input {
			border: @form_border_width solid @form_border_color;
			border-radius: @form_border_radius;
			padding: 4px 16px;
			
			.ti-new-tag-input-wrapper {
				margin: 0.5px;
				
				input {
					min-height: 21px !important;
				}
			}
			
			.ti-tags {
				.ti-tag {
					background: @dark_color;
					border-radius: 4px;
				}
				
				.ti-tag-center {
					padding: 4px;
					
					span {
						font-weight: 600;
					}
				}
			}
		}
		
		.ti-autocomplete {

			border: @form_border_width solid @form_border_color;
			border-top: none;
			border-radius: 0 0 @form_border_radius @form_border_radius;
			
			&:before {
				content: "";
				display: block;
				background: #fff;
				height: 5px;
				width: ~"calc(100% + 3px)";
				position: absolute;
				left: -1.5px;
				right: -1.5px;
				bottom: 100%;
				border-left: @form_border_width solid @primary_color;
				border-right: @form_border_width solid @primary_color;
			}
			
			& > ul {
		
				
				&:before, &:after {
					display: none;
				}
				
				& > li {
					 & > div {
						 padding: 12px @form_padding_sides;
						 .rems(12);
						 line-height: 1.2em;
						 font-weight: 600;
					 }
					
					&.ti-selected-item {
						background: @primary_color;
					}
				}
			}
			
		}
		
	}
	
	
}


.checkboxRow {
	label {
		display: block !important;
	}
}
