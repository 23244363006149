.infoBanner {

    background: @primary_color;
    padding: 21px 0 20px;
    overflow: hidden;
    position: relative;
    float: none;
    clear: both;
	color: #fff;
	
    .wrapper {
        position: relative;
        z-index: 2;

		@media all and (max-width: 699px) {
			padding: 0;
		}
    }
	
    .iconListWrap {
		
        margin: 0 auto;

        .iconList {
			
			padding: 0;

            &:before, &:after {
                display: none;
            }
			
			&:not(.slick-slider) {

				display: flex;
				justify-content: center;
				gap: 0 46.5px;
				
				
				li {
					
					&:nth-child(1n+4) {
						@media all and (max-width: 699px) {
							display: none;
						}
					}

					&:nth-child(1n+3) {
						@media all and (max-width: 559px) {
							display: none;
						}
					}

					&:nth-child(1n+2) {
						@media all and (max-width: 399px) {
							display: none;
						}
					}
				}
			}
			
			&.slick-slider {
				padding: 0;
				width: 100%;
				
				.slick-list {
					width: 100%;
				}
			}

			
			li {
				width: auto;
				
				@media all and (max-width: 699px) {
					padding: 0 24px;
					width: 33.333333%;
				}
			}

        }

        .iconTitleWrap {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 0;
            text-align: center;
			max-width: 226px;

			@media all and (max-width: 699px) {
				max-width: 100%;
			}

            .icon {
                width: 47px;
                height: 47px;
                display: flex;
                justify-content: center;
                align-items: center;
				position: relative;
				

                img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
            }

            .iconTitle {
                margin: 12px 0 0;
				.rems(17);
				font-style: normal;
				font-weight: 500;
				line-height: 150%; /* 25.5px */
                width: 100%;
                text-align: inherit;
				letter-spacing: normal;

				@media all and (max-width: 699px) {
					.rems(14);
				}
            }

        }

    }


}


.productPointsBanner {
	
	padding: 30px 0;
	background: @light_color;
	border-top: 1px solid #DADEE7;
	
	ul {
		
		margin: 0;
		padding: 0;
		display: flex;
		gap: 84px;
		justify-content: center;
		
		li {
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 11px;
			font-size: 17px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%; /* 25.5px */
			color: @dark_primary;
			
		}
	}
}
