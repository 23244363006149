/*
 * Notification Styles
 */
@alert_border_radius: 4px;

.success, .error, .alert, .alertBlue, .info {
    margin: 0 0 16px 0;
    padding: 36px 48px !important;
    clear: both;
    text-align: left;
    position: relative;
    border-radius: @alert_border_radius;
    border: 1px solid @border_color;
    box-shadow: none;
	background: transparent !important;
	.rems(17) !important;
	font-weight: 500 !important;
	line-height: 25.5px !important;
	color: @dark_primary;


    @media @mobile {
		padding: 25px 25px 25px 60px  !important;
    }

    .darkBg & {
        color: @text_color;
    }

	 

    &, p {
        
		font-size: inherit;
		line-height: inherit;
		color: inherit;
		font-weight: inherit;
		
		
		
        .darkBg & {
            color: @dark_primary;
        }
    }

    a {

		font-size: inherit;
		line-height: inherit;
		color: inherit;
		font-weight: inherit;
        text-decoration: underline;
        transition: color 200ms linear;

        &:hover, &:active, &:focus-visible {
            text-decoration: none;
        }

    }

    h4 {
        margin-bottom: 8px;
		margin-top: -4px;
        color: inherit;

    }

	
    &:after {
        display: none;
    }
}

.success {

    border-color: @success_color;

    .darkBg & {
		border-color: @success_color;
    }

    &, p {
        //color: #fff !important;
    }


    a {
        //color: #fff;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        //color: #fff;
    }
}

.error {

	border-color: @alert_color;

    .darkBg & {
		border-color: @alert_color;
    }

    &, p {
        //color: #fff  !important;
    }
	

    a {
        //color: #fff;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        color: #fff;
    }

}

.alert {

	border: none !important;
	background: @information_color !important;
	color: @dark_primary !important;
	

    &, p {
        //color: #fff !important;
    }


    a {
        //color: #fff;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        //color: #fff;
    }

}

.alertBlue, .info {

	border: none !important;
	background: @note_color !important;
	color: @dark_primary !important;

    .darkBg & {
		border-color: @note_color;
		background: @note_color !important;
    }

    &, p {
        color: @dark_primary !important;
    }
	
    a {
        //color: @primary_color;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        //color: @primary_color;
    }

}


/*
 * Site wide messages
 */

.siteWideMessageWrap {
	.clearfix();
	background: @highlight_color;
	border-top: 2px solid #fff;

	.siteWideMessage {

		.slick-track {
			perspective: none;
		}

		li {
			width: 100%;
			font-family: @primary_font;
			.rems(16);
			color: #fff;
			float: left;
			text-align: center;
			font-weight: 400;
			text-transform: uppercase;
			-webkit-font-smoothing: subpixel-antialiased;

			a {
				color: #fff;
				text-decoration: none;
				display: block;
				line-height: 20px;
				padding: 10px 15px;

				&:hover {
					text-decoration: underline;
				}
			}

			&:focus {
				-webkit-appearance: none;
				outline: none;
			}

			@media @desktop, @tablet {
				width: 33.33%;
				border-right: 2px solid #fff;

				&:nth-child(3n) {
					border-right: 0;
				}

				@media @medium {
					border: 0;
				}
			}
		}
	}
}
