/**
 * Home page slider
 */

.sliderContainer {
	float: left;
	width: 100%;
	overflow: hidden;
	margin: 0 0 20px;

	&.boxed {
		max-width: @wrapper_width;
		margin: 20px auto 20px;
		float: none;
	}
}

.sliderWrap {
	position: relative;
	max-width: 100%;
	.clearfix();

	@media @mobile {
		margin-bottom: 15px;
	}

	.slider {
		.clearfix();
		width: 100%;

		@media @mobile {
			width: auto;
			margin: 0;
		}

		.slick-slide, > li {
			position: relative;
			min-height: 600px;
			overflow: hidden;

			a {
				display: block;
			}

			@media @mobile {
				min-height: initial;
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 50%;
			width: 2000px;
			max-width: 2000px;
			transform: translate(-50%, 0);

			@media @mobile {
				position: relative;
				width: auto;
				top: auto;
				left: auto;
				min-height: initial;
				max-width: 100%;
				transform: translate(0, 0);
			}
		}
	}
	
	.innerText {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		background: rgba(0, 0, 0, 0.1);
		padding: 20px;

		.slideshowPosition {
			display: table;
			height: 100%;
			width: 100%;
		}

		.slideshowText {
			display: table-cell;
			vertical-align: middle;

			h2 {
				margin-bottom: 30px;
				.rems(50);
				color: #fff;
				font-family: @primary_font;
				font-weight: bold;

				@media @tablet {
					.rems(35);
				}

				@media @mobile {
					margin-bottom: 10px;
					.rems(25);
				}
			}

			h3 {
				margin-bottom: 40px;
				.rems(22);
				color: #fff;
				text-transform: none;
				letter-spacing: 0;

				@media @mobile {
					margin-bottom: 20px;
					.rems(22);
				}
			}

			.button {
				clear: both;
				margin: 0;
				.rems(17);

				@media @mobile {
					.rems(14);
				}
			}
		}
	}
}


/*
* Splide
*/
.splide__list {
	&:before, &:after {
		display: none;
	}
}
.splide__arrows {
	
	.splide__arrow {
		background: transparent !important;
		color: #fff;
		width: 48px;
		height: 48px;
		opacity: 1;
		
		&:after {
			content: "";
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background: @primary_color;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			transition: background-color 200ms linear;
		}
		
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 2;
			width: 10px;
			height: 10px;
			
			path {
				fill: #fff;
			}
		}
		
		&:hover, &:active, &:focus {
			&:after {
				background: darken(@primary_color, 8%);
				transition: background-color 200ms linear;
			}
		}
		
		&.splide__arrow--prev {
			
			svg {
				transform: translate(-50%,-50%) rotate(-180deg);
			}
			
		}
	}
}


