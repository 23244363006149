.titleBanner {

    position: relative;
    background: transparent;
	color: @dark_primary;
    padding: 41px 0;
	min-height: 367px;
	display: flex;
	align-items: center;
	
	@media @mobile {
		padding: 24px 0;
		min-height: 140px;
	}
	

    &.withImage {

		min-height: 368px;

		&:after {
			content: "";
			display: block;
			width: 66%;
			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			opacity: 0.7;
			background: linear-gradient(90deg, #FFF 43.68%, rgba(255, 255, 255, 0.00) 98.3%);
			z-index: 2;
		}
	
    }
	



    & > .wrapper {

        position: relative;
        display: flex;
        align-items: center;
		width: 100%;
		z-index: 3;

        &:before, &:after {
            display: none;
        }

    }

    .imageBackground {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;


        .imageWrap {
            width: 100%;
			height: 100%;
            position: absolute;
            top: 0;
			right: 0;
			bottom: 0;
			left: 0;
            overflow: hidden;
            z-index: 2;

            img {
                position: absolute;
				object-fit: cover;
				width: 100%;
				height: 100%;
				max-width: none;
				max-height: none;
				z-index: 1;
				opacity: 0.8;
				
            }
			
        }
		
    }


	&.categoryHero {

		padding: 48px 0;
		min-height: 368px;
		background: #fff;
		color: @text_color;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@media @mobile {
			min-height: 250px;
		}

		.imageBackground {

			.imageWrap {
				&:before {
					display: none;
				}
			}
			
		}

	}
}

.innerTitleBanner {

    display: flex;
    width: 100%;
    position: relative;
    z-index: 6;
	color: @dark_primary;

    @media all and (max-width: 730px) {
        flex-wrap: wrap;
    }

    .bannerContentWrap {
        width: 100%;
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .leftAlign & {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }


    .innerContentWrapper {

        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        position: relative;
        z-index: 2;



    }

    .titleWrap {

        padding: 0;
        width: 100%;
        max-width: 965px;
        margin: 0 auto;
        text-align: center;
		color: inherit;
		display: flex;
		align-items: center;
		

        .innerText {

            padding: 0 !important;
            display: flex;
            flex-direction: column;
			color: inherit;
			margin-top: -8px;

            &:before, &:after {
                display: none;
            }

            h1 {


				.rems(49);
				font-weight: 500;
				line-height: 52.91px; /* 108.555% */
				letter-spacing: -1.95px;
				margin: 0;
				color: inherit;
				
                strong {
                    font-weight: bold;
                }
				
				@media @mobile {
					.rems(28);
					line-height: 1.2em;
				}


            }

            h4 {

                margin: 8px 0 0;
                .rems(14);
                line-height: 20px;
                position: relative;
                padding: 0;
                font-weight: 600;
				color: inherit;
				
            }

            .titleContent {

                margin: 14px 0 0;
                max-width: 100%;
                .rems(15);
                line-height: 1.5em;
				font-weight: 400;
                color: inherit;
				

                ul, ol, p {
                    font-size: inherit;
                    line-height: inherit;
					color: inherit;
					margin-bottom: 24px;
                }
				
				p {
					&:last-child {
						margin: 0;
					}
				}

            }
        }

        .withImage & {
            max-width: 512px;
        }

		.categoryHero & {
			max-width: 500px;
			
			@media all and (max-width: 1440px) {
				max-width: 400px;
			}
			
			@media @mobile {
				max-width: 100%;
			}
		}
		
		.leftAlign & {
			text-align: left;
			margin: 0;
		}
		
		
    }

    .iconPoints {

        padding: 24px 0 0;

        ul {

            margin: 0;
            float: none;
            clear: both;

            &:before, &:after {
                display: none;
            }

            li {

                position: relative;
                .rems(14);
                line-height: 22px;
				font-weight: 500;
                padding-left: 22px;
                margin-bottom: 10px;

				@svg_color: escape(@primary_color);
				
				&:before {
					content: url("data:image/svg+xml; utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' %3E%3Cpath d='M11.3671 3.75949H8.24051V0.632911C8.24051 0.278481 7.94939 0 7.60759 0H4.39241C4.03797 0 3.75949 0.291117 3.75949 0.632911V3.75949H0.632911C0.278481 3.75949 0 4.05061 0 4.39241V7.60759C0 7.96203 0.291117 8.24051 0.632911 8.24051H3.75949V11.3671C3.75949 11.7215 4.05061 12 4.39241 12H7.60759C7.96203 12 8.24051 11.7089 8.24051 11.3671V8.26582H11.3671C11.7215 8.26582 12 7.97471 12 7.63291V4.41772C12 4.05059 11.7089 3.75947 11.3671 3.75947V3.75949Z' fill='@{svg_color}'/%3E%3C/svg%3E");
					color: @primary_color;
					position: absolute;
					width: 12px;
					height: 12px;
					left: 0;
					top: 0;
					margin-top: 1.5px;
					display: inline-block;
				}
			


            }
        }
    }

    .buttonWrap {

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 25px 0 0 0;
        margin-bottom: 0;

        @media all and (max-width: 1380px) {
            padding: 25px 0 0;
        }

        .leftAlign & {
            justify-content: flex-start;
        }

        & > a {
            margin: 0 24px 24px 0;
            width: auto;
            flex: 0 0 auto;
			
            @media @mobile {
                width: 100%;
            }
			
			.svgIcon {
				display: none;
			}
			
			&.withIcon {
				padding-right: 63px;
				position: relative;
				
				.svgIcon {
					display: flex;
					width: 19px;
					height: 17px;
					position: absolute;
					right: 16px;
					top: 50%;
					transform: translateY(-50%);
					margin: 0;
				}
			}

			&:first-of-type {
				margin-top: 0;

				@media @mobile {
					margin-top: 0;
				}
			}

            &:last-of-type {
                margin-right: 0;
				margin-bottom: 0;
            }

            & + .loginLink {

                margin: 0 0 0 24px;

                @media @mobile {
                    width: 100%;
                    margin: 16px 0 0 0;
                }
            }


        }
		
		.buttonOutlined {
			color: #fff;
			
			&:hover, &:active {
				color: #fff;
			}
			
			.withImage {
				color: @text_color;

				&:hover, &:active {
					color: @text_color;
				}
			}
		}
		
		.categoryHero & {
			padding: 24px 0 0;

			@media all and (max-width: 1380px) {
				padding: 24px 0 0;
			}
		}

    }


}
