/* -- GENERIC FAQ -- */

//Icon
@faq_fa_icon: f107;
@faq_fa_icon_close: f107;
@faqs_icon_right: 0;
@faqs_icon_right_flyout: 0;
@faqs_icon_right_mobile: 0;

//Border and shadow
@faqs_border_color: @border_color_alt;
@faqs_border_width: 1px;
@faqs_box_shadow: none;
@faq_border_radius: 0;

//Spacing
@faqs_padding: 15px 40px 16px 0;
@faqs_answer_padding: 8px 0 16px 0;

@faqs_padding_flyout: 15px 40px 16px 0;
@faqs_answer_padding_flyout: 8px 0 16px 0;

@faqs_padding_mobile: 15px 40px 16px 0;
@faqs_answer_padding_mobile: px 0 16px 0;

//Colours
@faqs_question_bg_colour: transparent;
@faqs_question_text_colour: @text_color;

@faqs_answer_bg_colour: transparent;
@faqs_answer_text_colour: @text_color;

//Font sizes
@faqs_question_font_size: 17;
@faqs_question_line_height: 32px;
@faqs_question_font_size_flyout: 16;
@faqs_question_line_height_flyout: 32px;
@faqs_question_font_size_mobile: 16;
@faqs_question_line_height_mobile: 32px;

@faqs_answer_font_size: 16;
@faqs_answer_line_height: 32px;
@faqs_answer_font_size_flyout: 14;
@faqs_answer_line_height_flyout: 28px;
@faqs_answer_font_size_mobile: 14;
@faqs_answer_line_height_mobile: 28px;


/*
* Faq options
 */
@faq_option_padding: 16px 24px;
@faq_option_padding_mobile: 12px 16px;


ul.faqs, ul.accordion {
    float: none;
    clear: both;
    width: 100%;
    margin-bottom: 0;
    border-radius: @faq_border_radius;
    overflow: hidden;
    border: none;
    box-shadow: @faqs_box_shadow;
    //border-bottom: @faqs_border_width solid @faqs_border_color;

    @media @flyout {
        margin-bottom: 0;
    }

    @media @mobile {
        margin-bottom: 0;
    }


    .innerContentWrap {
        display: none;
        padding: @faqs_answer_padding;
		
		.darkBg & {
			color: @dark_text_color;
		}
    }

    &.accordionReady {
        .innerContentWrap {
            display: block;
        }

    }

    li {
        float: none;
        clear: both;
        width: 100%;



        & > h4 {
            position: relative;
            float: none;
            clear: both;
            width: 100%;
            background: @faqs_question_bg_colour;
            border-bottom: none;
            border-bottom: @faqs_border_width solid @faqs_border_color;
            margin: 0;
            color: @faqs_question_text_colour;
            cursor: pointer;
            .rems(@faqs_question_font_size);
            line-height: @faqs_question_line_height;
            padding: @faqs_padding;
            transition: all 200ms linear;
            font-weight: bold;
            letter-spacing: 0;

            @media @flyout {
                .rems(@faqs_question_font_size_flyout);
                line-height: @faqs_question_line_height_flyout;
                padding: @faqs_padding_flyout;
            }

            @media @mobile {
                .rems(@faqs_question_font_size_mobile);
                line-height: @faqs_question_line_height_mobile;
                padding: @faqs_padding_mobile;
            }

            .darkBg & {
                color: #fff;
                border-color: fade(#fff, 40%);
            }

            .faqsGrid & {
                .rems(16);
                line-height: 28px;
                font-weight: normal;
                padding: 17px 40px 18px 0;
                border-color: @border_color;

                @media @flyout {
                    .rems(16);
                    line-height: 28px;
                    font-weight: normal;
                    padding: 17px 40px 18px 0;
                }

                @media @mobile {
                    .rems(16);
                    line-height: 28px;
                    font-weight: normal;
                    padding: 17px 40px 18px 0;
                }
            }


            .faqIcon {

                width: 15px;
                height: 15px;
                border-radius: 50%;
                padding: 0;
                text-indent: 0;
                border: none;
                background: transparent;
                color: #35A3BA;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 200ms linear;
                box-sizing: border-box;
                z-index: 2;

                .faqsGrid & {
                    width: 9px;
                    height: 9px;
                }

                svg {
                    width: 100%;
                    height: auto;

                    path {
                        fill: #35A3BA;
                        transition: color 200ms linear;
                    }

                }

                .darkBg & {

                    color: #fff;
                    border-color: @border_color;

                    svg {

                        path {
                            fill: #fff;
                            transition: color 200ms linear;
                        }

                    }
                }
            }



            &.open {


                .faqIcon {
                    transform: translateY(-50%) rotate(180deg);
                    transition: all 200ms linear;

                    .darkBg & {
                        color: #35A3BA;

                        svg {
                            path {
                                fill: #35A3BA;
                            }
                        }
                    }
                }

                & + .answer, & + .content {
                    .innerContentWrap {
                        display: block;
                    }
                }

            }

            &:hover, &:active, &:focus {

                color: @highlight_color;
                transition: all 200ms linear;

                &:after {
                    color: @highlight_color;
                    transition: all 200ms linear;
                }

                .faqIcon {
                    svg {

                        path {
                            fill: #35A3BA;
                            transition: color 200ms linear;
                        }

                    }
                }

            }

        }


        &:first-child {
            & > h4 {
                border-top: none;
            }
        }


        .answer, .content {
            float: none;
            clear: both;
            width: 100%;
            border: none;
            padding: 0;
            background: @faqs_answer_bg_colour;
            color: @faqs_answer_text_colour !important;
            text-align: left;
            .rems(@faqs_answer_font_size);
            line-height: @faqs_answer_line_height;



            .darkBg & {
                color: #fff;
            }

            @media @flyout {
                .rems(@faqs_answer_font_size_flyout);
                line-height: @faqs_answer_line_height_flyout;
            }

            @media @mobile {
                .rems(@faqs_answer_font_size_mobile);
                line-height: @faqs_answer_line_height_mobile;
            }


           p, ul, ol {
                color: inherit;
                font-size: inherit;
                line-height: inherit;
                text-align: inherit;

            }

            h1,h2,h3,h4,h5,h6 {
                color: inherit;
                line-height: 24px;
                font-weight: 400;
            }

            h1 {
                .rems(24);
            }

            h2 {
                .rems(20);
            }

            h3 {
                .rems(18);
            }

            h4 {
                .rems(16);
            }

            h5 {
                .rems(15);
            }

            h6 {
                .rems(14);
            }
        }


        .answer, .content {
            padding: 24px 0;
        }

    }

    &.faqClosed {

        li {
            .answer, .content {
                display: none;
            }
        }

    }

    /* Faqs with reduces spacing */
    &.faqsMini {

        margin-bottom: 32px;

        @media all and (max-width: @wrapper_width) {
            margin-bottom: 32px;
        }

        @media @flyout {
            margin-bottom: 24px;
        }

        li {

            h4 {

                .rems(14);
                line-height: 24px;
                font-weight: normal;
                padding: 12px 64px 12px 16px;

                @media @flyout {
                    padding: 12px 64px 12px 16px;
                }

                @media @mobile {
                    padding: 12px 64px 12px 16px;
                    .rems(14);
                }

            }

            .answer {
                padding: 16px;
            }

        }

    }
}




.faqContainer.faqsMulti {
    float: none;
    clear: both;
    width: 100%;
    padding: 32px 0 40px;

    &:last-of-type {
        padding-bottom: 0;
    }


    ul.faqs {
        margin-bottom: 0 !important;

    }

    .faqSelector {
        float: none;
        clear: both;
        width: 100%;
        display: block;
        margin-bottom: 32px;

        @media @mobile {
            margin-bottom: @top_bottom_gutter_mobile;
        }

        .faqCategoryTitle {
            display: none;
            .rems(18);
            line-height: 32px;
            margin: 0;
            position: relative;
            border-radius: @border_radius;

            @media @mobile {
                display: block;
            }

            &.isClickable {

                width: 100%;
                padding: 12px 16px;
                cursor: pointer;
                background: @secondary_color;
                color: #fff;
                border: 1px solid @secondary_color;
                transition: all 200ms linear;
                font-weight: 600;

                &:after {
                    position: absolute;
                    .fa(f107);
                    top: 50%;
                    right: 8px;
                    width: 32px;
                    height: 32px;
                    .rems(16);
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: translateY(-50%) rotate(0deg);
                    transform-origin: center;
                    transition: transform 200ms linear;
                    color: inherit;
                }

                &.open {

                    border-radius: @border_radius @border_radius 0 0;
                    border-bottom: 1px solid rgba(0,0,0,0.04);
                    transition: all 200ms linear;

                    &:after {
                        transform: translateY(-50%) rotate(-180deg);
                        transition: transform 200ms linear;
                    }
                }


            }
        }

        .innerFaqsSelector {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 48px;
        }


        .option1, .option2, .option3, .option4 {
            position: relative;
            //float: left;
            width: auto;
            flex: 1 1 auto;
            background: @faqs_question_bg_colour;
            border: 1px solid @faqs_border_color;
            border-right: none;
            margin: 0;
            padding: @faq_option_padding;
            .rems(@faqs_question_font_size);
            line-height: @faqs_question_line_height;
            color: @faqs_question_text_colour;
            cursor: pointer;
            display: inline-block;
            float: none;
            clear: both;
            height: auto;
            text-align: center;
            font-weight: 600;


            &:first-of-type {

                border-radius: 32px 0 0 32px;

                @media @mobile {
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    border-radius: 0;
                }
            }

            &:last-of-type {
                border-radius: 0 32px 32px 0;
                border-right: 1px solid @faqs_border_color;


                @media @mobile {
                    border-bottom: 1px solid @faqs_border_color;
                    border-radius: 0 0 @border_radius @border_radius;
                }
            }

            &.open {

                color: @secondary_color;

            }

            @media all and (max-width: 1050px) {
                .rems(14);
                line-height: 24px;
            }

            @media @flyout {
                .rems(@faqs_question_font_size_flyout);
                line-height: @faqs_question_line_height_flyout;
            }

            @media @mobile {
                width: 100%;
                border-bottom: 1px solid transparent;
                border-left: 1px solid @faqs_border_color;
                border-right: 1px solid @faqs_border_color;
                text-align: left;
                padding: @faq_option_padding_mobile;
                .rems(14);
                line-height: 24px;
            }
        }



    }


    .faqOption1 {
        display: block;


    }

    .faqOption2 {
        display: none;
    }

    .faqOption3 {
        display: none;
    }

    .faqOption4 {
        display: none;
    }
}

.faqTitles {

    display: none;
    float: none;
    clear: both;
    color: @text_color;
    .rems(18);
    line-height: 24px;

    &.optionTitle1 {
        display: block;
    }


}


.faqsColumns {

    padding: 131px 0 10px;
    background: #fff;

    @media @flyout {
        padding: 80px 0 40px;
    }

    @media all and (max-width: 700px) {
        padding: 56px 0;
    }

}

.faqsGrid {
    width: ~"calc(100% + 40px)";
    margin: 0 -20px;
    display: flex;
    justify-content: center;

    @media @flyout {
        display: block;
    }

    .faqsLeft {
        width: 50%;
        flex: 1 1 50%;
        padding: 0 20px;
        max-width: 50%;

        @media @flyout {
            width: 100%;
            max-width: 70%;
            margin: 0 auto;
        }

        @media all and (max-width: 700px) {
            max-width: 100%;
        }
    }

    .faqsRight {
        width: 50%;
        flex: 1 1 50%;
        padding: 0 20px;
        max-width: 50%;

        @media @flyout {
            width: 100%;
            max-width: 70%;
            margin: 0 auto;
        }

        @media all and (max-width: 700px) {
            max-width: 100%;
        }
    }

}

