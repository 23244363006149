//Search form input
@searchform_padding: 14.64px 25.83px;
@searchform_font_size: 18;
@searchform_line_height: 25.278px;
@searchform_height: 49.9px;


@searchform_flyout_padding: 14.64px 25.83px;
@searchform_flyout_font_size: 12;
@searchform_flyout_line_height: 12px;
@searchform_flyout_height: 41px;

@searchform_mobile_height: 41px;

//Search form button
@searchform_button_width: @searchform_height;
@searchform_button_height: @searchform_height;
@searchform_button_font_size: 12;


@searchform_button_mobile_width: @searchform_flyout_height + 8px;
@searchform_button_mobile_height: @searchform_flyout_height;
@searchform_button_mobile_font_size: 12;
@searchform_button_mobile_font_weight: 400;



//Colours
@search_color: @primary_color;
@search_mobile_bg_color: #FFFFFF;

//Border
@searchform_border_radius: 20.5px;


.searchForm {
    position: relative;
    margin: 0;
    float: none;
    clear: both;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 500;

    &:before, &after {
        display: none;
    }

    .searchWrap {
        width: 100%;
        position: relative;

    }

    .row {
        float: none;
        clear: both;
        margin: 0;
        width: 100%;
        position: relative;

        button {
            width: 69.9px !important;
            height: 49.9px !important;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 5;
            outline: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            color: @text_color;
            transition: all 200ms linear;
            border-radius:  0 @searchform_border_radius @searchform_border_radius 0;
			opacity: 0.5;

            @media @mobile {
                height: @searchform_mobile_height;
            }

            &:hover {
                color: @search_color;
                transition: all 200ms linear;
            }
			
            i {
                .rems(20);
                line-height: 24px;
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
				color: #3F3F3F;

                &.fa-times {
                    position: relative;
                    top: 2px;
                }

            }

            &.buttonDisabled {
                pointer-events: none;
            }

        }

        input:not([type='checkbox']), input:not([type='radio']) {

            padding: @searchform_padding;
            .rems(@searchform_font_size);
            line-height: @searchform_line_height;
            font-weight: 500;
            float: none;
            clear: both;
            width: 100%;
            min-width: 120px;
            position: relative;
            z-index: 2;
            height: @searchform_height;
            transition: all 200ms linear;
            border: 1.5px solid #F2F2F2;
            border-radius: 50px;
            background: #F2F2F2;
            color: @text_color;
			letter-spacing: normal;


            .searchBanner & {
                padding: 20px 56px 20px 64px;
                .rems(16);
                line-height: 32px;
                height: 72px;
                border: 1.5px solid @border_color;
                border-radius: 36px;
            }
			
            &:focus, &:focus-visible {

				border-color: darken(@search_color, 8%);

                & + .searchIcon {

                    i {

                        &.magnifySearchIcon {

                            &:before {
                                display: none !important;
                            }

                            svg {

                                &, * {
                                    fill: @text_color;
                                    transition: fill 200ms linear;
                                }

                            }

                        }
                    }

                }
            }

        }

        .searchIcon {
			

            i {
                &.magnifySearchIcon {

                    &:before {
                        display: none !important;
                    }

                    svg {
                        position: relative;
                        width: 20px;
                        height: 25px;

                        &, * {
                            fill: @text_color;
                            transition: fill 200ms linear;
                        }

                    }

                }
            }

            .transparentHeader & {


                @media @above_flyout {

                    i {
                        color: fade(@text_color, 40%);

                        svg {
                            position: relative;

                            .fa-primary {
                                color: fade(#fff, 16%);
                            }

                            .fa-secondary {
                                color: fade(#fff, 40%);
                            }

                        }
                    }
                }

            }
        }

        input:not([type='checkbox']):focus, input:not([type='radio']):focus, &.inputFocus input:not([type='checkbox']), &.inputFocus input:not([type='radio']) {
            border-color: @search_color !important;
            transition: all 200ms linear !important;

        }

        &.inputFocus {

            input:not([type='checkbox']), input:not([type='radio']) {
                border-radius: @searchform_border_radius @searchform_border_radius 0 0;

                @media @above_flyout {
                    background-color: #fff !important;
                }
            }

            button {
                border-radius:  0 @searchform_border_radius 0 0;

            }

        }

    }

    .searchResults {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #fff;
        border: 1.5px solid @search_color;
        border-top: none;
        padding: 0;
        z-index: 9999;
        height: 340px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 200ms linear;
        border-radius: 0 0 @searchform_border_radius @searchform_border_radius;
        box-shadow: 0 5px 8px -4px rgba(0,0,0,0.3);
		max-height: 200px;

        &.searchResultsVisible {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: all 200ms linear;
        }


        .searchingText {
            position: relative;

            i {
                position: absolute;
                right: 0;
                top: 4px;
            }
        }

        .innerScroller {
            height: 100%;
            overflow-y: scroll;
            padding: 16px;

            &>div {
                margin-bottom: 16px;
                padding-bottom: 12px;
                border-bottom: 1.5px solid @border_color;

                &.productsResults {
                    padding-bottom: 12px;
                }

                &:last-of-type {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }
            }
        }

        .categoryResults {
            float: none;
            clear: both;
            width: 100%;

            li a {
                text-align: left;
            }
        }

        .productsResults {
            float: none;
            clear: both;
            width: 100%;

            .button {
                margin: 0;
                padding: 12px 16px;
                .rems(12);
                line-height: 12px;
                max-width: 220px;

                @media @flyout {
                    max-width: 100%;
                }

            }

            ul {

                margin-bottom: 16px;

                li {

                    margin: 16px 0;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    a {
                        text-align: left;
                        transition: color 200ms linear;

                        &:hover {
                            color: @search_color;
                            transition: color 200ms linear;
                        }
                    }
                }
            }
        }

        h5 {

            .rems(14);
            line-height: 20px;
            color: @search_color;
            margin-bottom: 4px;
        }

        ul {
            float: none;
            clear: both;
            width: 100%;
            margin-bottom: 0;

            li {
                float: none;
                clear: both;
                width: 100%;
                margin: 6px 0;
                height: auto;
                background: none;

                a {
                    .rems(14);
                    line-height: 20px;
                    font-weight: normal;
                    color: @text_color;
                    text-decoration: none;
                    transition: color 200ms linear;
                    display: flex;

                    .imageWrap {
                        width: 56px;
                        height: 56px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .productInfo {
                        flex: 1 1 100%;
                    }


                    &:hover {
                        color: @secondary_color;
                        transition: color 200ms linear;
                    }
                }

                .imageWrap {
                    float: left;
                    width: 64px;
                    margin-right: 18px;
                }

                .productInfo {
                    float: left;
                    width: ~"calc(100% - 82px)";

                    h3 {
                        margin: 3px 0 5px;
                        font-weight: normal;
                        .rems(14);
                        line-height: 1.2em;
                        color: #5F5F5F;
                    }

                    .productPrice {
                        font-weight: bold;
                        color: @secondary_color;
                    }
                }
            }
        }

        .button {
            width: 100%;
            .rems(12);
            line-height: 12px;
            margin-bottom: 0;
        }

        .noResults {

            h5 {
                margin-bottom: 0;
            }
        }
    }

}


.mobileSearchWrap {
    display: none;
}

@media @flyout {
    .mobileSearchWrap {

        float: none;
        clear: both;
        width: 100%;
        background: #fff;
        padding: 0;
        border-bottom: 1px solid @search_color;
        display: none;


        &.open {
            overflow: visible !important;
        }


        .wrapper {
            padding: 0;
        }

        .searchForm {

            width: 100%;
            max-width: none !important;

            .row {
                input:not([type='checkbox']), input:not([type='radio']) {
                    .rems(@searchform_flyout_font_size);
                    line-height: @searchform_flyout_line_height;
                    height: @searchform_flyout_height;
                    padding: @searchform_flyout_padding;
                    border: none !important;
                    transition: all 200ms linear;
                    background: @search_mobile_bg_color;
					letter-spacing: normal;

                    &:focus {
                        background-color: darken(@search_mobile_bg_color,1%);
                        transition: all 200ms linear;
                    }

                }

                button {

                    width: @searchform_button_mobile_width;
                    height: @searchform_button_mobile_height;
                    .rems(@searchform_button_mobile_font_size);
                    line-height: @searchform_button_mobile_font_size;
                    font-weight: @searchform_button_mobile_font_weight;

                }
            }

        }
    }
}





.searchBanner {
    margin-top: 0;
    float: none;
    clear: both;
    position: relative;
    z-index: 4;

    .wrapper {
        max-width: 919px;
    }

    @media @mobile {
        display: none;
    }
}
