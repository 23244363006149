.heroSlider {
	
	display: block;
	width: 100%;
	position: relative;
	float: none;
	clear: both;
	color: @text_color_alt;

	mtc-slider {
		display: flex;
		width: 100%;
		position: relative;
		overflow: hidden;
	}

	mtc-slide {
		
		position: relative;
		width: 100%;
		
		&:nth-child(n+2) {
			display: none;
		}
	}
	
	.splide__pagination {
		bottom: 32px;
	}
	
	&__slides {
		
	}
	
	&__slide {
		
		
	}
	
	&__contentWrap {
		padding: 40px 0;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-height: 557px;
		position: relative;
		z-index: 2;

		@media @mobile {
			min-height: 420px;
		}
		
		&:before {
			content: "";
			display: block;
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0.60) 73.76%, rgba(255, 255, 255, 0.00) 116%);
			backdrop-filter: blur(4.3px);
			
			@media @mobile {
				width: 100%;
				backdrop-filter: blur(4.3px);
			}
		}
		
		.wrapper {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			position: relative;
			z-index: 2;
		}
	}
	
	&__innerContent {
		width: 100%;
		max-width: 435px;
	}
	
	&__imageWrap {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	&__title {
		.rems(54);
		font-weight: 400;
		line-height: 52.91px;
		letter-spacing: -2.16px;
		margin: 0;
		
		span {
			font-style: italic;
			font-weight: 500;
		}
	}
	
	&__content {
		color: inherit;
		padding: 25px 0 0;
		
		p, ul, ol, h1,h2,h3,h4,h5,h6 {
			color: inherit;
		}
	}
	
	&__buttonWrap {
		padding: 21px 0 0;
		
		.button {
			margin: 0 16px 16px 0;
			
			&:last-of-type {
				margin: 0 0 -6px 0;
			}
		}
	}
	
}
