.serviceListOuterWrap {
    float: none;
    clear: both;
    width: 100%;
    position: relative;

}

.serviceListScroller {
    float: none;
    clear: both;
    width: 100%;
    position: relative;

}

ul.serviceList {
    position: initial;
    float: none;
    clear: both;
    z-index: 5;

    @media all and (min-width: 801px) {
        .colGroup(16px);
    }

    @media all and (max-width: 800px) {
        width: 100%;
        max-width: none;
        margin: 0;
        .colGroup(16px);
        flex-wrap: nowrap;

        &:not(.slick-initialized) {
            padding-bottom: 72px;
        }

    }

    &.slick-slider {
        flex-wrap: wrap;

        .slick-track {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    &:before, &:after {
        display: none;
    }

    .slick-list {
        width: 100%;
        //overflow: visible !important;
    }

    .slick-track {
        display: flex;

        &:before, &:after {
            display: none !important;
        }

        & > li {
            margin: 0 !important;
            height: initial;
        }

    }

    & > li {


        @media all and (max-width: 800px) {

            &:not(.slick-slide) {
                display: none;

                &:first-child {
                    display: flex;
                }
            }

        }

    }

    & > li, .slick-track > li {
        float: none;
        clear: both;
        .col(3, 16px);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        outline: 0 !important;
        margin-bottom: 0;

        @media all and (max-width: 800px) {
            .col(1, 16px);
        }


        &:first-of-type, &.slick-slide {
            display: flex;
        }

        .innerService {
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: transparent;
            border-radius: 0;
            padding: 0;

            @media all and (max-width: 800px) {
                max-width: 480px;
                margin: 0 auto;
            }

        }


        .infoWrap {

            max-width: 100%;
            //flex: 0 0 100%;
            display: flex;
            flex-direction: column;
        }

        .imageWrap {

            position: relative;
            line-height: 16px;
            padding: 0;
            width: 100px;
			height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: transparent;
            overflow: hidden;


            .iconWrapper {

                width: 100%;
                height: 100%;

                i {
                    color: @primary_color;
                    .rems(32);
                    line-height: 32px;
                }

                svg {
                    width: 100%;
                    height: auto;
                }
            }

            img {
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
				
            }

        }

        h4 {
            float: none;
            clear: both;
            width: 100%;
            margin: 38px 0 0;
            .rems(18);
            line-height: 20px;
            letter-spacing: 0.1em;
            color: @text_color;
            font-weight: 700;
            position: relative;
			
            .serviceListPrefix {

                font-weight: 700;

                @media @mobile {
                    display: none;
                }
            }

            .serviceTitleText {

                font-weight: 700;

                @media @mobile {
                    position: relative;
                    z-index: 2;
                }
            }

        }

        p {
            padding: 0;
            margin: 6px 0 0;
            .rems(14);
            line-height: 22px;
            color: @text_color;

        }

    }

    .slick-dots {
        width: 100%;
    }

    .slickNavigation {
        bottom: 12px;
    }
}




.serviceBanner {
    position: relative;
    float: none;
    clear: both;
    width: 100%;
    background: @light_color;
    padding: 109px 0;
    text-align: center;
    z-index: 2;
    max-width: 100%;
    margin: 0 auto;

    @media @mobile {
        padding: 71px 0 65px;
    }


    &.darkBg {
        background: @dark_color;
    }

    .wrapper {
        position: relative;
        z-index: 1;
    }

    a {
        text-decoration: none;
    }

    .buttonWrap {
        padding: 49px 0 0;
        margin-bottom: 0;

        @media all and (max-width: 800px) {
			padding: 36px 0 0;
        }
		
		@media @mobile {
			padding: 16px 0 0;
		}

        .button {
            margin: 0;
            min-width: 311px;

            @media @mobile {
                min-width: 0;
				width: 100%;
            }
			
        }
    }
	

}



