.assessmentPageWrap {
	min-height: 80vh;
	background: #fff;
}

.gpSearchWrap {

    padding: 0;

    .searchWrap {
        width: 100%;
        position: relative;

        .row {
            float: none;
            clear: both;
            margin: 0;
            padding: 0;
            position: relative;

            .searchIcon {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-50%, -50%);
                z-index: 3;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                color: @primary_color;
                background: transparent;
                transition: color 200ms linear;
                border: none;
                cursor: pointer;
                outline: 0 !important;

                &:hover {
                    color: @primary_color;
                    background: transparent;
                    border: none;
                    transition: color 200ms linear;
                }
            }

            .searchinput {
                &.showResults {
                    border-color: @primary_color !important;
                    border-radius: @border_radius @border_radius 0 0 !important;
                    transition: all 200ms linear;
                }
            }
        }

        h5 {
            margin-bottom: 16px;
            .rems(18);
            line-height: 24px;

            &.searchingText {

                i {
                    margin-left: 8px;
                    color: @primary_color;
                    .rems(16);
                    line-height: 24px;
                }
            }
        }

        .searchResults {
            position: absolute;
            top: 100%;
            width: 100%;
            background: #fff;
            padding: 16px;
            z-index: 9999;
            overflow-y: scroll;
            height: 250px;
            border-radius: 0 0 @border_radius @border_radius;
            border: 1.5px solid @primary_color;
            border-top: none;
            box-shadow: 0 8px 8px rgba(0,0,0,0.1);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all 200ms linear;

            &.resultsVisible {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: all 200ms linear;
            }



            .productsResults {

                & > ul {
                    & > li {
                        margin-bottom: 8px;

                        @media @mobile {
                            margin-bottom: 0;
                        }

                        a {
                            color: @primary_color;
                            transition: color 200ms linear;
                            .rems(14);
                            line-height: 24px;
                            text-decoration: none;
                            font-weight: bold;


                            &:hover {
                                color: darken(@primary_color, 10%);
                                transition: color 200ms linear;
                            }
                        }
                    }
                }
            }

        }
    }


    .searchInputFields {

        margin-top: 16px;

        .row {
            margin-bottom: 16px;
            float: none;
            clear: both;

            .inputWrap {
                float: none;
                clear: both;

                input, label {
                    float: none;
                    clear: both;
                }
            }

            &:before, &:after {
                display: none;
            }
        }

        .doctorSurgeryFields {

            label {
                color: fade(@text_color, 100%);
                transition: color 200ms linear;
            }

            .inputWrap {
                &:after {
                    color: fade(@text_color, 100%);
                    transition: color 200ms linear;
                }
            }

            &.fieldsDisabled {

                label {
                    color: fade(@text_color, 40%);
                    transition: color 200ms linear;
                }

                .inputWrap {
                    &:after {
                        color: fade(@text_color, 40%) !important;
                        transition: color 200ms linear;
                    }
                }

            }
        }
    }

}

