.headerTopBar {

    background: @secondary_color;
    border-bottom: none;
    padding: 12px 0;
    float: none;
    clear: both;
    position: relative;




    .innerTopBar {

        ul {

            display: flex;
            width: 100%;
            margin: 0;
            justify-content: center;


            &:before, &:after {
                display: none;
            }

            li {

                flex: 1 0 100%;
                width: 100%;
                color: #fff;
                .rems(14);
                line-height: 16px;
                font-weight: normal;
                position: relative;
                padding: 0 16px;
                text-align: center;

                a {
                    color: inherit;
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: inherit;
                    text-decoration: none;

                    &:hover, &:active {
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                        font-weight: inherit;
                    }
                }



                .innerItem {
                    opacity: 1;
                    //white-space: nowrap;

                    .deliveryTimer {
                        font-size: inherit;
                        line-height: inherit;
                        font-weight: inherit;
                        color: inherit;
                        padding: 0;
                        display: inline-block;
                        text-align: left;
                    }
                }

                &:after {
                    content: "";
                    width: 1px;
                    height: 16px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    background: @border_color;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                a > span {
                    display: inline-block;
                    margin-right: 8px;

                    @media all and (max-width: 1230px) {
                        display: none;
                    }
                }


                i {
                    display: inline-block;
                    margin-right: 8px;
                }

            }

        }

    }


}
