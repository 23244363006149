.productCategoriesBanner {

    background: @light_color;
    float: none;
    clear: both;
    position: relative;
    z-index: 1;
    padding: 80px 0 40px;

    @media @flyout {
        padding: 64px 0 40px;
    }


    @media @mobile {
        padding: @top_bottom_gutter_mobile 0;
    }



    .categoryGrid {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: ~"calc(100% + 32px)";
        margin: 0 -16px -32px -16px;
        padding: 16px 0 0 0;


        @media all and (max-width: 1200px) {
            width: ~"calc(100% + 16px)";
            margin: 0 -8px -24px -8px;
        }

        @media @flyout {
            padding: 0;
        }

        @media all and (max-width: 740px) {
            width: ~"calc(100% + 16px)";
            margin: 0 -8px -24px -8px;
        }

        &:after, &:after {
            display: none;
        }

        @media @mobile {

            width: 100%;
            margin: 0;

        }


        .categoryBox {

            padding: 0 16px;
            margin-bottom: 32px;
            width: 33.333%;

            @media all and (max-width: 1200px) {
                padding: 0 8px;
                margin-bottom: 24px
            }

            @media all and (max-width: 1000px) {
                width: 50%;
            }

            @media all and (max-width: 740px) {
                width: 100%;
                padding: 0 8px;
                margin: 0 0 24px 0;
            }


            .innerCategoryBox {

                padding: 0;
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;
                min-height: 240px;
                overflow: hidden;

                @media all and (max-width: 740px) {
                    min-height: 0;
                }



                & > h3 {
                    padding: 16px 24px;
                    border-bottom: none;
                    text-align: center;
                    font-weight: bold;
                    background: @primary_color;
                    color: #fff;
                    border-radius: @border_radius @border_radius 0 0;
                    margin: 0;
                    flex: 0 0 auto;
                    .rems(32);
                    line-height: 32px;

                    @media @mobile {
                        .rems(24);
                        line-height: 24px;
                        padding: 16px;
                    }
                }


                .contentBox {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                    padding: 24px;
                    border: 1px solid @border_color;
                    border-top: 0;
                    border-radius: 0 0 @border_radius @border_radius;
                    background: #fff;

                    @media @mobile {

                        padding: 16px;
                    }


                    ul {

                        margin-bottom: 0;

                        li {

                            margin-bottom: 8px;

                            &:last-child {
                                margin: 0;
                            }

                            a {
                                .rems(16);
                                font-weight: 500;
                                line-height: 32px;
                                padding: 8px 0;
                                color: @text_color;
                                text-decoration: none !important;
                                transition: color 200ms linear;

                                @media @mobile {
                                    padding: 2px 0;
                                }

                                @media all and (max-width: 400px) {
                                    .rems(14);
                                }


                                &:after {
                                    .fa(f178);
                                    position: relative;
                                    display: inline-block;
                                    margin-left: 8px;
                                    color: @primary_color;
                                    transform: translateX(0);
                                    transition: transform 200ms linear;
                                    font-weight: 400;

                                }

                                &:hover, &:active {

                                    color: @primary_color;
                                    transition: color 200ms linear;

                                    &:after {
                                        transform: translateX(8px);
                                        transition: transform 200ms linear;
                                    }
                                }
                            }
                        }
                    }

                }




            }


        }


    }
}

