/*
 * Checkout CSS
 */
@checkout_border_color: @border_color;
@checkout_border_radius: @border_radius;

.adverseWarning {
    margin-top: 16px;
}


#checkout {
    margin: 0;
    .clearfix();
    .wrapper();
    padding: @content_padding_top 0 40px 0;
    clear: both;
	min-height: 50vh;

    @media @flyout {
        padding-top: @content_padding_top_flyout;

        .contentPageWrap & {
            padding: 0;
        }
    }

    @media @mobile {
        padding-top: @content_padding_top_mobile;
    }

    @media @tablet {
        margin: 0 auto;
        max-width: 800px;
    }

    @media all and (max-width: 830px) {
        max-width: 830px;
    }

    &.overview {


        @media all and (max-width: 870px) {
            padding: 40px 24px 24px 24px !important;
        }

        .overviewAccordionTitle {
            @media @mobile {
                .rems(24);
                line-height: 32px;
            }
        }


    }

    .itemPrice {
        del {
            padding-right: 8px;
            display: inline-block;
            opacity: 0.5;
        }
    }
}

/*
 * Main checkout layout
 */



h3.detailsTitle {

    .rems(24);
    line-height: 32px;
    margin: 0 8px 32px 8px;
    padding: 0 0 24px 0;
    border-bottom: 1px solid @border_color;
    width: 100%;
    display: block;
    float: none;
    clear: both;

    @media @tablet, @flyout {
        text-align: center;
    }

}

#mobileCheckoutButton {
    display: none;

    @media @mobile, @tablet {
        width: 100%;
        display: block;
    }
}


.userDetailsWrap {
    float: none;
    clear: both;
    width: 100%;
    padding: 0 40px;


    #shipAddress {
        max-height: 0;
        transition: opacity 0.5s cubic-bezier(0, 0, 0.58, 1), max-height 0.5s cubic-bezier(0, 0, 0.58, 1);
        overflow: hidden;
        opacity: 0;
        margin: 0;
        padding: 0;

        &.alternate_delivery {
            max-height: none;
            opacity: 1;
        }

        h5 {
            .rems(18);
            line-height: 24px;
            margin-bottom: 24px;
            font-weight: 600;
            margin-top: 4px;
			
			.orderOverviewArea & {
				font-weight: 600;
			}
        }
    }

    .overviewAccordionContent {
        padding-top: 40px;

        .overviewHeader {
            h5 {
                .rems(18);
                line-height: 24px;
                margin-bottom: 8px;
                font-weight: 600;
                margin-top: 0;
            }
        }
    }
}



.checkoutSectionWrap {
    .clearfix();
    margin: 0;
    width: 100%;

    .orderSummaryWrap & {
        padding: 0 8px 16px 8px;
        width: 50%;

        @media all and (max-width: 500px) {
            width: 100%;
            padding: 0 8px;
        }

        &:first-of-type {
            width: 100%;
        }
    }




    h6 {
        .rems(18);
        font-weight: 500;
        margin-bottom: 24px;

        i {
            display: none;
        }
    }

    .fakeInput {
        padding-top: 6px;
    }

    .countdown {
        .rems(12);
        float: right;
    }
}

.checkoutButtonWrap {
	float: none;
	clear: both;
	width: 100%;
	
	.button {
		width: 100%;
	}
	
	&.primaryCheckoutButton {
		
		& + .checkoutButtonWrap {
			padding-top: 24px;
			border-top: 1px solid @border_color;
		}
	}
}

#shipPayment {
    margin-bottom: 24px;
    margin-top: 16px;
    padding: 16px 32px;

    @media @tablet, @flyout {
        width: 100%;
    }

    @media @mobile {
        margin-top: 32px;
    }

}


.bagImageWrap {
    height: 50px;
    width: 50px;
    text-align: center;
    background: #fff;
    border: 1px solid @checkout_border_color;
    overflow: hidden;

    @media @mobile {
        width: 100%;
        height: auto;
    }
}

.buttonAlt.goBack {
    min-width: 200px;
    border: 1px solid #D5D5D5;
    color: #999;
    font-size: 0.8125rem;
    font-weight: 500;

    @media @mobile {
        width: 100%;
    }
}

a.bagTitle {
    text-decoration: none;
    .rems(13);
    line-height: 16px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}


/*
 * Basket Overview
 */

.overviewHeader {
    .clearfix();

    .orderSummaryPage & {
        cursor: pointer;
    }

    h5 {
        .rems(18);
        line-height: 24px;
        margin: 0;
    }
}


.detailsOverview {
    width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 0 15px;

    &:before, &:after {
        display: none;
    }

    .checkoutSectionWrap {

		flex: 0 0 33.333333%;
		float: none;
		clear: both;

		@media all and (max-width: 1080px) {
			padding: 0 0 8px;
			margin-bottom: 24px;
			border-bottom: 1.5px solid @border_color;
			flex: 0 0 100%;
			
			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
				margin: 0;
			}
		}
		
		&:first-of-type {
			flex: 0 0 100%;
			padding: 0 0 8px;
			margin-bottom: 24px;
			border-bottom: 1.5px solid @border_color;
			
			@media @flyout {
				padding: 24px 0 8px;
				border-top: 1.5px solid @border_color;
			}
			
		}
		
        h5 {
            position: relative;
            margin: 0;
            padding: 0;

            i {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}



.formInline {

    .overviewContent {
        clear: both;
        .clearfix();
        width: 100%;
        padding: 8px 0 16px 0;

        .row {
            margin: 0;

            .fakeInput {
                padding: 0;
                word-wrap: break-word;
            }
        }

        label {
            display: none;
        }
    }
}

.editBasket {
    .button();
    float: right;
    padding: 10px 15px;
}

/*
 * Basket View
 */


#bagItems {
    width: 100%;

    .itemAlert {

        .error {
            text-align: center;
        }
    }
}



.bagItemsHeader, .bagItem {
    display: table;
    text-align: center;
    width: 100%;
    table-layout: fixed;

    > div {
        display: table-cell;
        padding: 10px;
        vertical-align: middle;

        @media @mobile {
            display: block;
            float: left;
            padding: 0;
            text-align: left;

        }

        &:first-child {
            padding-left: 0;
            text-align: left;
        }

        &:last-child {
            text-align: right;
            padding-right: 0;
        }
    }

    .bagThumb {
        padding: 5px;
        width: 8%;

        @media @mobile {
            width: 60px;
        }
    }

    .bagDescription {
        text-align: left;
        width: 20%;

        @media @mobile {
            width: ~'calc(100% - 70px)';
            float: right;
            margin: 0;
        }
    }

    .bagCode {

        @media @mobile {
            width: ~'calc(100% - 70px)';
            float: right;
            margin: 0 0 5px;
        }
    }

    .bagSize {
        width: 16%;

        @media @mobile {
            width: ~'calc(100% - 70px)';
            float: right;
            margin: 0 0 50px;
            .rems(12);
        }
    }

    .bagUnitPrice {
        width: 13%;

        @media @mobile {
            line-height: 35px;
            position: absolute;
            right: 129px;
            bottom: 10px;
            width: 100px;
            text-align: right;
        }
    }

    .bagQuantity {
        width: 12%;

        @media @mobile {
            float: right;
            position: absolute;
            right: 30px;
            bottom: 10px;
            line-height: 37px;
            width: 80px;
        }

        &:before {

            @media @mobile {
                content: 'x';
                line-height: 35px;
                position: absolute;
                right: 100%;
                margin: 0 5px 0 0;
                top: 0;
            }
        }
    }

    .bagCost {
        width: 12%;

        @media @mobile {
            display: none;
        }
    }

    .bagButtons {
        width: 4%;

        @media @mobile {
            float: right;
            position: absolute;
            bottom: 10px;
            right: 0;
            line-height: 35px;
            padding: 0 10px;
            width: auto;
            .ems(20);
        }
    }
}

.bagItemsHeader {

    @media @mobile {
        display: none;
    }

    > div {
        border-bottom: 1px solid @checkout_border_color;
    }
}


.passportHelpLink {
    display: block;
    margin-top: 10px;
    .rems(13);
}


.bagItem {

    @media @mobile {
        .clearfix();
        position: relative;
        display: block;
        padding: 0 0 10px;
        margin: 0 0 20px;
        border-bottom: 1px solid @checkout_border_color;
    }

    .row {
        margin: 0;

        .bagItemQuantity {
            width: 80px;
            margin: 0 auto;
            padding: 0 8px;
            line-height: 26px;
            height: 36px;
            text-align: center;
            float: none;
            min-height: 0 !important;

            @media @mobile {
                height: 36px;
                line-height: 26px;
            }

            input {
                .rems(14) !important;
                line-height: 20px !important;
            }
        }
    }
}


#totals {
    margin-bottom: 0;
    border: none;
    //table-layout: auto;
    table-layout: fixed;

    tr {

        td {
            border-bottom: 1px solid @checkout_border_color;
            padding: 16px 0;
            background: transparent !important;
            border-radius: 0 !important;

            &:nth-child(2n) {
                text-align: right;
            }

            &:first-child {
                padding-left: 0;
            }
        }

        &.checkoutTotal {

            td {
                border-bottom: none;
            }
        }
    }

    tr:last-child td {
        border-bottom: 0;
    }
}

.deliveryOptions {

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    td, .row {
        padding: 0;
        margin: 0;
    }

    td {

        &:first-child {
            width: 200px;
        }
    }

    .deliverySelect {
        float: left;
        width: 80%;


        @media @mobile {
            width: 100%;
            margin-bottom: 10px;
            text-align: right;
        }
    }

    .deliveryTotal {
        float: left;
        width: 20%;
        padding: 0 0 0 20px;
        text-align: right;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .orderSummary & {
            height: auto;
        }

        @media @mobile {
            width: 100%;
        }
    }
}

.deliverySelect {

    .row {

        float: none !important;
        clear: both !important;

        &:before, &:after {
            display: none !important;
        }
    }
}

#discount {
    @media @mobile {
        border-radius: 16px;
    }
}

.discount {

    .row {
        margin: 0;
    }

    td {

        &:first-child {
            width: 200px;

            @media @mobile {
                width: 126px;
            }
        }

        &:last-child {
            width: ~'calc(100% - 200px)';

            @media @mobile {
                width: ~'calc(100% - 126px)';;
            }
        }
    }
}




.termslink {
    text-decoration: underline;
}

.itemUpdateButton,


table {

    tr {

        .success, .error {
            margin: 0;
        }
    }
}

/*
 * Sage Pay Server
 */

.sagepayServerForm {
    clear: both;

    h2 {
        float: none;
        clear: both;
        width: 100%;
        margin: 8px 0 16px;
        .rems(20);
        line-height: 24px;
        text-align: center;

        @media @flyout {
            margin-bottom: 32px;
        }
    }

    iframe {
        background: #fff;
        border-radius: @checkout_border_radius;
        overflow: hidden;
        padding: 24px 24px 0 24px;
        box-shadow: none;
        margin-bottom: 8px;

        @media @flyout {
            border: 1px solid @checkout_border_color;
        }

        @media all and (max-width: 500px) {
            padding: 0;
            border-left: none;
            border-right: none;
            border-radius: 0;
        }
    }

    & > p {
        text-align: center;
    }
}

.sagePayServerIframe {
    height: 524px;
    width: 100%;
    border: 0;
}

.PayPalForm {
    .clearfix();
    float: right;
}

.paypalSubmit {
    background: 0;
    border: 0;
    float: left;
    cursor: pointer;
}

/*
 * Order Complete
 */
.orderCompleteSection {
    border-top: 1px solid @border_color;
    margin-top: 32px;
    padding-top: 16px;
}

.orderComplete {
    .wrapper();
    .clearfix();
    padding: 40px 0;
    margin-top: 32px !important;

    @media @desktop {
        min-height: 400px;
    }

    @media @tablet {
        min-height: 400px;
    }

    table {
        float: left;
        max-width: 600px;

        th, td {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }


}

.orderCompleteInner {
    display: flex;
    flex-wrap: wrap;
    width: ~"calc(100% + 32px)";
    margin: 0 -16px;
    padding-bottom: 40px;

    &:before, &:after {
        display: none;
    }
}

.whatYouDo, .whatWeDo {
    float: none;
    clear: both;
    width: 50%;
    margin-right: 0;
    padding: 24px 16px;

    @media @tablet, @mobile {
        width: 100%;
        margin: 0;
        padding: 16px;
    }

    h4,h5 {
        margin-bottom: 16px;
        .rems(20);
        line-height: 24px;
        margin-bottom: 8px;

        @media @flyout {
            margin-bottom: 8px;
        }
    }

    ul {
        li {
            .rems(14);
            line-height: 24px;
        }
    }
}

.whatWeDo {
    margin-right: 0;
}

/*
 * Checkout Buttons
 */

.logoutText {
    text-align: center;
}

.checkoutButton {
    .button();
    .rems(18);
    margin: 30px 0 0;

    @media @tablet {
        margin-bottom: 30px;
    }

    @media @mobile {
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
    }
}



@media all and (max-width: 640px) {

    select {
        height: 45px;
    }
}

.paymentProcesserTestMessage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.checkoutTotal {
    font-weight: 00;
    .rems(18);
}

.emptyBasket {
    text-align: center;
    padding: 200px 0;

    .busyLoading {
        opacity: 0;
        transition: opacity 0.3s ease-in;
    }

    &.loading {

        .busyLoading {
            opacity: 1;
        }
    }

    @media @mobile {
        padding: 100px 0;
    }

    .emptyBasketMessage {

        float: none;
        clear: both;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
		position: relative;
		
		.loadingSpinner {
			
			.loadingText {
				margin-top: -24px;
			}
			
		}

        h4 {
            .rems(20);
            line-height: 32px;
            opacity: 0.7;
            width: 100%;
        }

        .button {
            margin: 0;
        }
    }
}

/*
 * Accepted cards
 */

.acceptedCards {
    padding: 8px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: ~"calc(100% + 32px)";
    margin: 0 -16px;
    justify-content: center;
    margin-top: 0 !important;

    @media @tablet, @flyout {
        padding-top: 24px;
    }


    &:before, &:after {
        display: none;
    }


    li {

        margin: 0;
        max-width: 96px;
        width: ~"calc(100% / 5)";
        padding: 0 16px;
        display: flex;
        align-items: center;


        svg, img {
            width: auto;
            height: 30px;
        }


    }

}

.memberAlready {
    background: transparent;
    padding: 24px;
    border: 1px solid @border_color;
    border-left: none;
    border-right: none;
    margin-bottom: 24px;

    h3 {
        .rems(20);
        line-height: 32px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 16px;
    }

    .button {
        margin-bottom: 0;
        min-width: 116px;
    }
}

.checkoutActions {

    padding-bottom: 24px;

    @media all and (max-width: 830px) {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .button {
        margin: 0;
    }

    .goBack {

        .button();

        i {
            margin-right: 5px;
        }
    }

    @media @tablet, @flyout {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .button {
        @media @tablet, @flyout {
            order: 1;
        }
    }

    .buttonAlt {
        @media @tablet, @flyout {
            margin: 16px 0;
            background: transparent !important;
            border: none !important;
            color: @secondary_color;
            padding: 8px 16px;
            .rems(14);
            line-height: 16px;
            width: auto;
            transition: color 200ms linear;

            &:hover {
                color: darken(@secondary_color, 10%);
                transition: color 200ms linear;
            }
        }
    }

    .continueButton {
        margin-top: 16px;
        text-decoration: underline;
        order: 999;

        &:hover, &:active {
            text-decoration: none;
        }
    }



}



.userDetailsWrap {

    .formInline .row label, .row label {

        @media @mobile {
            padding-top: 0;
            padding-bottom: 8px;
        }

    }

}

.mobileBack {
    display: none;
    text-align: center;
    padding-top: 24px;

    @media @tablet, @flyout {
        display: block;
        padding-top: 40px;
    }
}


.desktopBack {
    display: inline-block;

    @media @tablet, @flyout {
        display: none !important;
    }
}


.userDetailsWrap.paymentButtonArea {

    .paymentDetails {
		
        @media @flyout {
            float: none;
            clear: both;
            padding: 24px 0 0;
            border-top: 1px solid @checkout_border_color;
            margin-top: 24px;
        }

        .paymentTitle {
            .rems(24);
            line-height: 32px;
            margin: 0;
            text-align: center;
        }

        .checkoutButton {

            margin-bottom: 0 !important;

            @media all and (max-width: 640px) {
                .rems(16);
            }
        }

        & > form {
            @media @tablet, @flyout {
                float: none;
                clear: both;
            }

        }
		
		
        .acceptedCards {

            margin-top: 16px;

            @media @tablet, @flyout {
                margin-top: 24px;
            }
        }

        .mobileBack {
            display: none !important;

            @media @tablet, @flyout {
                display: inline-block !important;
                padding: 40px 0 0 0;
                width: 100%;
            }


            .buttonAlt {
                width: 100%;
            }
        }
    }
}

#judopayForm {

	iframe {
		border-radius: 10px;
		background: #fff;
		padding: 24px;
		margin: 0 0 12px;
		height: 300px !important;
		min-height: 300px !important;
		
		@media all and (max-width: 500px) {
			height: 400px !important;
			min-height: 400px !important;
		}
	}



	.judopay-errors {
		color: @alert_color;
		margin-top: 12px;
		margin-bottom: 12px;
		float: none;
		clear: both;
		display: block;
		width: 100%;
	}
	
	form {
		.row {
			label {
				display: block;
				width: 100%;
				text-align: center;
			}
		}
	}
	
	.testPaymentRow {
		
		padding-top: 12px;
		
		table {
			
			border-collapse: collapse;
			
			td {
				border-radius: 0 !important;
				padding: 8px 0 !important;
				border-bottom: 1px solid rgba(0,0,0,0.16) !important;
				background: transparent !important;
			}
		}
		
	}
}

.phoneTransaction {

    position: relative;
    padding-bottom: 64px;

    &:after {
        content: "OR";
        display: inline-block;
        position: absolute;
        bottom: 16px;
        margin-top: 0;
        color: fade(@text_color,20%);
        left: 50%;
        transform: translateX(-50%);
        background: @light_color;
        padding: 4px;
        z-index: 5;
        font-weight: bold;
    }

    &:before {
        content: "";
        width: ~"calc(100% - 16px)";
        height: 1px;
        background: fade(@text_color,20%);
        position: absolute;
        bottom: 32px;
        margin-top: 0;
        left: 8px;
        right: 8px;
        z-index: 4;
    }
}


.detailsOuter {

    border-top: 1px solid @checkout_border_color;
    padding-top: 32px;
    margin-top: 0;

    h3 {

    }
}

.formInline.detailsOverview {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: ~"calc(100% + 16px)";
    margin: 0 -8px;
    padding-top: 24px;
    padding-bottom: 24px;

    @media @flyout {
        padding-bottom: 0;
    }


    .checkoutDetailsSections {
        width: 50%;
        float: none;
        clear: both;
        margin: 0;
        padding: 0 8px;
        margin-bottom: 32px;

        @media all and (max-width: 640px) {
            width: 100%;
        }

        &.fullWidth {
            width: 100%;
			
        }

        .overviewContent {
            padding: 0;
        }
    }
}


.checkoutMarketingArea {
    display: none !important;
}

.checkboxRow {
    .validationMessage {
        padding-top: 32px !important;
        padding-left: 40px !important;
    }
}


#totals {
    td, th {
        border-left: none !important;
        border-right: none !important;
    }

}

#totals {

    td, th {
        @media @tablet, @flyout {
            .rems(16);
            line-height: 24px;
        }
    }
}

.overviewAccordionContent {
    #totals {

        tr > td {
            border-color: @checkout_border_color !important;
        }

        td, th {
            @media @tablet, @flyout {
                .rems(16);
                line-height: 24px;
            }
        }
    }
}

.bagItems {
    .bagItemsHeader {

        margin-bottom: 8px;

        &>div {
            border-color: @checkout_border_color !important;
        }
    }
}

.detailsOverview .checkoutSectionWrap h5 {
    .rems(18);
    line-height: 24px;
    font-weight: 600;
}


.idCheck {
    margin: 0 0 24px !important;
}

.idCheckFieldsSection {

    float: none;
    clear: both;
    width: 100%;
    padding: 24px 24px 12px 24px;
    border: 1px solid @border_color;
    border-radius: @border_radius;

    .innerIdCheckFields {

        float: none;
        clear: both;
        width: 100%;

        .multiRowLabel {

            display: flex;
            margin: 0 0 16px;

            @media all and (max-width: 500px) {
                flex-wrap: wrap;
            }

            label {

                float: none;
                clear: both;
                width: 50%;
                flex: 0 0 50%;
                padding: 0 16px 0 0;
                display: flex;
                align-items: center;

                @media @mobile {
                    display: block;
                }

                @media all and (max-width: 500px) {
                    padding: 0;
                    width: 100%;
                    flex: 0 0 100%;
                }

            }

            .idHelpLinkWrap {

                width: 50%;
                flex: 0 0 50%;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media all and (max-width: 500px) {
                    padding: 0;
                    margin: 12px 0 0;
                    width: 100%;
                    flex: 0 0 100%;
                    justify-content: flex-start;
                }

                a {
                    text-decoration: none;
                    margin: 0;
                }

            }

        }

        .inputWrap {

            float: none;
            clear: both;
            width: 100%;
            margin: 0;
            padding: 0;

            .exampleValue {

                padding: 0;
                margin: 16px 0 0 0;
                display: flex;
                flex-wrap: wrap;

                span {
                    .rems(11);
                    line-height: 16px;
                    color: fade(@text_color, 70%);
                    display: block;
                    width: 100%;

                    &:first-of-type {
                        width: auto;
                        flex: 0 0 auto;
                        padding-right: 4px;
                    }

                    &:last-of-type {
                        width: auto;
                        flex: 1 1 auto;
                    }
                }

            }

            .idCheckFields {

                float: none;
                clear: both;
                display: flex;
                flex-wrap: wrap;
                width: ~"calc(100% + 12px)";
                margin: 0 -6px -12px -6px;


                .row {

                    float: none;
                    clear: both;
                    width: auto;
                    flex: 1 1 auto;
                    margin: 0 0 12px 0;
                    padding-left: 6px;
                    padding-right: 6px;

                    input {
                        text-transform: uppercase;
                        text-align: center;
                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }

                &.passportFields {

                    .row {

                        min-width: 56px;

                        &:nth-of-type(1) {
                            width: 134px;
                            flex: 1 0 134px;

                            @media all and (max-width: 1188px) {
                                width: 260px;
                                flex: 1 0 260px;
                            }

                            @media all and (max-width: 780px) {
                                width: 350px;
                                flex: 1 0 350px;
                            }

                            @media all and (max-width: 500px) {
                                width: 134px;
                                flex: 1 0 134px;
                            }
                        }
                        &:nth-of-type(2) {
                            width: 48px;
                            flex: 0 0 48px;
                        }
                        &:nth-of-type(3) {
                            width: 68px;
                            flex: 0 0 68px;
                        }
                        &:nth-of-type(4) {
                            width: 124px;
                            flex: 1 0 124px;

                            @media all and (max-width: 1188px) {
                                width: 260px;
                                flex: 1 0 260px;
                            }

                            @media all and (max-width: 780px) {
                                width: 124px;
                                flex: 1 0 124px;
                            }

                            @media all and (max-width: 500px) {
                                width: 140px;
                                flex: 1 0 140px;
                            }

                        }
                        &:nth-of-type(5) {
                            width: 48px;
                            flex: 0 0 48px;
                        }
                        &:nth-of-type(6) {
                            width: 124px;
                            flex: 1 0 124px;

                            @media all and (max-width: 780px) {
                                width: 240px;
                                flex: 1 0 240px;
                            }

                            @media all and (max-width: 400px) {
                                width: 140px;
                                flex: 1 0 140px;
                            }

                        }
                        &:nth-of-type(7) {
                            min-width: 168px;
                            flex: 1 1 168px;

                            @media all and (max-width: 1242px) {
                                flex: 1 0 240px;
                            }

                            @media all and (max-width: 500px) {
                                width: 168px;
                                flex: 1 0 168px;
                            }
                        }
                        &:nth-of-type(8) {
                            width: 58px;
                            flex: 0 0 58px;
                        }

                    }
                }

                &.driversLicenseFields {

                    .row {

                        width: ~"calc(33.333% - 12px)";
                        flex: 1 1;
                        flex-basis: ~"calc(33.333% - 12px)";
                        min-width: 100px;

                    }

                }

                &.idCardFields {

                    .row {

                        min-width: 56px;

                        &:nth-of-type(1) {
                            width: 58px;
                            flex: 0 0 58px;

                        }
                        &:nth-of-type(2) {
                            width: 68px;
                            flex: 0 0 68px;

                            @media all and (max-width: 500px) {
                                flex: 1 0 78px;
                            }
                        }
                        &:nth-of-type(3) {
                            width: 168px;
                            flex: 1 0 168px;


                            @media all and (max-width: 500px) {
                                flex: 1 0 200px;
                            }
                        }

                        &:nth-of-type(5) {
                            width: 108px;
                            flex: 1 0 108px;

                            @media all and (max-width: 1242px) {
                                width: 200px;
                                flex: 1 0 200px;
                            }

                            @media @mobile {
                                width: 234px;
                                flex: 1 0 234px;
                            }


                        }
                        &:nth-of-type(6) {
                            width: 48px;
                            flex: 0 0 48px;

                        }
                        &:nth-of-type(7) {
                            width: 124px;
                            flex: 1 0 124px;

                            @media all and (max-width: 1242px) {
                                width: 260px;
                                flex: 1 0 260px;
                            }


                            @media all and (max-width: 500px) {
                                flex: 1 1 300px;
                            }

                            @media all and (max-width: 358px) {
                                flex: 1 1 200px;
                            }

                        }
                        &:nth-of-type(8) {
                            width: 68px;
                            flex: 0 0 68px;

                            @media all and (max-width: 500px) {
                                flex: 1 0 140px;
                            }
                        }
                        &:nth-of-type(10) {
                            width: 48px;
                            flex: 0 0 48px;

                        }

                    }
                }

            }

        }

        .row {
            float: none;
            clear: both;

        }

        .error {
            display: block;
            margin: 16px 0 0;
        }

    }


}


#checkout{

    padding: 56px 0 40px;

    @media @tablet, @flyout {
        padding: 40px 0;
    }

    .orderSummaryWrap {
        width: ~"calc(58% - 64px)";
        margin-right: 64px;

        @media @tablet, @flyout {
            width: 100%;
            margin: 0 0 40px;
        }
    }

    .goBackButton {
        @media @tablet, @flyout {
            display: none;
        }
    }

    .paymentDetails {
        @media @tablet, @flyout {
            width: 100%;
        }

        form {
            @media @tablet, @flyout {
                width: 100%;
            }
        }
    }

}

@media all and (max-width: 830px) {

    #checkout {

        padding: 0;

        .staticBlock {

            float: none;
            clear: both;

            .staticBlockInstance {

                float: none;
                clear: both;

                & > div {
                    margin: 0;
                    float: none;
                    clear: both;
                }

                .error, .alert, .warning, .success {
                    margin-bottom: 0 !important;
                }
            }

        }

        .checkoutForm {

            .userDetailsWrap {
                padding: 0 24px;
            }

            .orderSummaryWrap {

                #orderSummary {

                    border-radius: 0;
                    box-shadow: none;

                }

            }

        }
    }
}


.continueShoppingLink {
    margin-bottom: 24px;
    text-decoration: underline;

    @media @tablet {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }
}





/*
* Layout
*/

.basketWrapper {
    display: flex;
	padding-bottom: 56px;
	gap: 0 80px;

    @media @tablet, @flyout {
        width: 100%;
        margin: 0;
		flex-wrap: wrap;
        padding-bottom: 56px;
    }


    @media @mobile {
        padding-bottom: 56px;
    }
	
	.orderSummaryArea {
		flex: 0 0 50%;

		@media @tablet, @flyout {
			flex: 0 0 100%;
		}
	}
	
	.userDetailsWrap {
		flex: 1 1 100%;
		padding: 0;

		@media @tablet, @flyout {
			flex: 0 0 100%;
		}
	}


}


#checkout.checkoutOverview {
	background: #fff;
	max-width: 100%;
	padding-bottom: 80px;
	
	@media @flyout {
		padding-bottom: 40px;
	}
}


.orderOverviewWrapper {
	
	display: flex;
	gap: 0 80px;
	width: 100%;
	background: #fff;

	@media @flyout {
		flex-wrap: wrap;
		padding-top: 56px;
	}

	.formInline.detailsOverview {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.orderSummaryBox > h4 {
		
		@media @flyout {
			text-align: center;
			.rems(20);
			line-height: 1.2em;
		}
	}

}

.overviewBackButtons {
	padding-bottom: 32px;
	padding-top: 24px;
	margin-top: 24px;
	border-top: 1.5px solid @border_color;
	float: none;
	clear: both;

	&.desktopBackButton {
		
		display: block;
		width: 100%;
		
		@media @flyout {
			display: none;
		}
	}
	
	&.mobileBackButton {
		display: none;
		text-align: center;
		width: 100%;
		border-top: none;

		@media @flyout {
			display: block;
		}
	}
}


.userDetailsWrap {

    float: none;
    clear: both;
    width: 45%;
    padding: 0 28px;

    @media @tablet, @flyout {
        margin: 0;
        width: 100%;
        clear: both;
        padding: 0;
    }

    @media @mobile {
        margin: 0;
        width: 100%;
        clear: both;
    }
}

.formInline {

    .checkoutTitle {
        .rems(28);
        line-height: 38px;
        font-weight: bold;
        margin: 0 0 24px;
    }

    .checkoutSectionTitle {
        .rems(20);
        line-height: 30px;
        font-weight: bold;
        margin: 0 0 24px;
    }
}

/*
* Checkout Summary
*/

.orderSummaryArea {

    float: none;
    clear: both;
    padding: 0;

    @media @flyout {
       flex: 0 0 100%;
    }


}

.paymentBoxWrap {

	float: none;
	clear: both;
	flex: 0 0 40%;

	@media @flyout {
		flex: 0 0 100%;
		padding-top: 30px;
	}

	& > h4 {
		display: none;

		@media @mobile {
			margin-bottom: 16px;
		}


	}


}

.paymentDetails {
	background: @light_color;
	padding: 32px 40px;
	margin: 0;
	width: 100%;
	.clearfix();
	border-radius: 20px;
	position: sticky;
	top: 24px;

	@media @flyout {
		padding: 25px 25px 25px 35px;
		//background: transparent;
		//border-radius: 0;
		position: relative;
		top: 0;
	}
	
	& > h4 {
		text-align: center;
		padding: 12px 0;
		margin: 0 0 8px;
		
		@media @flyout, @mobile {
			.rems(24);
			line-height: 1.2em;
			margin-bottom: 16px;
		}
	}
	
	form {
		.row {
			label {
				display: block;
				width: 100%;
				text-align: center;
			}
		}
	}

	.error {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9100;

		&.relative {
			position: relative;
		}
	}

	@media @tablet {

		form {
			float: right;
		}
	}

	.checkoutButton {
		width: 100%;
		margin: 0 0 20px;

		@media @tablet {
			margin: 0;
		}
	}

}

.orderSummaryBox {

    .rems(16);
    line-height: 24px;
    background: @light_color;
    border-radius: 18px; //@border_radius;
    padding: 56px;
    float: none;
    clear: both;
    width: 100%;
    margin: 0 0 24px 0;

    @media @mobile {
        padding: 24px 24px 40px;
    }

    @media all and (max-width: 830px) {
        border-radius: 0;
    }

    .orderOverviewArea & {
        border-radius: 0;
        background: transparent;
        padding: 0;
		min-height: 442px;

		@media @mobile {
			padding: 40px 24px;
		}
    }

    & > h4 {
        .rems(28);
        line-height: 38px;
        margin: 0 0 24px 0;
		
		
		@media @mobile {
			.rems(24);
			line-height: 1.2em;
			display: none;

			.orderOverviewArea & {
				display: block;
			}
		}

		span {
			display: block;
			font-size: 0.8em;
			width: 100%;
		}
    }


    .totalsRows {

        width: 100%;
        float: none;
        clear: both;
        font-size: inherit;
        line-height: inherit;


        .totalRow {

            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 24px 0;
            border-top: 1px solid rgba(0,0,0,0.08);


            &:last-of-type {
                padding-bottom: 0;
            }

            &.totals {
                .rems(20);
                line-height: 30px;
            }

            .sectionTitle {
                width: 100%;
                padding: 0 0 12px;
                font-weight: bold;
            }

            .costTitle {
                flex: 1 1;
                width: ~"calc(100% - 100px)";
                flex-basis: ~"calc(100% - 100px)";
                text-align: left;
                padding: 0 12px 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                font-weight: bold;

                .row {
                    margin: 0;
                    float: none;
                    clear: both;
                    width: 100%;
                    max-width: 540px;

                    .buttonInline {
                        flex-wrap: nowrap;

                        @media @mobile {
                            flex-wrap: wrap;
                        }
                    }
                }

                .couponForm {
                    .row {
                        @media @mobile {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }

                .messageBox {
                    width: 100%;
                    margin: 0 !important;
                }

                & > div {
                    width: 100%;
                }

                .couponButton {
                    a {
                        .rems(12);
                        line-height: 18px;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .cost {
                width: 100px;
                text-align: right;
                padding: 0 0 0 12px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-weight: bold;

                & * {
                    font-weight: inherit;
                    font-size: inherit;
                    line-height: inherit;
                }
            }

            .errorRow, .successRow {
                width: 100%;
                padding: 12px 0 0;

                .success, .error, .alert, .alertBlue, .info {
                    margin: 0;
                }
            }

			.checkoutBasket & {
				&.fullWidth {

					.costTitle {
						@media all and (max-width: 500px) {
							width: 100%;
							flex-basis: 100%;
							padding: 0 0 16px 0;
						}
					}

					.cost {
						@media all and (max-width: 500px) {
							width: 100%;
							flex-basis: 100%;
							padding: 0;
						}
					}


				}
			}


        }

    }


}


.basketItems {

    float: none;
    clear: both;
    width: 100%;

    & > h5 {
        .rems(16);
        line-height: 24px;
        font-weight: bold;
    }

    .basketItem {
        width: 100%;
        padding: 24px 0;
        border-top: 1px solid rgba(0,0,0,0.08);
    }

    .basketItemInner {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        .basketItemImage {
            width: 140px;
            flex: 0 0 140px;
            padding: 0 24px 0 0;
            position: relative;

            @media @mobile {
                width: 100px;
                flex: 0 0 100px;
            }

            @media all and (max-width: 400px) {
                display: none;
            }

            .imageWrap {
                display: block;
                width: 100%;
                position: relative;
                padding-bottom: 100%;
                overflow: hidden;
                background: rgba(0,0,0,0.08);
                border-radius: @border_radius;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;
                    height: auto;
                }

            }


            .orderOverviewArea & {
                display: none;
            }

        }

        .basketItemDescription {
            width: 100%;
            flex: 1 1;
            flex-basis: 100%;
            position: relative;
            padding: 0 24px 0 0;
            display: flex;
            flex-direction: column;

            .descRow {
                width: 100%;
                margin: 0 0 4px;

                &.descQuantity {
                    margin: auto 0 0;
                }
            }

            .itemTitle {
                font-weight: 600;
				
				.orderOverviewArea & {
					color: @text_color;
					pointer-events: none;
				}
            }

            .itemPrice {

                &, & * {
                    font-weight: bold;
                }
            }

            .descLabel {
                .rems(14);
                line-height: 16px;
                font-weight: 600;
                margin: 0 0 8px 0;
            }

            .descQuantity {
				
				display: flex;
				align-items: center;
				
				.descLabel {
					flex: 0 0 auto;
					margin: 0;
				}

                .row {
                    margin: 0;
                    float: none;
                    clear: both;
                    width: 56px;
					flex: 0 0 56px;
					padding-left: 12px;
                    font-size: inherit !important;
                    line-height: inherit !important;

                    input {
                        font-size: inherit !important;
                        line-height: inherit !important;
                        padding: 0.55em !important;
                        min-height: 0 !important;
                        width: 100% !important;
                        max-width: 80px !important;
                    }

					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					input[type=number] {
						-moz-appearance: textfield;
					}
                }
            }


        }

        .basketItemTotals {
            width: 100px;
            flex: 0 0 100px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
            padding-top: 48px;
			
			@media @mobile {
				width: 80px;
				flex: 0 0 80px;
			}

            .basketItemActions {
                width: 100%;
                position: absolute;
                top: 4px;
                left: 0;
                right: 0;
                display: flex;
                justify-content: flex-end;

                .itemDeleteButton {
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;
                    transition: color 200ms linear;

                    i {
                        width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &:hover {
                        color: @alert_color;
                        transition: color 200ms linear;
                    }

                    &:active {
                        color: darken(@alert_color, 8%);
                        transition: color 200ms linear;
                    }
                }
            }

            .itemPrice {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                text-align: right;

                &, * {
                    font-weight: bold;
                }

            }

        }
    }

    .itemAlert {

        & > div {

            margin: 12px 0;

            &:first-child {
                margin: 24px 0 12px;
            }

            &:last-child {
                margin: 12px 0 0;
            }
        }
    }


}


.subscriptionBox {
    padding-top: 8px;

    .row {
        margin: 0;
        float: none;
        clear: both;
        display: flex;

        &:before, &:after {
            display: none;
        }

        label {
            float: none;
            clear: both;

        }
    }

    .row input[type=checkbox]:not(.toggleSwitch) + label {
        padding-left: 32px;
    }
    .row input[type=checkbox]:not(.toggleSwitch) + label:before, .row input[type=checkbox]:not(.toggleSwitch) + label:after {
        left: 0;
    }

    .row input[type=checkbox]:not(.toggleSwitch) + label:hover {
        &:before {
            border-color: @secondary_color !important;
        }
    }
}



.termsLink {
	text-decoration: underline;

	&:hover, &:active {
		text-decoration: none;
	}
}

.orderComplete {
	margin-top: 80px;
}

.titleBanner.checkoutTitle {
	@media all and (max-width: 830px) {
		min-height: 0;
	}
}

.orderCompleteFooter {
	border-top: 1px solid @border_color;
	padding-top: 32px;
	padding-bottom: 32px;
}


