.imageBanner {
    position: relative;
    background: #fff;
    padding: 0;
	display: flex;
	flex-wrap: wrap;
	
	&.altBackground {
		background: @light_color;
	}
	
    .wrapper {
        position: relative;
        z-index: 3;
		display: flex;
		align-items: center;
		width: 100%;
		min-height: 490px;
		
		
		@media @mobile {
			min-height: 0;
		}
		
		&:before, &:after {
			display: none;
		}
    }
	
	&.imageTopMobile {

		.wrapper {
			
			@media @mobile {
				order: 2;
			}
			
		}
		
	}

}

.imageBannerImages {

	flex: 0 0 50%;
	width: 50%;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	order: 1;
	max-width: 2000px;
	
	@media @mobile {
		width: 100%;
		flex: 0 0 100%;
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		max-width: 100%;
	}

	.imageTopMobile & {
		@media @mobile {
			order: 1;
		}
	}

	.imageLeft & {
		right: auto;
		left: 0;
	}

	.imageWrap {

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		max-width: 100%;
		margin: 0 0 0 auto;
		z-index: 1;
		
		@media @mobile {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			padding-bottom: 100%;
		}
		
		&:before {
			content: "";
			width: "calc(100% + 1px)";
			height: "calc(100% + 2px)";
			position: absolute;
			top: -1px;
			left: 0;
			bottom: -1px;
			right: -1px;
			z-index: 2;
			background: #fff;
			background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.79) 30%, rgba(255, 255, 255, 0) 80%);

			@media @mobile {
				background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0.79) 14%, rgba(255, 255, 255, 0) 60%);
			}

			.imageLeft & {
				background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.79) 30%, rgba(255, 255, 255, 0) 80%);

				@media @mobile {
					background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0.79) 14%, rgba(255, 255, 255, 0) 60%);
				}
			}

		}

		&.altGradient {

			&:before {
				background: @light_color;
				background: linear-gradient(90deg, fade(@light_color, 100%) 0%, fade(@light_color, 100%) 10%, fade(@light_color, 79%) 30%, fade(@light_color, 0%) 80%);

				@media @mobile {
					background: linear-gradient(180deg, fade(@light_color, 100%) 0%, fade(@light_color, 100%) 1%, fade(@light_color, 79%) 14%, fade(@light_color, 0%) 60%);
				}

				.imageLeft & {
					background: linear-gradient(270deg, fade(@light_color, 100%) 0%, fade(@light_color, 100%) 10%, fade(@light_color, 79%) 30%, fade(@light_color, 0%) 80%);

					@media @mobile {
						background: linear-gradient(180deg, fade(@light_color, 100%) 0%, fade(@light_color, 100%) 1%, fade(@light_color, 79%) 14%, fade(@light_color, 0%) 60%);
					}
				}
			}

		}

		
		&.mobileImageTop {

			&:before {
				
				@media @mobile {
					background: linear-gradient(360deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0.79) 14%, rgba(255, 255, 255, 0) 60%);
				}

				.imageLeft & {
					@media @mobile {
						background: linear-gradient(360deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0.79) 14%, rgba(255, 255, 255, 0) 60%);
					}
				}

			}

			&.altGradient {

				&:before {

					@media @mobile {
						background: linear-gradient(360deg, fade(@light_color, 100%) 0%, fade(@light_color, 100%) 1%, fade(@light_color, 79%) 14%, fade(@light_color, 0%) 60%);
					}

					.imageLeft & {

						@media @mobile {
							background: linear-gradient(360deg, fade(@light_color, 100%) 0%, fade(@light_color, 100%) 1%, fade(@light_color, 79%) 14%, fade(@light_color, 0%) 60%);
						}
					}
				}

			}
			
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
			backface-visibility: hidden;

			@media @mobile {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
}

.innerImageBanner {

    display: flex;
    width: 50%;
	flex: 0 0 50%;
    position: relative;
    margin-left: 0;
    margin-right: auto;

	@media @mobile {
		width: 100%;
		flex: 0 0 100%;
	}

    .imageLeft & {
        margin-left: auto;
        margin-right: 0;
    }
	
    .bannerContentWrap {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex: 0 0 100%;
		height: 100%;
        padding: 44px;
		
		@media @mobile {
			padding: 70px 0 0 0;
		}
		
		.imageTopMobile & {

			@media @mobile {
				padding: 0 0 50px 0;
			}
			
		}
		
		.button {
			margin-bottom: 0;
			
			
			&.buttonOutlined {
				//color: @primary_color;
			}
		}
		
    }

    .innerContentWrapper {

        display: block;
        width: 100%;
		max-width: 345px;
        position: relative;
        z-index: 2;
		
		@media @mobile {
			max-width: 358px;
			text-align: center;
		}
		

        h4.bannerSubtitle {
            margin: 0 0 8px;
            .rems(12);
            line-height: 1.2em;
            position: relative;
            padding: 0;
            font-weight: 500;
            opacity: 0.5;
        }
		
        .bannerTitle {
            .rems(24);
            line-height: 1.2em;
            margin: 0;
            font-weight: 500;
            padding: 0;
			color: @dark_primary;
        }

        & > .wysiwyg {
            margin: 0;
            .rems(17);
            line-height: 25px;
			font-weight: 500;
            color: @text_color;
            padding: 18px 0 0;
			
			@media @mobile {
				max-width: 328px;
				margin-left: auto;
				margin-right: auto;
			}

            p, ul, ol, span, strong {
                font-size: inherit;
                line-height: inherit;
				font-weight: inherit;
                color: inherit;
            }
			
			p, ul, ol {
				margin-bottom: 0 !important;	
			}

            &.testimonialText {
               

                p {

                    &:before {
                        content: '"';
                        display: inline-block;
                        padding-right: 8px;
                    }

                    &:after {
                        content: '"';
                        display: inline-block;
                        padding-left: 8px;
                    }

                }

            }
        }

       
		.trustPilotBox {
			width: 240px;
			height: 112px;
			position: absolute;
			right: 100%;
			top: 50%;
			transform: translateY(-50%);
			margin: 0 141px 0 0;
			
			@media @flyout {
				margin: 0 80px 0 0;
			}
			
			@media all and (max-width: 740px) {
				margin: 0 56px 0 0;
			}
			
			@media @mobile {
				width: 240px;
				right: auto;
				left: 50%;
				top: -148px;
				margin: 0;
				transform: translateX(-50%);
			}
		}

        .buttonWrap {

            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 25px 0 0;

            & > a {
                margin: 0;
                width: auto;
                flex: 0 0 auto;
                min-width: 136px;

                &:last-of-type {
                    margin-right: 0;
                }
				
				@media @mobile {
					width: 100%;
					flex: 0 0 100%;
					min-width: 100%;
				}

            }


        }

        .faqsWrapper, .categoriesWrapper {
            margin: 32px 0 0;

            @media all and (max-width: 900px){
                margin: 24px 0 0;
            }

            @media @mobile {
                margin: 24px 0 0;
            }
        }

    }




}
