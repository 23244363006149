.categoryTabsSection {

    background-color: #fff;
    padding: 240px 0 107px;
    float: none;
    clear: both;

    &.lightBg {
        background: #fff;
    }

    @media @flyout {
        padding: 80px 0 64px;
    }

    @media @mobile {
        padding: 80px 0 64px;
    }



    .categoryTabs {
        margin: 0 0 64px;
        display: flex;
        justify-content: center;

        @media @flyout {
            width: ~"calc(100% + 48px)";
            margin-left: -24px;
            margin-right: -24px;
            height: 48px;
            overflow: hidden;
        }

        @media @mobile {
            margin-bottom: 32px;
        }


        ul {
            display: flex;
            width: auto;
            overflow: hidden;
            background: #fff;
            box-shadow: 0 12px 16px rgba(0,0,0,0.08);
            border-radius: 100px;
            flex-wrap: nowrap;

            &:before, &:after {
                display: none;
            }

            @media @flyout {
                width: ~"calc(100% + 48px)";
                min-height: 72px;
                overflow-x: scroll;
                padding-bottom: 24px;
                padding-left: 24px;
                padding-right: 24px;
                box-shadow: none;
                border-radius: 0;
            }

            @media @mobile {

            }



            & > li {
                width: auto;
                float: none;
                clear: both;

                @media @flyout {
                    padding: 0 8px;

                    &:last-child {
                        padding-right: 24px;
                    }
                }

                @media @mobile {
                    padding: 0 4px;

                    &:last-child {
                        padding-right: 24px;
                    }
                }


                button, a {
                    .rems(17);
                    line-height: 23px;
                    color: fade(@text_color, 50%);
                    font-weight: 600;
                    border-radius: 100px;
                    background: fade(@third_color, 0);
                    transition: all 200ms linear;
                    padding: 24px 32px;
                    cursor: pointer;
                    outline: 0;
                    margin: 0;
                    white-space: nowrap;

                    @media @flyout {
                        border: 2px solid @border_color;
                        padding: 9px 24px;
                        .rems(14);
                        line-height: 20px;
                        height: 48px;
                    }

                    &:hover, &:focus {
                        color: @third_color;
                        border-color: @third_color;
                        transition: all 200ms linear;
                    }

                    &:active {
                        background: fade(@third_color, 80%);
                        border-color: fade(@third_color, 80%);
                        color: #fff;
                        transition: all 200ms linear;
                    }

                    &.active {
                        background: @third_color;
                        border-color: @third_color;
                        color: #fff !important;
                        transition: all 200ms linear;
                    }
                }
            }
        }
    }

    .onlineDoctorWrap {

        .categoryListWrap {

            &:not(.activeList) {
                display: none;
                visibility: hidden;
            }

            .activeList {
                display: block;
                visibility: visible;
            }

            & > ul {
                &:before, &:after {
                    display: none;
                }
            }


        }

    }

}
