.headingStyle(@selector: h1) {

	font-weight: 400;
	text-transform: none;

	& when (@selector = h1) {

		.rems(48);
		font-style: normal;
		font-weight: 500;
		line-height: 52.91px;
		letter-spacing: normal;

		@media @mobile {
			.rems(44);
			font-style: normal;
			font-weight: 500;
			line-height: 52.91px;
			letter-spacing: normal;
		}
		
	}

	& when (@selector = h2) {

		.rems(44);
		font-style: normal;
		font-weight: 500;
		line-height: 52.91px;
		letter-spacing: normal;
		

		@media @mobile {
			.rems(32);
			font-style: normal;
			font-weight: 600;
			line-height: 29.278px;
		}
	}

	& when (@selector = h3) {
		.rems(32);
		font-style: normal;
		font-weight: 600;
		line-height: 29.278px;

		@media @mobile {
			.rems(24);
			font-style: normal;
			font-weight: 500;
			line-height: 23.594px;
			letter-spacing: normal;
		}
	}

	& when (@selector = h4) {
		.rems(24);
		font-style: normal;
		font-weight: 500;
		line-height: 23.594px;
		letter-spacing: normal;

		@media @mobile {
			.rems(21);
			font-style: normal;
			font-weight: 500;
			line-height: 23.594px;
			letter-spacing: normal;
		}
	}

	& when (@selector = h5) {
		.rems(21);
		font-style: normal;
		font-weight: 500;
		line-height: 23.594px;
		letter-spacing: normal;

		@media @mobile {
			.rems(18);
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: normal;
		}
	}

	& when (@selector = h6) {
		.rems(18);
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: normal;

		@media @mobile {
			.rems(16);
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: normal;
		}
	}
}


.itemsList() {
	float: none;
	clear: both;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-columns: 25%;
	gap: 62px;
	

	@media all and (max-width: 900px) {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-columns: 33.333333%;
		gap: 24px;
	}

	@media all and (max-width: 700px) {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-columns: 50%;
	}

	@media all and (max-width: 400px) {
		grid-template-columns: 1fr;
		grid-auto-columns: 100%;
	}
	

	&:before, &:after {
		display: none;
	}
	

}
