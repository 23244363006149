/*
* News / Blog Styles
*/

.newsPageWrapper {
    padding: 96px 0 113px;
}

.blogGrid {

    .colGroup(24px);

    &:before, &:after {
        display: none;
    }

	.innerGridItem {

		display: flex;
		flex-direction: column;
		padding: 40px;
		background: @light_color;
		border: none;
		transition: background 400ms linear;
		border-radius: 16px;
		color: @text_color;
		height: 100%;
		text-align: center !important;

		@media @flyout {
			padding: 24px;
		}

		@media @mobile {
			background: @light_color;
			border: none;
			position: relative;
			min-height: 0;
			display: flex;
			padding: 16px;
		}

		&:hover {
			button {
				background: @secondary_color;
				border-color: @secondary_color;
				color: #fff;
			}
		}

		&:active, &:focus {
			button {
				background: darken(@secondary_color, 4%);
				border-color: darken(@secondary_color, 4%);
				color: #fff;
			}
		}


		.cardImage {

			width: 100%;
			height: auto;
			flex: 0 0 auto;
			position: relative;
			color: inherit;
			margin-top: 0;

			.imageWrap {
				border: none;
				box-shadow: none;
				position: relative;
				background: fade(@primary_color,10%);
				overflow: hidden;
				height: auto;
				padding-bottom: 46%;

				@media @flyout {
					padding-bottom: 46%;
				}

				@media all and (max-width: 500px) {
					padding-bottom: 46%;
				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 100%;
					height: auto;
				}

				.noImageSvg {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					width: 56px;
					height: 56px;
					opacity: 0.08;

					svg {
						width: 100%;
						height: auto;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%,-50%);

						path {
							fill: #000;
						}
					}
				}

			}

		}

		.cardHeader {

			padding: 16px 0 24px 0;

			@media @mobile {
				padding: 16px 0;
				width: 100%;
				margin-top: 0;
				height: auto;
				flex: 0 0 auto;
			}

			.date {
				text-align: inherit !important;
				.rems(12);
				opacity: 0.8;
				min-height: 24px;
			}
			
			.title {
				.rems(24);
				line-height: 32px;
				font-weight: bold;
				position: relative;
				text-align: inherit !important;
				margin: 0 0 8px;

				@media @flyout {
					.rems(16);
					line-height: 24px;
				}

				@media @mobile {
					.rems(16);
					line-height: 20px;
					word-wrap: break-word;
				}

				@media all and (max-width: 380px) {
					.rems(16);
					line-height: 20px;
				}

				i {
					margin-left: 8px;
					color: @text_color;
					transition: color 400ms linear;

					@media @mobile {
						display: none;
					}
				}
			}
			
			.summary {
				text-align: inherit !important;
			}
			
		}
		
		.cardFooter {
			width: 100%;
			padding: 0;
			height: auto;
			flex: 0 0 auto;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: inherit;
			flex-wrap: wrap;
			margin-top: auto;
			
			.cardCta {
				width: 100%;
				text-align: left;
				padding: 0;

				@media (min-width: 981px) and (max-width: 1100px) {
					padding: 16px 0 0;
				}

			}


			.button, .buttonAlt {
				margin: 0;
				display: block;
				min-height: 0;
				width: 100%;
			}
		}


	}

    &.mobileGridSlider {
		
		justify-content: center;
		
		@media all and (max-width: 1120px) {
			justify-content: flex-start;
		}
		
        @media @mobile {
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
			justify-content: flex-start;
        }
    }

    & > li {
        .col(3, 24px);
        margin-bottom: 24px;

        @media @flyout {
            .col(2, 24px);
            margin-bottom: 24px;
        }

        @media @mobile {
            .col(1, 24px);
            margin-bottom: 24px;
        }
    }
	

    &.blogGridSmall {

        margin-bottom: 0;

        & > li {

            .col(4, 24px, true);

            @media @flyout {
                .col(2, 24px, true);
            }

            @media @mobile {
                .col(1, 24px, true);
            }

        }


		.innerGridItem {

			padding: 24px;
			background: @light_color;
			color: @text_color;
			text-align: center !important;

			@media @flyout {
				padding: 16px;
			}

			@media @mobile {
				padding: 16px;
			}


			.cardHeader {

				padding: 12px 0 16px 0;

				@media @mobile {
					padding: 16px 0;
				}

				.date {
					text-align: inherit !important;
					.rems(12);
					opacity: 0.8;
				}

				.title {
					.rems(16);
					line-height: 24px;
					margin: 0 0 8px;

					@media @flyout {
						.rems(16);
						line-height: 24px;
					}

					@media @mobile {
						.rems(16);
						line-height: 20px;
					}

					@media all and (max-width: 380px) {
						.rems(16);
						line-height: 20px;
					}

				}

			}



		}

    }
	

}

.blogWrapper {

    padding: 56px 0 94px;
	background: #fff;


    @media all and (max-width: 1200px) {
        padding: 40px 0 80px;
    }



    .articlesWrap {
        .wrapper();
    }


    .paginationWrap {
        padding: 0;
    }

}

.emptyBlog {

    padding: 80px 0;

    h2 {
        .rems(32);
        line-height: 40px;
        margin: 0 0 8px;
        text-align: center;
        font-weight: 400;
    }

    p {
        margin: 0;
        text-align: center;
    }
}

/*
* News Articles
*/
.blogArticleWrapper {

    float: none;
    clear: both;
    padding: 64px 0 0;
    position: relative;
	background: #fff;

    &:before {
        content: "";
        display: block;
        background: @light_color;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
	
	& + .recommendedArticlesWrap {
		padding-top: 40px;
	}

    .wrapper {
        max-width: 920px;
        position: relative;
        z-index: 2;
    }

    .articleTitleBanner {

        text-align: center;
        padding: 0 0 64px;
        color: @text_color;

        .articleDate {
            .rems(12);
            line-height: 14px;
            margin-bottom: 16px;
            text-align: inherit;
            color: inherit;
        }

        .articleTitle {
            .rems(35);
            line-height: 40px;
            margin: 0;
            text-align: inherit;
            font-weight: 400;
            color: inherit;
        }

    }

    .articleContent {

        .articleImage {
            background: transparent;
            width: 100%;
            height: auto;
            padding-bottom: 56.306%;
            position: relative;
            box-shadow: 0 8px 32px rgba(0,0,0,0.14);
            margin: 0;

            img {
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }

        .blogContentContain {

            padding: 80px 0;
            margin: 0 auto;
            max-width: 680px;

            @media @flyout {
                padding: 40px 0;
            }

        }

    }
}


/*
* Recommended articles
*/
.recommendedArticlesWrap {

    background: #fff;
    padding: 120px 0 96px;

    @media @flyout {
        padding: 56px 0;
    }

    .introBlock {
        margin-bottom: 64px;

        @media @flyout {
            margin-bottom: 56px;
        }
    }

    &.generalBlogBanner {

        padding: 70px 0;

        @media @flyout {
            padding: 56px 0;
        }

        @media all and (max-width: 900px) {
            padding: 56px 0;
        }

        @media @mobile {
            padding: 56px 0;
        }

    }

}



.blogProducts {

    float: none;
    clear: both;
    position: relative;

    .upsellItemsWrap {
        padding: 120px 0 88px;

        @media @flyout {
            padding: 56px 0;
        }
    }
}
