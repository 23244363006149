.splide__pagination {
	
	display: flex;
	flex-wrap: wrap;
	gap: 0 8px;
	bottom: -5px;
	
	&:before, &:after {
		display: none;
	}
	
	li {
		flex: 0 0 auto;
	}
	
	.splide__pagination__page {
		width: 35px;
		height: 35px;
		border-radius: 0;
		background: transparent !important;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: none;
		transition: none;
		opacity: 1;
		
		&:before {
			content: "";
			width: 100%;
			height: 6.8px;
			border: 0.861px solid @dark_primary;
			background: fade(@dark_primary, 0%);
			display: block;
			border-radius: 30.139px;
			transition: background 200ms;
		}
		
		&.is-active {

			transform: none;
			transition: none;
			opacity: 1;
			
			&:before {
				background: fade(@dark_primary, 100%);
				transition: background 200ms;
			}

			&:hover, &:active, &:focus-visible {
				&:before {
					background: fade(@dark_primary, 100%);
					transition: background 200ms;
				}
			}
		}
		
		&:hover, &:active, &:focus-visible {
			&:before {
				background: fade(@dark_primary, 80%);
				transition: background 200ms;
			}
		}
	}
	
}
