.imagePoints {
	
	padding: 40px 0 140px;
	background: #fff;

	@media all and (max-width: 1000px) {
		padding: 40px 0 40px;
	}
	
	.introBlock {
		margin-bottom: 58px;

		@media @mobile {
			margin-bottom: 40px;
		}
	}
	
	&__grid {
		display: flex;

		@media all and (max-width: 1000px) {
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
			max-width: 685px;
			margin: 0 auto;
		}
	}
	
	&__image {
		
		flex: 1 1 60%;
		max-width: 685px;

		@media all and (max-width: 1200px) {
			flex: 1 1 50%;
		}

		@media all and (max-width: 1000px) {
			max-width: 100%;
			flex: 0 0 100%;
			display: flex;
			justify-content: center;
		}
		
		.imageWrap {
			border-radius: 8.611px;
			overflow: hidden;
			width: 100%;
			padding-bottom: 58%;
			position: relative;
		}
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
		}
		
		& + .imagePoints__points {
			padding: 0 0 0 64px;

			@media all and (max-width: 1000px) {
				padding: 40px 0 0 0;
			}
		}
		
	}

	&__points {
		flex: 1 1 40%;
		
		@media all and (max-width: 1200px) {
			flex: 1 1 50%;
		}

		@media all and (max-width: 1000px) {
			max-width: 100%;
			flex: 0 0 100%;
			display: flex;
			justify-content: center;
		}
	}
	
	ul {
		
		padding: 20px 0;
		
		&:before, &:after {
			display: none;
		}
		
		li {
			.rems(18);
			font-style: normal;
			font-weight: 500;
			line-height: 25.278px;
			color: @text_color_alt;
			letter-spacing: normal;
			position: relative;
			padding-left: 32px;
			display: block;
			list-style: none;
			margin: 0 0 30px;
			
			&:last-child {
				margin: 0;
			}
			
			&:before {
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" fill="%23145D6D"/></svg>');
				width: 24px;
				height: 25px;
				position: absolute;
				left: 0;
				top: 0;
				display: block;
			}
			
			
			strong {
				.rems(21);
				font-style: normal;
				font-weight: 500;
				line-height: 23.594px;
				letter-spacing: -0.63px;
				color: @dark_primary;
				margin: 0 0 4px;
				display: block;
			}
			
		}

		p {
			margin: 0;
		}
	}
	
}
